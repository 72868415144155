import React, { useContext, useEffect } from "react";
import { PageConfigContext } from "../../Store";
import { 
  Alert
 } from "../../Components";
 import {
  DownloadOutline,
} from "@graywolfai/react-heroicons";
import {Button } from "../../Components";
import apiHelper, { callApiBinary } from "../../Helpers/apiHelper";


function ProductList() {
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Products",
        subtitle: "All products repository",
        button: {
          visibility: 'hidden'
        }
      },
    });
  }, []);

  const downloadExcel = async() => {
    const urlApi = `/api/v1/admin/product`;
    const res = await callApiBinary(urlApi);

    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `all-products.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div className="backend__page">
      <div className="flex-1">
        <div className="belabel__description">
          <h3>Excel products file</h3>
          <p className="mb-2">Download an excel file containing all the company's products</p>
          <Button type="muted-button" text="Download" onClick={downloadExcel}>
            <DownloadOutline className="ml-2 w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
