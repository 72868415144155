import React from "react";
import { SidebarItems, PageTitle, Navbar } from "../../Components";
import { PageConfigStore } from "../../Store";

const LoggedLayout = (props) => {
  return (
    <PageConfigStore>
      <Navbar user={props.user} />
      <div id="backend__container">
        <div className="backend__sidebar">
          <SidebarItems user={props.user} />
        </div>

        <div className="backend__content">
          <div className="backend__header">
            <PageTitle />
          </div>
          <div className="backend__page">{props.children}</div>
        </div>
      </div>
    </PageConfigStore>
  );
};

export default LoggedLayout;
