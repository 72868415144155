import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from '../../Helpers/apiHelper';
import getLocalization from '../../Helpers/getLocalization';
import useSWR, { mutate } from 'swr';
import moment from 'moment';
import { Link } from "react-router-dom";

import { 
  Table, 
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FilterBar,
  FormField,
  Loading,
  DeleteModal,
  LinkModal,
  Radio,
  MediaPreview
 } from "../../Components";
import { LinkSolid, PencilSolid, SearchSolid, TrashOutline } from "@graywolfai/react-heroicons";

const QuickProductList = () => {
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  const fronted_base_url = process.env.REACT_APP_FRONTEND_BASE_URL;
  const get_company_settings_api_url = `/api/v1/admin/company`;
  //const delete_line_api_url = `/api/v1/admin/line/${lineId}`;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const currentLang = 'en';
  const [quickProductRows, setQuickProductRows] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('all');
  const [linkModal, setLinkModal] = useState({
    hidden: true
  });

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Quick products",
        subtitle: "Quickly create simple product pages to show to your clients",
        button: {
          visible: true,
          name: 'New quick products',
          icon: '',
          link: '/admin/quickproducts/new'
        }
      },
    });
  }, [dispachPageConfig]);

  const { data: settings } = useSWR(
    [get_company_settings_api_url, "get"],
    (url, method) => apiHelper(url, method),
    { revalidateOnFocus: false, suspense: true }
  );
  const companySlug = settings.data.data.company.slug;

  const normalizeQuickProducts = (quickProducts) => {
    let rows = [];

    console.log('quickProducts', quickProducts);

    if (quickProducts) {
      quickProducts.data.data.quickproducts.forEach((item) => {
        const createdAt = moment(item.createdAt);

        let row = {
          id: item.id,
          name: item.name,
          creationDate: createdAt.format("D/M/YYYY h:mm"),
          notes: item.Users[0].ProductUser.notes,
          contact: `${item.Users[0].name} ${item.Users[0].lastName} ${item.Users[0].businessName}`,
          seen: item.Users[0].ProductUser.seenAt ? 
            moment(item.Users[0].ProductUser.seenAt).format("D/M/YYYY HH:mm") :
            'No',
          picture: 'https://via.placeholder.com/40'
        };

        if (item.Media && item.Media.length > 0) {
          row.picture = item.Media[0].url;
        }

        rows.push(row);
      });
    }

    setQuickProductRows(rows);
  }

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async() => {
    const newData = await apiHelper('/api/v1/admin/quickproduct', 'get', {
      search: searchText,
      status: searchStatus !== 'all' ? searchStatus : null
    });

    normalizeQuickProducts(newData);
  }

  const deleteItem = async(productId) => {
    const delete_api_url = `/api/v1/admin/quickproduct/${productId}/delete`;
    const res = await apiHelper(delete_api_url, 'post');
    mutate('/api/v1/admin/quickproduct');
    setShowDeleteModal(false);
  }

  const showUrl = (url) => {
    console.log("showurl", url)
    setLinkModal({
      url: url,
      hidden: false
    });
  };

  const onClosedLinkModal = () => {
    setLinkModal({
      hidden: true
    })
  }

  const rowData = (row) => {
    console.log('### current row', row);

    return (
      <TableRow key={row.id}>
        <TableCell>
          <MediaPreview
            controls={false}
            className="object-cover h-12 w-12"
            previewFeaturedImage={row.picture} 
           />
        </TableCell>
        <TableCell className="hidden md:table-cell">
          {row.creationDate}
        </TableCell>
        <TableCell>
          {row.name}
        </TableCell>
        <TableCell>
          {row.contact}
        </TableCell>
        <TableCell className="hidden md:table-cell">
          {row.seen}
        </TableCell>
        <TableCell>
          <a href="#" onClick={(e) => { e.preventDefault(); showUrl(`${fronted_base_url}/${companySlug}/quickproduct/${row.id}`)}} target="_blank" className="badge-link uppercase">Open Url</a>
          <Link to={`/admin/quickproducts/edit/${row.id}`}>
            <PencilSolid className="dark-icon w-3 h-3 ml-4 cursor-pointer"></PencilSolid>
          </Link>
          <a href="#delete" onClick={(e) => { e.preventDefault(); setIdToDelete(row.id); setShowDeleteModal(true); }}>
            <TrashOutline className="dark-icon w-3 h-3 ml-4 cursor-pointer"></TrashOutline>
          </a>
        </TableCell>
      </TableRow>
    );
  }

  if (quickProductRows) {
    return (
      <div className="backend__page">
        <FilterBar onClick={refreshData}>
          <div className="filterbar__inlinefilters flex-grow">
            <span>Filter by status:</span>
            <Radio
              className="flex flex-row items-center"
              group="searchStatus"
              name="all"
              label="All"
              value="all"
              checked={searchStatus === "all"}
              onChange={(e) => setSearchStatus(e.target.value)} />
            <Radio
              className="flex flex-row items-center"
              group="searchStatus"
              name="seen"
              label="Seen"
              value="seen"
              checked={searchStatus === "seen"}
              onChange={(e) => setSearchStatus(e.target.value)} />
            <Radio
              className="flex flex-row items-center"
              group="searchStatus"
              name="notseen"
              label="Not seen"
              value="notseen"
              checked={searchStatus === "notseen"}
              onChange={(e) => setSearchStatus(e.target.value)} />
          </div>
          <FormField
            className="flex-grow"
            withLabel="0"
            type="text"
            value={searchText}
            onChange={(e) => {setSearchText(e.target.value)}}
            name="linesearch"
            placeholder="Search by name, description or contents...">
            <SearchSolid className="mid-icon"></SearchSolid>
          </FormField>
        </FilterBar>
  
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell className="hidden md:table-cell">
                Creation date
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell className="hidden md:table-cell">Seen</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { quickProductRows.map((item, i) => { return rowData(item); }) }
          </TableBody>
        </Table>

        <DeleteModal
          title="Are you sure?"
          text="Please confirm that you want to delete the quick product"
          hidden={!showDeleteModal}
          dismiss={() => { setShowDeleteModal(false); }}
          delete={() => { deleteItem(idToDelete); }}
        ></DeleteModal>

        <LinkModal url={linkModal.url} hidden={linkModal.hidden} onCloseHandler={onClosedLinkModal}/>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default QuickProductList;
