import React, { createContext, useReducer } from "react";
import PageConfigReducer from "./PageConfigReducer";

const initialState = {
  header: {
    title: "Default title",
    subtitle: "Default subtitle",
    button: {
      visible: false
    }
  },
};

const PageConfigStore = ({ children }) => {
  const [state, dispatch] = useReducer(PageConfigReducer, initialState);
  return (
    <PageConfigContext.Provider value={[state, dispatch]}>
      {children}
    </PageConfigContext.Provider>
  );
};

export const PageConfigContext = createContext(initialState);
export default PageConfigStore;
