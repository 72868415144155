import React from 'react';
import { Button } from "../../Components";
import { UploadOutline } from "@graywolfai/react-heroicons";

function InputFileButton(props) {
  const hiddenFileInput = React.useRef(null);

  const handleFileInputClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Button type="muted-button" text={props.title} onClick={handleFileInputClick}>
        <UploadOutline className="ml-2 w-4 h-4" />
      </Button>
      
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={props.onUpload}
        className="h-full w-full hidden"
      />
    </>
  );
}

export default InputFileButton;
