import axios from "axios";
import cookieCutter from "cookie-cutter";

const uploadMedia = async (file) => {
  console.log("uploadMedia file", file);

  const url = "/api/v1/admin/media";
  const JWT_COOKIE_TOKEN = "jwt_admin_cookie_token";

  const jwt = cookieCutter.get(JWT_COOKIE_TOKEN);

  if (!jwt) {
    return false;
  }

  try {

    var formData = new FormData();
    formData.append("mediaFile", file);

    const res = await axios.post(
      `${process.env.REACT_APP_API_SERVER}${url}`,
      formData,
        {
          headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'multipart/form-data'
        }
      },
    );

    return {
      data: res.data,
      status: res.data.status,
      httpStatus: res.status,
      success: res.data.status === "success",
    };
  } catch (e) {
    //TODO try catch errore 500
    return {
      data: e.response.data,
      status: e.response.data.status,
      httpStatus: e.response.status,
      success: e.response.data.status === "success",
    };
  }
};

export default uploadMedia;
