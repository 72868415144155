import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from "../../Helpers/apiHelper";
import { useParams } from "react-router-dom";

function SAArsutoriaImportView() {
  const { reportId } = useParams();

  const get_import_api_url = `/api/v1/saadmin/as-import/view/${reportId}`;
  
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  
  const [companyId, setCompanyId] = useState('');
  const [lineId, setLineId] = useState('');
  const [path, setPath] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [lineName, setLineName] = useState('')
  const [report, setReport] = useState([])



  const reloadImport = async () => {
    // Loading lines for default company
    const reportData = await apiHelper(
      get_import_api_url, "get", {}
    );

    let result = reportData.data.data.asImport

    

    if (result.report) {
      let reportArray = result.report.split('\n')

      let tmp = []
      for (let [index, reportRow] of reportArray.entries())
        tmp.push(<li className="text-gray-500 text-xs" key={'report-row-' + index}>{reportRow}</li>)

      setReport(tmp)
    }

    setLineId(result.lineId)
    setLineName(result.lineName)
    setCompanyId(result.companyId)
    setCompanyName(result.companyName)
    setPath(result.path)

  }


  useEffect(async() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Import From Elvis",
        subtitle: "Import summary",
        button: {
          visible: false,
        },
      },
    });

    await reloadImport();
  }, []);


  return (
    <div className="grid grid-cols-4 min-h-screen">
  
      <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Company</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              [{companyId}] - {companyName}
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Line</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
            [{lineId}] - {lineName}
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Elvis path</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              {path}
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Report</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              {report}
            </div>
          </div>
        </div>

      </div>

      {/* SIDEBAR */}
      <div className="col-span-4 md:col-span-1 md:pl-8">
       
      </div>
    </div>
  );
}

export default SAArsutoriaImportView;
