import loadingAnimation from "../assets/images/preload.gif";

const Loading = () => {
  return (
    <div className="grid place-items-center">
      <img  alt="" src={loadingAnimation}></img>
    </div>
    
  );
}

export default Loading;