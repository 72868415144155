function Checkbox(props) {
  return (
    <div className="mb-1">
      <input
        type="checkbox"
        disabled={props.disabled}
        name={props.name}
        checked={props.value}
        data-taxonomy={props.taxonomy}
        onChange={props.onChange}
        className="border border-gray-200 bg-white mr-2 rounded-sm text-purple-700"
      />
      <label htmlFor={props.label} className="text-xs text-gray-500">
        {props.label}
        {props.counter ? (
          <span> ({props.counter}) </span>
        ): null}
      </label>
    </div>
  );
}

export default Checkbox;
