import React, { useContext, useEffect, useState } from "react";
import moment from 'moment';
import { PageConfigContext } from "../../Store";
import { PlusCircleSolid, SearchSolid, PencilSolid, LinkSolid } from "@graywolfai/react-heroicons";
import apiHelper from '../../Helpers/apiHelper';
import getLocalization from '../../Helpers/getLocalization';
import useSWR, { mutate } from 'swr';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FilterBar,
  FormField,
  Loading,
  DeleteModal,
  LinkModal,
  Radio,
} from "../../Components";
import { Link } from "react-router-dom";

const ProposalList = () => {
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  const fronted_base_url = process.env.REACT_APP_FRONTEND_BASE_URL;
  const get_company_settings_api_url = `/api/v1/admin/company`;
  const currentLang = 'en';

  const [proposalRows, setProposalRows] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('all');

  const [linkModal, setLinkModal] = useState({
    hidden: true
  });

  const { data: settings } = useSWR(
    [get_company_settings_api_url, "get"],
    (url, method) => apiHelper(url, method),
    { revalidateOnFocus: false, suspense: true }
  );
  const companySlug = settings.data.data.company.slug;

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Proposals",
        subtitle: "Custom product collections for your clients",
        button: {
          visible: true,
          name: "Add New",
          icon: <PlusCircleSolid className="light-icon ml-2" />,
          link: "/admin/proposals/new",
        },
      },
    });
  }, []);

  const normalizeProposals = (proposals) => {
    let rows = [];

    if (proposals) {
      proposals.data.data.lines.forEach((item) => {
        const createdAt = moment(item.createdAt);
        const user = item.Users[0];

        let row = {
          id: item.id,
          name: getLocalization(item.Localizations, currentLang, 'title'),
          creationDate: createdAt.format("D/M/YYYY h:mm"),
          forclient: `${user.name} ${user.lastName} (${user.businessName})`,
          permissions: item.permission,
          visibility: item.visibility,
          status: item.Users[0].LineUser.status
        };

        if (item.Media) {
          row.picture = item.Media.url;
        }

        rows.push(row);
      });
    }

    setProposalRows(rows);
  }

  useEffect(() => {
    refreshData();
  }, []);
   
  const refreshData = async() => {
    const newData = await apiHelper('/api/v1/admin/proposal', 'get', {
      search: searchText,
      status: searchStatus !== 'all' ? searchStatus : null
    });

    normalizeProposals(newData);
  }

  const handleStatus = async (id, status) => {
    console.log(id, status)
    await apiHelper(
      `/api/v1/admin/proposal/${id}/status`,
      "post", {
        status: status === 'active' ? 'suspended' : 'active'
      }
    );
    await refreshData()
  }

  const showUrl = (url) => {
    console.log("showurl", url)
    setLinkModal({
      url: url,
      hidden: false
    });
  };

  const onClosedLinkModal = () => {
    setLinkModal({
      hidden: true
    })
  }

  if (proposalRows) {
    return (
      <div className="backend__page">
        <FilterBar onClick={refreshData}>
          <div className="filterbar__inlinefilters flex-grow">
            <span>Filter by:</span>
            <Radio
              className="flex flex-row items-center"
              group="searchStatus"
              name="all"
              label="All"
              value="all"
              checked={searchStatus === "all"}
              onChange={(e) => setSearchStatus(e.target.value)} />
            <Radio
              className="flex flex-row items-center"
              group="searchStatus"
              name="active"
              label="Active"
              value="active"
              checked={searchStatus === "active"}
              onChange={(e) => setSearchStatus(e.target.value)} />
            <Radio
              className="flex flex-row items-center"
              group="searchStatus"
              name="suspended"
              label="Suspended"
              value="suspended"
              checked={searchStatus === "suspended"}
              onChange={(e) => setSearchStatus(e.target.value)} />
          </div>
          <FormField
            className="flex-grow"
            withLabel="0"
            type="text"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            name="linesearch"
            placeholder="Search by title...">
            <SearchSolid className="mid-icon"></SearchSolid>
          </FormField>
        </FilterBar>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>For client</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {proposalRows.map((data) => (
              <TableRow key={data.id}>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.forclient}</TableCell>
                <TableCell>
                  <a href="#status"
                      onClick={(e) => { e.preventDefault(); handleStatus(data.id, data.status) }}
                      className={data.status === 'active' ? "badge-info" : "badge-danger"}>{data.status}</a>
                  {/* <span className="uppercase text-micro font-semibold p-2 badge-info">
                    {data.status}
                  </span> */}
                </TableCell>
                <TableCell>
                  <a href="#" onClick={(e) => { e.preventDefault(); showUrl(`${fronted_base_url}/${companySlug}/proposal/${data.id}`)}} target="_blank" className="badge-link uppercase">Open Url</a>
                  <Link to={`/admin/proposals/edit/${data.id}`}>
                    <PencilSolid className="dark-icon w-3 h-3 ml-4 cursor-pointer"></PencilSolid>
                  </Link>
                  {/* <a href="#delete" onClick={(e) => { e.preventDefault(); setIdToDelete(row.id); setShowDeleteModal(true); }}>
                    <TrashOutline className="dark-icon w-3 h-3 ml-4 cursor-pointer"></TrashOutline>
                  </a> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <LinkModal url={linkModal.url} hidden={linkModal.hidden} onCloseHandler={onClosedLinkModal}/>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default ProposalList;
