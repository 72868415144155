import React, { useEffect, useState } from "react";
import { 
  Button,
  FormField
} from "../Components";

function SizeSystemModal(props) {
  const [name, setName] = useState('');
  const [sizes, setSizes] = useState('');
  const [index, setIndex] = useState();

  useState(() => {
    if (props.update) {
      setName(props.update.name);
      setSizes(props.update.sizes);
      setIndex(props.update.index);
    }
  
    if (props.reset) {
      setName('');
      setSizes('');
      setIndex(null);
    }  
  }, []);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0" */}

        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true">
          &#8203;
        </span>

        {/* Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" */}
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex-1 full-width">
            <div className="">
              
              <div className="mt-3 sm:mt-0 text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline">
                  {props.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {props.text}
                  </p>
                </div>

                {props.error && (
                  <div className="mt-2">
                    <p className="text-sm text-red-500">
                      {props.error}
                    </p>
                  </div>
                )}

                <div className="mt-2">
                  <label htmlFor="name" className="text-sm text-gray-500">Name</label>
                  <FormField
                    required
                    value={name}
                    type="text"
                    name="name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }} />
                </div>

                <div className="mt-2">
                  <label htmlFor="linksColor" className="text-sm text-gray-500">Sizes</label>
                  <FormField
                    required
                    value={sizes}
                    type="text"
                    name="sizes"
                    onChange={(e) => {
                      setSizes(e.target.value);
                    }} />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse">
            <div className="ml-5">
              <Button
                type="muted-button"
                text={props.update ? 'Update' : 'Add'}
                onClick={() => {
                  props.add(name, sizes, index);
                }}
              />
            </div>
            <div className="">
              <Button
                type="muted-button"
                text="Cancel"
                onClick={() => {
                  props.dismiss();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default SizeSystemModal;
