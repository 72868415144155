import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import { SaveAsSolid } from "@graywolfai/react-heroicons";
import useSWR from "swr";
import apiHelper from "../../Helpers/apiHelper";
import { Link } from "react-router-dom";
import moment from 'moment';
import { Button, Alert } from "../../Components";

import { 
  Table, 
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FilterBar,
  FormField,
  Loading,
  DeleteModal,
  LinkModal,
  Radio
 } from "../../Components";
import { LinkSolid, PencilSolid, SearchSolid, TrashOutline } from "@graywolfai/react-heroicons";

function SAArsutoriaImportList() {
  const line_list_api_url = `/api/v1/admin/line`;
  const get_company_settings_api_url = `/api/v1/admin/company`;
  const import_from_elvis_api_url = `/api/v1/admin/company`;
  const fronted_base_url = process.env.REACT_APP_FRONTEND_BASE_URL;

  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  
  const [asImports, setAsImports] = useState([])
  
  const reloadAsImports = async () => {

    const asImportsData = await apiHelper(
      "/api/v1/saadmin/as-import", "get", {
      }
    );
    setAsImports(asImportsData.data.data.asImports);
    console.log('asImportsData', asImportsData);
  }


  useEffect(async() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Import From Elvis",
        subtitle: "List of most recent imports from Elvis to Arsutoria companies' lines",
        button: {
          visible: true,
          name: 'Setup Import',
          icon: '',
          link: '/saadmin/arsutoria/import/new'
        }
      },
    });

    await reloadAsImports();
  }, []);

  const rowData = (row) => {
    //console.log('current row', row);

    return (
      <TableRow key={"tr-" + row.id}>
        <TableCell><Link to={`/saadmin/arsutoria/import/view/${row.id}`}>
            <LinkSolid className="dark-icon w-3 h-3 ml-4 cursor-pointer"></LinkSolid>
          </Link></TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{`[${row.companyId}] ${row.companyName}`}</TableCell>
        <TableCell>{`[${row.lineId}] ${row.lineName}`}</TableCell>
        <TableCell>{row.path}</TableCell>
        <TableCell>{row.totalItems}</TableCell>
        <TableCell>{row.importedItems}</TableCell>
        <TableCell>{row.status.toUpperCase()}</TableCell>
        <TableCell className="hidden md:table-cell">{moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
        <TableCell className="hidden md:table-cell">{moment(row.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
      </TableRow>
    );
  }

  if (asImports) {
    return (
      <div className="backend__page">
  
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>View</TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Line</TableCell>
              <TableCell>Path</TableCell>
              <TableCell>Tot. Items</TableCell>
              <TableCell>Imported. Items</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="hidden md:table-cell">Creation date</TableCell>
              <TableCell className="hidden md:table-cell">Update date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { asImports.map((item, i) => { return rowData(item); }) }
          </TableBody>
        </Table>

        
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default SAArsutoriaImportList;
