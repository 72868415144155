function Metadata(props) {
  const content = props.meta.map((m) => {
    if (m.value) {
      return (
        <div key={m.key} className="productcard__metadata">
          <p className="productcard__label">{m.label}</p>
          <p className="productcard__value">{m.value}</p>
        </div>
      );
    }
    return null;
  });

  return (
    <div className={`grid grid-cols-2 gap-2 ${props.className}`}>{content}</div>
  );
}

export default Metadata;
