function FormField(props) {

  const getLabel = () => {
    if (props.withLabel) {
      return (
        <label
          className="form__label"
          htmlFor={`${props.name}`}>
          {props.label}
        </label>
      );
    }

    return null;
  };

  return (
    <div className="field__container flex-grow">
      {getLabel()}
      <div className="relative flex flex-row items-center">
        <input
          value={props.value}
          type={props.type}
          name={props.name}
          disabled={props.disabled}
          placeholder={props.placeholder}
          className={`form-field ${props.children ? "with-icon" : null}`}
          onChange={props.onChange}
        />
        <div className="absolute ml-3">{props.children}</div>
      </div>
    </div>
  );
}

export default FormField;
