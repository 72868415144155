import axios from "axios";
import cookieCutter from "cookie-cutter";

const JWT_COOKIE_TOKEN = "jwt_admin_cookie_token";

/**
 * Call this method when performing client requests
 * @param {*} url
 * @param {*} method
 * @param {*} payload
 */
const apiHelper = async (
  url,
  method = "get",
  payload = {},
  requireJwt = true
) => {
  console.log('apiHelper', [
    url,
    method,
    payload,
    requireJwt
  ]);
  return await apiManager(url, method, payload, requireJwt);
};

/**
 * This is the real manager for api calls. It has to optionally check for jwt token and if needed (default) performs anonimous login
 * @param {*} cookieManager is the cookie instance used to store cookies (different for client and server side)
 * @param {*} url
 * @param {*} method
 * @param {*} payload
 * @param {*} requireJwt true by default but if false, manager goes directly to api call
 */
const apiManager = async (
  url,
  method = "get",
  payload = {},
  requireJwt = true
) => {
  let jwt = undefined;

  if (requireJwt) {
    jwt = cookieCutter.get(JWT_COOKIE_TOKEN);
    //console.log("jwt admin cookie is " + jwt);

    if (!jwt) {
      return false;
    }
  }

  return await callApi(url, method, payload, jwt);
};

/**
 * This is the method that really performs the api call
 * @param {*} url
 * @param {*} method
 * @param {*} payload
 * @param {*} jwt
 */
const callApi = async (url, method = "get", payload = {}, jwt) => {
  let res;

  try {
    if (method === 'get') {
      res = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_SERVER}${url}`,
        params: { ...payload },
        headers: jwt ? { Authorization: `Bearer ${jwt}` } : {},
      });
    } else {
      res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_SERVER}${url}`,
        data: { ...payload },
        headers: jwt ? { Authorization: `Bearer ${jwt}` } : {},
      });
    }

    return {
      data: res.data,
      status: res.data.status,
      httpStatus: res.status,
      success: res.data.status === "success",
    };
  } catch (e) {
    //TODO try catch errore 500
    return {
      data: e.response.data,
      status: e.response.data.status,
      httpStatus: e.response.status,
      success: e.response.data.status === "success",
    };
  }
};

export const callApiBinary = async (url) => {
  const method = "get";
  const jwt = cookieCutter.get(JWT_COOKIE_TOKEN);

  const res = await axios({
    method: 'get',
    responseType: 'blob',
    url: `${process.env.REACT_APP_API_SERVER}${url}`,
    headers: { Authorization: `Bearer ${jwt}` }
  });

  console.log('res', res);

  return res.data;
};

export default apiHelper;
