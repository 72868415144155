import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from "../../Helpers/apiHelper";
import { Loading } from "../../Components";

import {
  ChatOutline,
  FingerPrintOutline,
  SearchSolid,
  ShoppingCartSolid,
} from "@graywolfai/react-heroicons";
import {
  FormField,
  FilterBar,
  SidebarItems,
  PageTitle,
  Breadcrumbs,
  Chat,
} from "../../Components";

import ContactItem from "./ContactItem";
import ProductItem from "./ProductItem";
import productImage from "./productImage.jpg";
import InteractionCategory from "./InteractionCategory";
import OrderList from "./InteractionPanels/OrderList";
import EventList from "./InteractionPanels/EventList";
import OrderDetail from "./InteractionPanels/OrderDetail";

function Interactions(props) {
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Interactions",
        subtitle: "Open chats and orders about your products with clients and prospects",
        button: {
          visible: false,
        },
      },
    });
  }, []);

  /* Vibilità colonne */
  var [contactsVisible, setContactsVisible] = useState(true);
  var [contactsCompact, setContactsCompact] = useState(false);
  var [productsVisible, setProductsVisible] = useState(false);
  var [productsCompact, setProductsCompact] = useState(false);
  var [interactionsVisible, setInteractionsVisible] = useState(false);
  var [detailsVisible, setDetailsVisible] = useState(false);
  var [chatsVisible, setChatsVisible] = useState(false);
  var [ordersVisible, setOrdersVisible] = useState(false);
  var [orderDetailVisible, setOrderDetailVisible] = useState(false);
  var [orderDetail, setOrderDetail] = useState(false);
  var [eventsVisible, setEventsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactsData, setContactsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [countsData, setCountsData] = useState({chat: 0, order: 0, event: 0});

  const [selectedContact, setSelectedContact] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedContactId, setSelectedContactId] = useState();
  const [selectedProductId, setSelectedProductId] = useState();

  const safe = (val) => {
    return (val === undefined || val === null) ? "" : "" + val
  };

  const loadContactsData = async() => {
    setIsLoading(true);
    const loadedContactsData = await apiHelper(
      "/api/v1/admin/interaction/contacts", "get", {}
    );

    const normalized = loadedContactsData.data.data.result.map(contact => {
      return {
        name      : (contact.name || contact.lastName) ? safe(contact.name) + " " + safe(contact.lastName) : undefined,
        email     : safe(contact.username),
        assignedTo: safe(contact.salesName) + " " + safe(contact.salesLastName),
        unread    : safe(contact.unread),
        id        : contact.id
      }
    })
    setContactsData(normalized);
    setIsLoading(false);
  };

  const loadProductsData = async(contactId) => {
    setIsLoading(true);
    const loadedProductsData = await apiHelper(
      "/api/v1/admin/interaction/products-by-contact", "get", {contactId: contactId}
    );

    const normalized = loadedProductsData.data.data.result.products.map(product => {
      return {
        line      : safe(''),
        name      : safe(product.name),
        productID : safe(product.sku),
        image     : safe(''),
        unread    : safe(product.Interactions.filter(x => x.read===false).length),
        id        : product.id
      }
    });

    setProductsData(normalized);
    setIsLoading(false);
  };

  const loadCountByTypes = async(productId) => {
    setIsLoading(true);
    const loadedCountsData = await apiHelper(
      "/api/v1/admin/interaction/count-for-type", "get", {contactId: selectedContactId, productId: productId}
    );

    setCountsData(loadedCountsData.data.data.result) 

    setIsLoading(false);
  };

  useEffect(() => {
    loadContactsData();
  }, []);

  //hides objects so that they are completely disabled
  useEffect(() => {
    if (!detailsVisible) {
      setChatsVisible(false)
      setOrdersVisible(false)
      setOrderDetailVisible(false)
      setEventsVisible(false)
    }
  }, [detailsVisible]);

  const handleContactClick = async(contact) => {

    console.log('handleContactClick', contact);

    await loadProductsData(contact.id);
    setSelectedContact(contact.name ? contact.name : contact.email);
    setSelectedContactId(contact.id);
    setChatsVisible(false);
    setProductsVisible(!productsVisible);
    //setContactsCompact(!contactsCompact);
    setContactsVisible(false);
    setInteractionsVisible(false);
    setDetailsVisible(false);
  };

  const handleProductClick = async (productId, productName) => {

    console.log('handleProductClick', productId, productName);

    await loadCountByTypes(productId)

    setSelectedProduct(productName);
    setSelectedProductId(productId);
    setContactsVisible(false);
    setProductsVisible(false);
    setInteractionsVisible(true);
    setDetailsVisible(false);
  }

  const handleBreadCrumbContact = async(e) => {
    e.preventDefault();
    await loadContactsData()
    setSelectedContact();
    setSelectedProduct();
    setContactsVisible(true);
    setProductsVisible(false);
    setInteractionsVisible(false);
    setDetailsVisible(false);
  }

  const handleBreadCrumbProduct = async(e) => {
    e.preventDefault();
    await loadProductsData(selectedContactId);
    setSelectedProduct();
    setContactsVisible(false);
    setProductsVisible(true);
    setInteractionsVisible(false);
    setDetailsVisible(false);
  }

  const clickChat = () => {
    setDetailsVisible(true);
    setChatsVisible(true);
    setOrdersVisible(false);
    setEventsVisible(false);
    setOrderDetailVisible(false);
  }

  const clickOrders = () => {
    setDetailsVisible(true);
    setChatsVisible(false);
    setOrdersVisible(true);
    setEventsVisible(false);
    setOrderDetailVisible(false);
  }

  const clickEvents = () => {
    setDetailsVisible(true);
    setChatsVisible(false);
    setOrdersVisible(false);
    setEventsVisible(true);
    setOrderDetailVisible(false);
  }

  const handleOrderDetail = (order) => {
    setOrderDetail(order);
    setOrdersVisible(false);
    setOrderDetailVisible(true);
  }


  return (
    <div className="backend__page">
      {/* Barra dei filtri */}
      {/* <FilterBar>
        <FormField
          className="flex-grow"
          withLabel="0"
          type="text"
          name="linesearch"
          placeholder="Search chat by name, contact or contents..."
        >
          <SearchSolid className="mid-icon"></SearchSolid>
        </FormField>
      </FilterBar> */}

      <div className="breadcrumbs__container">
          <Breadcrumbs
            contact={selectedContact}
            product={selectedProduct}
            onContactClick={handleBreadCrumbContact}
            onProductClick={handleBreadCrumbProduct}
          />
      </div>

      { isLoading ? (
        <Loading />
      ) : (
        <div className="interaction__container">
          <div className={`visible-${contactsVisible}`}>
          {contactsData.map((contact) => {
              return (
                <ContactItem
                  key={'ContactItem-' + contact.id}
                  name={contact.name}
                  email={contact.email}
                  assigned={contact.assignedTo}
                  onClick={() => handleContactClick(contact)}
                  compact={contactsCompact}
                  unread={contact.unread}></ContactItem>
              );
            })}
          </div>

          <div className={`visible-${productsVisible}`}>
            {productsData.map((product) => {
              return (
                <ProductItem
                  key={'ProductItem-' + product.id}
                  line={product.name}
                  productID={product.sku}
                  image={productImage}
                  unread={product.unread}
                  compact={productsCompact}
                  onClick={() => handleProductClick(product.id, product.name)} />
              );
            })}
          </div>

          <div className={`visible-${interactionsVisible}`}>
            <InteractionCategory category="Chats" onClick={() => clickChat()} unread={safe(countsData.chat)}>
              <ChatOutline className="mid-icon mr-1"></ChatOutline>
            </InteractionCategory>
            <InteractionCategory category="Orders" onClick={() => clickOrders()} unread={safe(countsData.order)}>
              <ShoppingCartSolid className="mid-icon mr-1"></ShoppingCartSolid>
            </InteractionCategory>
            <InteractionCategory category="Events" onClick={() => clickEvents()} unread={safe(countsData.event)}>
              <FingerPrintOutline className="mid-icon mr-1"></FingerPrintOutline>
            </InteractionCategory>
          </div>

          {detailsVisible && (
          <div className={`visible-${detailsVisible} flex-grow`}>
            {/* chats */}
            {chatsVisible && (
            <div className={`interactions__chats visible-${chatsVisible}`}>
              {selectedContactId && selectedProductId && (
                <Chat
                  show={chatsVisible}
                  contactId={selectedContactId}
                  productId={selectedProductId} />
              )}
            </div>
            )}

            {/* orders */}
            {ordersVisible && (
            <div className={`interactions__orders visible-${ordersVisible}`}>
              {selectedContactId && selectedProductId && (
                <OrderList show={chatsVisible}
                  contactId={selectedContactId}
                  productId={selectedProductId}
                  onDetailClick={handleOrderDetail} />
              )}
            </div>
            )}

            {/* orders detail */}
            {orderDetailVisible && (
            <div className={`interactions__orders visible-${orderDetailVisible}`}>
              <OrderDetail order={orderDetail} />
            </div>
            )}
            

            {/* events */}
            {eventsVisible && (
            <div className={`interactions__events visible-${eventsVisible}`}>
              {selectedContactId && selectedProductId && (
                <EventList
                  contactId={selectedContactId}
                  productId={selectedProductId} />
              )}
            </div>
            )}

          </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Interactions;
