import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import { LinkSolid } from "@graywolfai/react-heroicons";
import useSWR from "swr";
import apiHelper from "../../Helpers/apiHelper";

import { Button, FormField, Alert, Radio } from "../../Components";

function ContactInvite() {
  const invite_contact_api_url = `/api/v1/admin/user/contact`;
  const line_list_api_url = `/api/v1/admin/line`;
  const get_company_settings_api_url = `/api/v1/admin/company`;
  const fronted_base_url = process.env.REACT_APP_FRONTEND_BASE_URL;

  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  
  const [name, setName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [line, setLine] = useState('');
  const [type, setType] = useState();
  const [clientGroups, setClientGroups] = useState([]);
  const [clientGroupId, setClientGroupId] = useState('');

  // Getting lines
  const { data: lines } = useSWR(
    [line_list_api_url, "get"],
    (url, method) => apiHelper(url, method),
    { suspense: true }
  );

  useEffect(async() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Invite a client",
        subtitle: "Please fill all the data to invite a client to use Walkr platform",
        button: {
          visible: false,
        },
      },
    });

    // Loading client groups
    const settingsData = await apiHelper(
      "/api/v1/admin/company", "get", {}
    );
    setClientGroups(settingsData.data.data.company.ClientGroups);
    console.log('setClientGroups', settingsData.data.data.company.ClientGroups);
  }, []);

  const [validationError, setValidationError] = useState({
    success: true,
    type: "error",
    title: "Validation error",
    text: "Validation text",
  });
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);

  const handleInvite = async () => {
    setSubmitButtonEnabled(false);

    const data = {
      name: name,
      lastName: lastName,
      email: email,
      businessName: company,
      line: line,
      accessType: type,
      clientGroup: clientGroupId !== '' ? clientGroupId : null
    };
    

    if (validateFields()) {
      const backendCompanyRes = await apiHelper(get_company_settings_api_url, 'get', {});
      if (backendCompanyRes.status !== 'success') {

        setValidationError({
          type: "error",
          title: "Error",
          text: `An error has occurred, can't obtain company informations`,
          success: false,
        });

        setSubmitButtonEnabled(true);
      } else {
        let companySlug = backendCompanyRes.data.data.company.slug;
        const res = await apiHelper(invite_contact_api_url, 'post', data);
        if (res.status === 'success') {
          const uuid = res.data.data.invitation.uuid;

          setValidationError({
            type: "success",
            title: "Success!",
            text: `Invite was created successfully: send this link to your customer (click link to copy to clipboard):<br/><button class="badge-info my-2" onClick="navigator.clipboard.writeText('${fronted_base_url}/${companySlug}/invitation/${uuid}')">${fronted_base_url}/${companySlug}/invitation/${uuid}</button>`,
            success: false,
          });

          //setSubmitButtonEnabled(true);
          
        } else {
          console.log(res)
          setValidationError({
            title: 'Generic error',
            text: res.data.data.message,
            success: false,
            type: 'error'
          });

          setSubmitButtonEnabled(true);
        }

      }

    }
  };

  const validateFieldFilled = (field, text) => {
    // Validate fields
    if (field === undefined || field === "") {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitButtonEnabled(true);
      return false;
    }

    return true;
  };

  const validateFieldUrl = (field, text) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    if (!pattern.test(field)) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitButtonEnabled(true);
      return false;
    }

    return true;
  };

  const validateFields = () => {
    if (
      !validateFieldFilled(name, "Please fill name field") ||
      !validateFieldFilled(email, "Please fill email field") || 
      !validateFieldFilled(company, "Please fill company field") || 
      !validateFieldFilled(line, "Please fill line field") || 
      !validateFieldFilled(type, "Please fill type field") 
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="grid grid-cols-4 min-h-screen">
  
      <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">
        {!validationError.success && (
          <Alert
            type={validationError.type}
            title={validationError.title}
            text={validationError.text} />
        )}

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Name</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => { setName(e.target.value); }}
                value={name}
                withLabel="0"
                withIcon="1"
                name="name"
                type="text"
                placeholder="First name">
              </FormField>
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Last Name</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => { setLastName(e.target.value); }}
                value={lastName}
                withLabel="0"
                withIcon="1"
                name="last_name"
                type="text"
                placeholder="Last name">
              </FormField>
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Email</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => { setEmail(e.target.value); }}
                value={email}
                withLabel="0"
                withIcon="1"
                name="email"
                type="text"
                placeholder="Email address">
              </FormField>
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Company</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => { setCompany(e.target.value); }}
                value={company}
                withLabel="0"
                withIcon="1"
                name="company"
                type="text"
                placeholder="Company name">
              </FormField>
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Line</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <select
                name="actionselector"
                className="form__dropdown"
                value={line}
                onChange={(e) => {setLine(e.target.value)}}>
                <option key="0" value="" disabled>
                  Choose a line...
                </option>
                {lines.data.data.line.map((line) => {
                  return <option key={line.id} value={line.id}>
                    { line.Localizations.find(x => (x.type === 'title' || x.lang === 'en')).content }
                  </option>;
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Client group</h3>
            <p>Optional field</p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <select
                name="actionselector"
                className="form__dropdown"
                value={clientGroupId}
                onChange={(e) => {setClientGroupId(e.target.value)}}>
                <option key="0" value="">
                  Choose a client group...
                </option>
                {clientGroups.map((clientGroup) => {
                  return <option key={clientGroup.id} value={clientGroup.id}>
                    { clientGroup.name }
                  </option>;
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Type</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <div className="form__optiongroup">
                <Radio
                  group="types"
                  value="registration"
                  label="Registration"
                  checked={type === "registration"}
                  onChange={(e) => setType(e.target.value)} />
                <Radio
                  group="types"
                  value="quick"
                  label="Quick access"
                  checked={type === "quick"}
                  onChange={(e) => setType(e.target.value)} />
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* SIDEBAR */}
      <div className="col-span-4 md:col-span-1 md:pl-8">
        <Button
          type="default-button w-full mt-10 disabled:opacity-30"
          text="Generate invite link"
          onClick={handleInvite}
          disabled={!submitButtonEnabled}>
          <LinkSolid className="w-4 h-4 ml-4"></LinkSolid>
        </Button>
      </div>
    </div>
  );
}

export default ContactInvite;
