import { TrashOutline } from "@graywolfai/react-heroicons";

function SelectedProduct(props) {
  const { product } = props;

  return (
    <div className="proposals__selectedproduct" key={product.id}>
      <a href="#delete" onClick={(e) => { e.preventDefault(); props.onRemove(product.id);}}>
        <TrashOutline className="w-4 h-4 text-red-700" />
      </a>
      {product.imageUrl ? (
        <img src={product.imageUrl} alt={product.name}></img>
      ) : (
        <img src='https://via.placeholder.com/200x/cccccc/5b21b6/?text=Walkr' alt={product.name}></img>
      )}
      <p>{product.name}</p>
    </div>
  );
}

export default SelectedProduct;
