function ClientGroupSelect(props) {
  if (props.options) {
    return (
      <div className={props.className}>
        {props.withLabel ? (
          <label
            className="form__label text-white text-tiny"
            htmlFor="actionselector">
            Client group
          </label>
        ) : null}
        <select name="actionselector" className="form__dropdown" value={props.selected} onChange={props.onChange}>
          <option key="0" value="">
            All client groups
          </option>
          {props.options.map((group) => {
            return <option key={group.id} value={group.id}>{group.name}</option>;
          })}
        </select>
      </div>
    );
  }

  return null;
}

export default ClientGroupSelect;
