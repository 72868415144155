import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from '../../Helpers/apiHelper';
import getLocalization from '../../Helpers/getLocalization';
import useSWR, { mutate } from 'swr';
import moment from 'moment';
import { Link } from "react-router-dom";

import { 
  Table, 
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FilterBar,
  FormField,
  Loading,
  DeleteModal,
  LinkModal,
  Radio
 } from "../../Components";
import { LinkSolid, PencilSolid, SearchSolid, TrashOutline } from "@graywolfai/react-heroicons";

const LineList = () => {
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  const fronted_base_url = process.env.REACT_APP_FRONTEND_BASE_URL;
  const get_company_settings_api_url = `/api/v1/admin/company`;
  //const delete_line_api_url = `/api/v1/admin/line/${lineId}`;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const currentLang = 'en';
  const [lineRows, setLineRows] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchPermission, setSearchPermission] = useState('all');
  const [linkModal, setLinkModal] = useState({
    hidden: true
  });

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Lines / Collections",
        subtitle: "Manage your products collections here",
        button: {
          visible: true,
          name: 'Create new line',
          icon: '',
          link: '/admin/lines/new'
        }
      },
    });
  }, [dispachPageConfig]);

  const { data: settings } = useSWR(
    [get_company_settings_api_url, "get"],
    (url, method) => apiHelper(url, method),
    { revalidateOnFocus: false, suspense: true }
  );
  const companySlug = settings.data.data.company.slug;

  const normalizeLines = (lines) => {
    let rows = [];

    if (lines) {
      lines.data.data.line.forEach((item) => {
        const createdAt = moment(item.createdAt);

        let row = {
          id: item.id,
          name: getLocalization(item.Localizations, currentLang, 'title'),
          creationDate: createdAt.format("D/M/YYYY h:mm"),
          productCount: 'TODO',
          permissions: item.permission,
          visibility: item.visibility,
          picture: 'https://via.placeholder.com/40'
        };

        if (item.Media) {
          row.picture = item.Media.url;
        }

        rows.push(row);
      });
    }

    setLineRows(rows);
  }

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async() => {
    const newData = await apiHelper('/api/v1/admin/line', 'get', {
      search: searchText,
      permission: searchPermission !== 'all' ? searchPermission : null
    });

    normalizeLines(newData);
  }

  const deleteItem = async(lineId) => {
    const delete_api_url = `/api/v1/admin/line/${lineId}/delete`;
    const res = await apiHelper(delete_api_url, 'post', {
      id: lineId,
      type: 'line',
      visibility: 'hidden'
    });
    await mutate('/api/v1/admin/line');
    setShowDeleteModal(false);
    document.location.reload()
  }

  const showUrl = (url) => {
    console.log("showurl", url)
    setLinkModal({
      url: url,
      hidden: false
    });
  };

  const onClosedLinkModal = () => {
    setLinkModal({
      hidden: true
    })
  }

  const rowData = (row) => {
    //console.log('current row', row);

    return (
      <TableRow key={row.id}>
        <TableCell>
          <img src={row.picture} alt={row.name} className="object-cover h-12 w-12" />
          &nbsp;
          <p>{row.name}</p>
        </TableCell>
        <TableCell className="hidden md:table-cell">
          {row.creationDate}
        </TableCell>
        {/* <TableCell className="hidden md:table-cell">
          {row.productCount}
        </TableCell> */}
        <TableCell className="hidden md:table-cell">
          {row.permissions}
        </TableCell>
        <TableCell className="hidden md:table-cell">
          {row.visibility}
        </TableCell>
        <TableCell>
          <a href="#" onClick={(e) => { e.preventDefault(); showUrl(`${fronted_base_url}/${companySlug}/line/${row.id}`)}} target="_blank" className="badge-link uppercase">Open Url</a>
          <Link to={`/admin/lines/edit/${row.id}`}>
            <PencilSolid className="dark-icon w-3 h-3 ml-4 cursor-pointer"></PencilSolid>
          </Link>
          <a href="#delete" onClick={(e) => { e.preventDefault(); setIdToDelete(row.id); setShowDeleteModal(true); }}>
            <TrashOutline className="dark-icon w-3 h-3 ml-4 cursor-pointer"></TrashOutline>
          </a>
        </TableCell>
      </TableRow>
    );
  }

  if (lineRows) {
    return (
      <div className="backend__page">
        <FilterBar onClick={refreshData}>
          <div className="filterbar__inlinefilters flex-grow">
            <span>Filter by:</span>
            <Radio
              className="flex flex-row items-center"
              group="searchPermission"
              name="all"
              label="All"
              value="all"
              checked={searchPermission === "all"}
              onChange={(e) => setSearchPermission(e.target.value)} />
            <Radio
              className="flex flex-row items-center"
              group="searchPermission"
              name="public"
              label="Public"
              value="public"
              checked={searchPermission === "public"}
              onChange={(e) => setSearchPermission(e.target.value)} />
            <Radio
              className="flex flex-row items-center"
              group="searchPermission"
              name="private"
              label="Private"
              value="private"
              checked={searchPermission === "private"}
              onChange={(e) => setSearchPermission(e.target.value)} />
          </div>
          <FormField
            className="flex-grow"
            withLabel="0"
            type="text"
            value={searchText}
            onChange={(e) => {setSearchText(e.target.value)}}
            name="linesearch"
            placeholder="Search by name, description or contents...">
            <SearchSolid className="mid-icon"></SearchSolid>
          </FormField>
        </FilterBar>
  
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell className="hidden md:table-cell">
                Creation date
              </TableCell>
              {/* <TableCell className="hidden md:table-cell">Products</TableCell> */}
              <TableCell className="hidden md:table-cell">Permissions</TableCell>
              <TableCell className="hidden md:table-cell">Visibility</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { lineRows.map((item, i) => { return rowData(item); }) }
          </TableBody>
        </Table>

        <DeleteModal
          title="Are you sure?"
          text="Please confirm that you want to delete the line updating is visibility to hidden"
          hidden={!showDeleteModal}
          dismiss={() => { setShowDeleteModal(false); }}
          delete={() => { deleteItem(idToDelete); }} />
          
        <LinkModal url={linkModal.url} hidden={linkModal.hidden} onCloseHandler={onClosedLinkModal}/>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default LineList;
