import { useState, useEffect, useRef } from 'react';
import { PaperAirplaneSolid } from "@graywolfai/react-heroicons";
import ChatMessage from "./ChatMessage";
import useSWR from "swr";
import apiHelper from "../../Helpers/apiHelper";

function Chat(props) {
  const messagesBoxRef = useRef(null);
  const { contactId, productId, show } = props;
  const [chatLatestTs, setChatLatestTs] = useState(0);
  const [ chatMessages, setChatMessages ] = useState([]);
  const [ newMessage, setNewMessage ] = useState('');

  const { data: chat, mutate } = 
    useSWR(
      `/api/v1/admin/interaction/interactions-by-type?contactId=${contactId}&productId=${productId}&type=chat&lastTs=${chatLatestTs}`,
      apiHelper,
      { refreshInterval: 3000 }
    );

  
  const scrollToBottom = () => {
    console.log('scrollToBottom');
    //messagesBoxRef.current.scrollTop(1000000000);
    //messagesBoxRef.current.scrollIntoView(true);
    messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
  };

  useEffect(() => {
    if (chat && chat.data.data.result) {
      console.log('chat messages', chat.data.data.result);
      setChatMessages([...chatMessages, ...chat.data.data.result]);

      if (chat.data.data.result.length > 0) {
        const lastestMessage = chat.data.data.result[chat.data.data.result.length-1];
        const createdAt = new Date(lastestMessage.createdAt);
        // console.log('lastestMessage', createdAt.getTime());
        setChatLatestTs(createdAt.getTime());
      }

      scrollToBottom();
    }
  }, [chat]);

  const handleSendNewMessage = async() => {
    if (newMessage !== '') {
      await apiHelper('/api/v1/admin/chat', 'post', {
        message: newMessage,
        productId: productId,
        userId: contactId
      });
      setNewMessage('');
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendNewMessage();
    }
  }

  if (!show) {
    return null;
  } else {
    return (
      <div className="flex-grow flex absolute w-full h-full">
        <div className="flex flex-1 justify-between flex-col bg-white sm:pt-16 md:pt-0 md:h-auto md:min-h-96 md:border md:border-gray-100 md:mb-8">
          <div
            ref={messagesBoxRef}
            id="messages"
            className="h-full space-y-4 p-4 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
            {chatMessages.map(message => {
              return (
                <ChatMessage key={message.id} sender={message.user_id === contactId ? 'user' : 'company'}>
                  {message.message}
                </ChatMessage>
              )
            })}
          </div>

          <div className="bg-purple-800 p-1 sm:py-4 sm:px-6">
            <div className="relative flex items-center justify-between">
              <input
                onKeyDown={handleKeyDown}
                value={newMessage}
                onChange={e => { setNewMessage(e.target.value); }}
                type="text"
                placeholder="Chat with customer"
                className="w-full bg-white border border-gray-100 shadow-sm hover:shadow-md rounded-sm text-sm px-2 py-2 text-gray-600 placeholder-gray-400 focus:placeholder-gray-300 focus:outline-none focus:ring-transparent focus:ring-offset-transparent focus:ring-offset-0"
              />
              <div className="absolute flex right-0 items-center">
                <button onClick={handleSendNewMessage}>
                  <PaperAirplaneSolid className="transform rotate-90 text-purple-800 w-5 h-5 mr-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
