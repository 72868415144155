import React, { Suspense, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Loading } from '../../Components';
import SALoggedLayout from "./SALoggedLayout";

import apiHelper from "../../Helpers/apiHelper";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";

const SALoggedRoutes = ({ component: Component, ...rest }) => {

  let history = useHistory();

  const [user, setUser] = useState(undefined)

  useEffect(async() => {
    let userResponse = await apiHelper('/api/v1/admin/user/me')
    if (userResponse.httpStatus !== 200) {
      //console.log('LoggedRoutes: Not logged');
      history.push("/login");
    } else {
      //console.log("UTENTE LOGGATO")
      setUser(userResponse)
    }
  }, [])

  if (user && user.httpStatus === 200 && user.data.data.user.Roles[0].name === 'ROLE_SUPERADMIN') {

    return (
      <Suspense fallback={Loading}>
        <Route
          {...rest}
          render={(props) => (
            <SALoggedLayout user={user.data.data.user}>
              <Component {...props} user={user.data.data.user} />
            </SALoggedLayout>
          )}
        />
      </Suspense>
    )

  } else {
    return <Loading />;
  }
};

export default SALoggedRoutes;