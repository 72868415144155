import { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../../Components";
import {
  CheckCircleOutline,
  EyeOutline,
  TrashOutline,
} from "@graywolfai/react-heroicons";
import useSWR from "swr";
import apiHelper from "../../../Helpers/apiHelper";
import moment from 'moment';

function OrderList(props) {
  const { contactId, productId } = props;

  const [orderList, setOrderList] = useState([]);

  const {
    data: orders,
  } = useSWR(
    `/api/v1/admin/interaction/interactions-by-type?contactId=${contactId}&productId=${productId}&type=order`,
    apiHelper,
    { refreshInterval: 60000 }
  );

  useEffect(() => {
    if (orders) {
      setOrderList(orders.data.data.result);
    }
  }, [orders]);

  const handleDetailsClick = (e, id) => {
    e.preventDefault();
    props.onDetailClick(id);
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Order N°</TableCell>
          <TableCell>Date</TableCell>
          {/* <TableCell>Status</TableCell> */}
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderList.map((order) => {
          return (
            <TableRow key={order.id} className="text-xs">
              <TableCell>{order.id}</TableCell>
              <TableCell>{moment(order.createdAt).format("D/M/YYYY")}</TableCell>
              {/* <TableCell>
                <span className="statuslabel statuslabel-success">open</span>
                <span className="statuslabel statuslabel-error">closed</span>
                <span className="statuslabel statuslabel-warning">trashed</span>
              </TableCell> */}
              <TableCell>
                <a href="#order-details" onClick={(e) => { handleDetailsClick(e, order) }}>
                  <EyeOutline className="dark-icon w-4 h-4 cursor-pointer"></EyeOutline>
                </a>
                {/* <CheckCircleOutline className="dark-icon w-4 h-4 ml-4 cursor-pointer"></CheckCircleOutline> */}
                {/* <TrashOutline className="dark-icon w-4 h-4 ml-4 cursor-pointer"></TrashOutline> */}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default OrderList;
