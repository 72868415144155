function Radio(props) {
  return (
    <div className="mb-1">
      <input
        type="radio"
        {...props}
        className="border border-gray-200 bg-white mr-2 rounded-lg text-purple-700"
      />
      <label htmlFor={props.name} className="text-xs text-gray-500">
        {props.label}
      </label>
    </div>
  );
}

export default Radio;
