import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from '../../Helpers/apiHelper';
import useSWR, { mutate } from 'swr';
import getLocalization from '../../Helpers/getLocalization';
import moment from 'moment';
import { 
  Table, 
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FilterBar,
  FormField,
  Loading,
  DeleteModal,
  MediaPreview
 } from "../../Components";
import { LinkSolid, PencilSolid, SearchSolid, TrashOutline, CloudUploadOutline } from "@graywolfai/react-heroicons";
import { Link } from "react-router-dom";


function MediaList() {
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Media gallery",
        subtitle: "Upload your product pictures and attachments here",
        button: {
          visible: true,
          name: 'Start import',
          icon: ( <CloudUploadOutline className="light-icon ml-2" /> ),
          link: '/admin/media/import'
        }
      },
    });
  }, []);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);

  const [mediaRows, setMediaRows] = useState();
  const [searchText, setSearchText] = useState('');

  // const { data: mediaData } = useSWR('/api/v1/admin/media', apiHelper, { suspense: true });

  const normalizeMedias = (medias) => {
    let rows = [];
    
    medias.data.data.medias.forEach((item) => {
      const createdAt = moment(item.createdAt);

      // console.log('item', item);

      rows.push({
        id: item.id,
        name: item.name,
        picture: item.url,
        creationDate: createdAt.format("D/M/YYYY h:mm"),
        company_id: item.company_id
      });
    });

    // console.log('rows', rows);

    setMediaRows(rows);
  }

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async() => {
    const newData = await apiHelper('/api/v1/admin/media', 'get', {
      search: searchText
    });

    normalizeMedias(newData);
  }

  const deleteItem = async(mediaId) => {
    const delete_api_url = `/api/v1/admin/media/${mediaId}/delete`;
    const res = await apiHelper(delete_api_url, 'post', {});
    mutate('/api/v1/admin/media');
    setShowDeleteModal(false);
  }

  const rowData = (row) => {
    //console.log('current row', row);

    return (
      <TableRow key={row.id}>
        <TableCell>
          <MediaPreview
            controls={false}
            className="object-cover h-12 w-12"
            previewFeaturedImage={row.picture} 
           />
           {/* <img src={row.picture} className="object-cover h-12 w-12" alt="" /> */}
          &nbsp;
          <p>{row.name}</p>
        </TableCell>
        <TableCell className="hidden md:table-cell">
          {row.creationDate}
        </TableCell>
        
        <TableCell>
          <a href={row.picture} target="_blank" rel="noreferrer">
            <LinkSolid className="dark-icon w-3 h-3 cursor-pointer"></LinkSolid>
          </a>
          {/* <Link to={'/admin/media/edit/' + row.id}>
            <PencilSolid className="dark-icon w-3 h-3 ml-4 cursor-pointer"></PencilSolid>
          </Link> */}
          <a href="#delete" onClick={(e) => { e.preventDefault(); setIdToDelete(row.id); setShowDeleteModal(true); }}>
            <TrashOutline className="dark-icon w-3 h-3 ml-4 cursor-pointer"></TrashOutline>
          </a>
        </TableCell>
      </TableRow>
    );
  }

  if (mediaRows) {
    return (
      <div className="backend__page">
        <FilterBar onClick={refreshData}>
          <FormField
            className="flex-grow"
            withLabel="0"
            type="text"
            name="linesearch"
            value={searchText}
            onChange={(e) => {setSearchText(e.target.value)}}
            placeholder="Search by name, description or contents...">
            <SearchSolid className="mid-icon"></SearchSolid>
          </FormField>
        </FilterBar>
  
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Filename</TableCell>
              <TableCell className="hidden md:table-cell">Upload date</TableCell>
              {/* <TableCell className="hidden md:table-cell">Related product</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { mediaRows.map((item, i) => { return rowData(item); }) }
          </TableBody>
        </Table>

        <DeleteModal
          title="Are you sure?"
          text="Please confirm that you want to delete the media file"
          hidden={!showDeleteModal}
          dismiss={() => { setShowDeleteModal(false); }}
          delete={() => { deleteItem(idToDelete); }} />
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default MediaList;
