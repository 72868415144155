import { useState, useEffect } from 'react';
import { Checkbox } from "../";
import { SearchOutline } from "@graywolfai/react-heroicons";

function FilterSidebar(props) {
  const { facets, locale, lineId, loading } = props;
  const [taxonomies, setTaxonomies] = useState();
  // const [ selectedTaxonomies, setSelectedTaxonomies ] = useState();

  const normalizeArraySolrData = (data) => {
    let returnArray = [];

    for(let i = 0; i<data.length; i+=2) {
      returnArray.push({
        name: data[i],
        value: data[i+1]
      })
    }

    return returnArray;
  }

  useEffect(() => {
    if (facets && facets.facet_translations) {
      const taxs = [];

      console.log('FilterSidebar facets', [facets, locale, lineId]);

      for (let i=1; i<=5; i++) {
        const key = `tax_${lineId}_${i}_${locale}`;
        // console.log(key);
        if (facets.facet_translations.find(x => x[key])) {
          const taxValues = facets.facet_counts.facet_fields[key];
  
          taxs.push({
            index: i,
            key: key,
            title: facets.facet_translations.find(x => x[key])[key],
            lineId: lineId,
            data: normalizeArraySolrData(taxValues)
          });
        }
      }
  
      setTaxonomies(taxs);
    }
  }, [facets, lineId]);

  const getTaxonomy = (taxonomy) => {
    if (taxonomy) {
      // console.log('getTaxonomy', taxonomy);
      return (
        <div className="mb-10">
          <div className="uppercase text-gray-600 text-xs font-light mb-2">
            { taxonomy.title }
          </div>
          {taxonomy.data.map(item => {
            return (
              <Checkbox
                disabled={loading}
                name={item.name}
                counter={item.value}
                label={item.name}
                value={props.taxonomyFilters.find(x => x.name===item.name && x.taxonomy===taxonomy.key) !== undefined}
                taxonomy={taxonomy.key}
                onChange={(e) => { console.log('dentro il componente'); props.handleTaxonomyChange(e) }}
              />
            );
           
          })}
        </div>
      );
    }
  }

  const getTaxonomies = () => {
    if (taxonomies) {
      return taxonomies.map((taxonomy) => getTaxonomy(taxonomy));
    }
  }

  return (
    <div className="p-8">
      <h3 className="text-lg md:text-xl font-bold text-gray-700 mb-2">
        Search
      </h3>

      <div className="mb-10">
        <div className="relative">
          <input
            type="text"
            name="search"
            value={props.searchText}
            onChange={(e) => { props.setSearchText(e.target.value); }}
            placeholder="Search by keyword..."
            className="bg-white px-3 py-2 rounded border border-gray-200 text-xs text-gray-700 placeholder-gray-400 italic font-light focus:outline-none w-full"
          />
          <button type="submit" className="absolute right-0 top-0 mt-2.5 mr-3">
            <SearchOutline className="mid-icon" onClick={() => {
              props.onTextSearch(props.searchText)}
            }/>
          </button>
        </div>
      </div>

      { getTaxonomies() }
    </div>
  );
}

export default FilterSidebar;
