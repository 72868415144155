function TableCell(props) {
  return (
    <td className={props.className}>
      <div className="flex flex-row space-x-4 items-center">
        {props.children}
      </div>
    </td>
  );
}

export default TableCell;
