import { useState } from "react";
import { Button, FormField, Radio } from "../../../Components";
import {
  SearchSolid,
} from "@graywolfai/react-heroicons";
import SalesPeopleSelect from "./SalesPeopleSelect";
import ClientGroupSelect from "./ClientGroupSelect";

function Filters({ 
    salesPeople,
    selectedArray,
    onFilter,
    assignedFilterShown,
    invitesFilterShown,
    clientGroupsFilterShown,
    clientGroups
  }) {
  const [filterAssigned, setFilterAssigned] = useState('all');
  const [filterAssignedEmail, setFilterAssignedEmail] = useState();
  const [filterClientGroup, setFilterClientGroup] = useState();
  const [filterText, setFilterText] = useState('');
  const [filterInvites, setFilterInvites] = useState('all');
  
  return (
    <div className="filterbar__container hidden md:flex flex-row items-center justify-between space-x-4 p-4 mb-4 bg-gray-50">
      { invitesFilterShown && (
        <div className="filterbar__inlinefilters flex-grow">
          <span>Filter by:</span>
          <Radio
            className="flex flex-row items-center"
            group="inviteStatus"
            name="All"
            label="All"
            value="all"
            checked={filterInvites === "all"}
            onChange={(e) => setFilterInvites(e.target.value)} />
          <Radio
            className="flex flex-row items-center"
            group="inviteStatus"
            name="Pending"
            label="Pending"
            value="pending"
            checked={filterInvites === "pending"}
            onChange={(e) => setFilterInvites(e.target.value)} />
          <Radio
            className="flex flex-row items-center"
            group="inviteStatus"
            name="Accepted"
            label="Accepted"
            value="accepted"
            checked={filterInvites === "accepted"}
            onChange={(e) => setFilterInvites(e.target.value)} />
          <Radio
            className="flex flex-row items-center"
            group="inviteStatus"
            name="Revoked"
            label="Revoked"
            value="revoked"
            checked={filterInvites === "revoked"}
            onChange={(e) => setFilterInvites(e.target.value)} />
          
        </div>
      )}

      { assignedFilterShown && (
        <div className="filterbar__inlinefilters flex-grow">
          <span>Filter by:</span>
          <Radio
            className="flex flex-row items-center"
            group="assignStatus"
            name="All"
            label="All"
            value="all"
            checked={filterAssigned === "all"}
            onChange={(e) =>  setFilterAssigned(e.target.value)} />
          <Radio
            className="flex flex-row items-center"
            group="assignStatus"
            name="Unassigned"
            label="Unassigned"
            value="unassigned"
            checked={filterAssigned === "unassigned"}
            onChange={(e) =>  setFilterAssigned(e.target.value)} />
          <Radio
            className="flex flex-row items-center"
            group="assignStatus"
            name="Assigned to"
            label="Assigned to"
            value="assigned"
            checked={filterAssigned === "assigned"}
            onChange={(e) =>  setFilterAssigned(e.target.value)} />
          <SalesPeopleSelect
            className="flex-shrink"
            options={salesPeople}
            onChange={(e) => { setFilterAssignedEmail(e.target.value); }} />
        </div>
      )}

      {clientGroupsFilterShown && (
        <ClientGroupSelect
          className="flex-shrink"
          options={clientGroups}
          onChange={(e) => { setFilterClientGroup(e.target.value); }} />
      )}

      <FormField
        className="flex-grow"
        withLabel="0"
        type="text"
        name="linesearch"
        placeholder="Search contact..."
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}>
        <SearchSolid className="mid-icon"></SearchSolid>
      </FormField>

      <Button type="muted-button" text="Apply filters" onClick={() => { 
        onFilter(
          filterAssigned,
          filterText,
          filterAssignedEmail,
          filterInvites,
          filterClientGroup
        ) }}></Button>
    </div>
  );
}


export default Filters;