import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import { SaveAsSolid } from "@graywolfai/react-heroicons";
import useSWR from "swr";
import apiHelper from "../../Helpers/apiHelper";

import { Button, FormField, Alert, Radio } from "../../Components";

function SAArsutoriaImportNew() {
  const start_import_api_url = `/api/v1/saadmin/as-import`;
  const get_company_settings_api_url = `/api/v1/admin/company`;
  const import_from_elvis_api_url = `/api/v1/admin/company`;
  const fronted_base_url = process.env.REACT_APP_FRONTEND_BASE_URL;

  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  
  const [companyId, setCompanyId] = useState(100005);
  const [lineId, setLineId] = useState('');
  //const [path, setPath] = useState('/RIVISTE/Arsutoria/Arsutoria 473/5609_MIC_FOTO (40 pag)')
  const [path, setPath] = useState('')
  //const [path, setPath] = useState('/UPL/Fiere/2022/20220316_Micam AW22-23/FORNARI 01')
  const [lines, setLines] = useState([])

  const arsutoriaCompanies = [
    {id: 100005, name: 'Arsutoria Shoes'},
    {id: 100006, name: 'Arsutoria Bags'},
    {id: 100007, name: 'Arsutoria'},
  ]

  const reloadLines = async () => {
    // Loading lines for default company
    const linesData = await apiHelper(
      "/api/v1/saadmin/line", "get", {
        companyId: companyId
      }
    );
    setLines(linesData.data.data.line);
    setLineId(undefined)
    console.log('linesData', linesData);
  }


  useEffect(async() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Import From Elvis",
        subtitle: "Import photos from Elvis to Arsutoria companies' lines",
        button: {
          visible: false,
        },
      },
    });

    await reloadLines();
  }, []);

  useEffect(async() => {
    await reloadLines()
  }, [companyId])
  

  const [validationError, setValidationError] = useState({
    success: true,
    type: "error",
    title: "Validation error",
    text: "Validation text",
  });
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);

  const handleImport = async () => {
    setSubmitButtonEnabled(false);
    
    if (validateFields()) {

      const data = {
        companyId: companyId,
        lineId: lineId,
        companyName: arsutoriaCompanies.filter((item)=>{return item.id === Number(companyId)})[0].name,
        lineName: lines.filter((item)=>{return ('' + item.id) === lineId})[0].Localizations.find(x => (x.type === 'title' || x.lang === 'en')).content,
        path: path,
      };
      
      const backendCompanyRes = await apiHelper(start_import_api_url, 'post', data);
      if (backendCompanyRes.status !== 'success') {

        setValidationError({
          type: "error",
          title: "Error",
          text: `An error has occurred, please check if import is already running`,
          success: false,
        });

        setSubmitButtonEnabled(true);
      } else {

        setValidationError({
          type: "success",
          title: "Success!",
          text: `Import was started, check progress in import list page`,
          success: false,
        });

        setSubmitButtonEnabled(false);
        
      }

    }
  };

  const validateFieldFilled = (field, text) => {
    console.log('field is ' + field)
    // Validate fields
    if (field === undefined || field === "") {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitButtonEnabled(true);
      return false;
    }

    return true;
  };

  const validateFields = () => {
    if (
      !validateFieldFilled(companyId, "Please select the company") || 
      !validateFieldFilled(lineId, "Please select a line where products will be imported into") || 
      !validateFieldFilled(path, "Please fill path field") 
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="grid grid-cols-4 min-h-screen">
  
      <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">
        {!validationError.success && (
          <Alert
            type={validationError.type}
            title={validationError.title}
            text={validationError.text} />
        )}

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Company</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <select
                name="actionselector"
                className="form__dropdown"
                value={companyId}
                onChange={(e) => {setCompanyId(e.target.value)}}>
                  {arsutoriaCompanies.map((ac)=>{
                    return <option key={'company-' + ac.id} value={ac.id}>[{ac.id}] {ac.name}</option>
                  })}
              </select>
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Line</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <select
                name="actionselector"
                className="form__dropdown"
                value={lineId}
                onChange={(e) => {setLineId(e.target.value)}}>
                <option key="0" value="" disabled>
                  Choose a line...
                </option>
                {/* {lines.data.data.line.map((line) => { */}
                {lines.map((line) => {
                  return <option key={line.id} value={line.id}>[{line.id}] { line.Localizations.find(x => (x.type === 'title' || x.lang === 'en')).content }
                  </option>;
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Elvis path</h3>
            <p></p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => { setPath(e.target.value); }}
                value={path}
                withLabel="0"
                withIcon="1"
                name="path"
                type="text"
                placeholder="Path to Elvis">
              </FormField>
            </div>
          </div>
        </div>

      </div>

      {/* SIDEBAR */}
      <div className="col-span-4 md:col-span-1 md:pl-8">
        <Button
          type="default-button w-full mt-10 disabled:opacity-30"
          text="IMPORT products"
          onClick={handleImport}
          disabled={!submitButtonEnabled}>
          <SaveAsSolid className="w-4 h-4 ml-4"></SaveAsSolid>
        </Button>
      </div>
    </div>
  );
}

export default SAArsutoriaImportNew;
