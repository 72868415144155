import { useState } from "react";
import SidebarItems from "./SidebarItems";
import { Link } from "react-router-dom";

import {
  MenuOutline,
  CogOutline,
  ChevronDownSolid,
  UserRemoveOutline,
  XOutline,
} from "@graywolfai/react-heroicons";
import LogoImg from '../../assets/images/walkr-logo.png';
import cookieCutter from "cookie-cutter";
import { useHistory } from "react-router-dom";

function SANavbar(props) {
  let history = useHistory();

  const [isVisible, setVisible] = useState(false);
  const { user } = props;
  console.log('Navbar', props);

  const handleLogout = (e) => {
    e.preventDefault();
    const JWT_COOKIE_TOKEN = "jwt_admin_cookie_token";
    const COOKIE_VALIDITY_DATE = new Date(0);
    cookieCutter.set(JWT_COOKIE_TOKEN, "", {
      expires: COOKIE_VALIDITY_DATE,
      path: "/",
      httpOnly: false,
    });

    history.push("/login");
  }

  return (
    <>
      <nav className="w-100 flex flex-row flex-nowrap items-center justify-between bg-gray-900">
        <div id="brand-logo" className="flex flex-row items-center md:pl-4">
          <span
            className="px-3 md:hidden"
            onClick={() => setVisible(!isVisible)}>
            <MenuOutline className="text-white w-6 h-6" />
          </span>
          <img src={LogoImg} alt="" /><span className="text-white pl-2 font-extrabold uppercase">- SUPER ADMIN -</span>
        </div>

        <div
          id="nav-components"
          className="flex flex-row flex-nowrap content-center justify-items-end text-tiny">
          <div className="relative">
            <input
              type="checkbox"
              id="navbar-dropdown"
              className="hidden absolute"
            />
            <label
              htmlFor="navbar-dropdown"
              className="flex items-center cursor-pointer bg-gray-800 p-4">
              <span className=" text-white uppercase font-semibold mr-2 hidden md:inline">
                Menu
              </span>
              <MenuOutline className="light-icon" />
            </label>

            <div
              id="navbar-menu"
              className="absolute mt-0 right-0 top-full min-w-max hidden bg-gray-800 transition delay-75 ease-in-out z-10">
              {/* Componenti del menu da loggato */}
              <p className="text-gray-500 py-3 px-4">
                Welcome back
                <br />
                <span className="text-white">{user.name} {user.lastName}</span>
              </p>
              <ul className="block text-left text-tiny text-gray-300 uppercase font-semibold pb-3 px-4">
                {/* <li className="block border-b border-gray-700 pr-6 py-3 hover:text-white ">
                  <a href="#dashboard">
                    <CogOutline className="mid-icon inline mr-1" /> Dashboard
                  </a>
                </li>{" "} */}
                {/* Visibile solo agli account aziendali, non agli utenti */}
                {/* <li className="block pr-6 pt-3">
                  <Link to="/en">
                    <ChevronDownSolid className="mid-icon inline mr-1" />{" "}
                    English
                  </Link>
                </li> */}
              </ul>
              <div className="block pr-6 pl-4 py-3 bg-red-800 text-tiny">
                <a href="#logout" onClick={handleLogout} className="uppercase text-white font-semibold">
                  <UserRemoveOutline className="light-icon inline mr-1" />{" "}
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar backend visibile solo da mobile */}
      <ul className={`sidebar__container visible-${isVisible}`}>
        <span onClick={() => setVisible(!isVisible)}>
          <XOutline className="text-white absolute top-4 right-4 w-8 h-8" />
        </span>
        <SidebarItems user={props.user} />
      </ul>
    </>
  );
}

export default SANavbar;
