const getLocalization = (data, lang, type) => {
  let item = data.find(x => (x.lang === lang && x.type === type));
  if (!item) {
    item = data.find(x => (x.lang === 'en' && x.type === type));
  }
  if (!item) {
    return 'Missing translation (' + type + ')';
  }

  return item.content;
}

export default getLocalization;