import { ChevronRightSolid } from "@graywolfai/react-heroicons";

function Breadcrumbs(props) {

  return (
    <nav
      className="w-100 flex text-tiny lg:text-xs justify-start font-bold uppercase mb-2"
      aria-label="Breadcrumb">

      {(props.contact) ? (
        <a href="#contact" className="flex items-center text-purple-700" onClick={props.onContactClick}>
          {props.contact} <ChevronRightSolid className="mid-icon" />
        </a>
      ) : (
        <a href="#home3" className="flex items-center text-gray-700">
          Please select a contact
        </a> 
      )}

      {(props.product) && (
        <a href="#product" className="flex items-center text-purple-700" onClick={props.onProductClick}>
          {props.product} <ChevronRightSolid className="mid-icon" />
        </a>
      )}

      {(!props.product && props.contact) && (
        <a href="#home3" className="flex items-center text-gray-700">
          Please select a product
        </a> 
      )}

      {/* */}
    </nav>
  );
}

export default Breadcrumbs;
