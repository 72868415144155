import { useState, useEffect } from "react";
import {
  FilterOutline,
  SearchSolid,
} from "@graywolfai/react-heroicons";
import { Button, FormField, Loading } from "../../../Components";
import useSWR, { mutate } from "swr";
import apiHelper from "../../../Helpers/apiHelper";
import moment from 'moment';

import ContactCard from "../Shared/ContactCard";

import Filters from "../Shared/Filters";

function Authorizations({ normalize }) {
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);

  const loadData = async() => {
    setIsLoading(true);
    const refreshedContactsData = await apiHelper(
      "/api/v1/admin/user/contact", "get", { view: "requests" }
    );

    setContacts(normalize(refreshedContactsData.data.data.users));
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const onFilter = async (assigned, text, salesEmail) => {
    setIsLoading(true);

    const refreshedContactsData = await apiHelper(
      "/api/v1/admin/user/contact",
      "get", {
        search: text,
        view: "requests",
      }
    );

    setContacts(normalize(refreshedContactsData.data.data.users));
    setIsLoading(false);
  };

  const handleDeny = async(id) => {
    await apiHelper(
      `/api/v1/admin/user/contact/${id}`,
      "post", {
        operation: "authorize",
        payload: "public"
      }
    );

    onFilter('');
  };

  const handleApprove = async(id) => {
    await apiHelper(
      `/api/v1/admin/user/contact/${id}`,
      "post", {
        operation: "authorize",
        payload: "private"
      }
    );

    onFilter('');
  };


  return (
    <>
      {/* barra dei filtri da mobile */}
      <div className="contacts__filters-mobile">
        <Button type="default-button" text="Filter by">
          <FilterOutline className="light-icon ml-2" />
        </Button>
        <FormField
          className="flex-grow"
          withLabel="0"
          type="text"
          name="linesearch"
          placeholder="Search contacts...">
          <SearchSolid className="mid-icon"></SearchSolid>
        </FormField>
      </div>

      {/* barra dei filtri da desktop */}
      <Filters
        onFilter={onFilter}
      />

      {/* elenco card di contatti */}
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <div className="contacts__cardgrid">
          {contacts.map((contact) => {

            let requestFromLine = undefined
            try {requestFromLine = contact.invitationsSent[0].Line.Localizations[0].content} catch(e) {}
            
            let meta = [
              { label: "Company name", value: contact.company, key: `company-${contact.id}` },
              { label: "Country", value: contact.country, key: `country-${contact.id}` },
              { label: "Registration date", value: contact.registrationDate, key: `registration-${contact.id}` },
              { label: "Request date", value: moment(contact.invitationsSent[0].createdAt).format('D/M/YYYY HH:mm:ss'), key: `registration-${contact.id}` },

              /* { label: "Accept date", value: contact.invitationsReceived[0].status === 'accepted' ? moment(contact.invitationsReceived[0].acceptedAt).format('D/M/YYYY HH:mm:ss') : undefined, key: `registrationDate-${contact.id}` }, */
              /* nella access request questa non dovrebbe mai venire valorizzata */
              { label: "Assigned to", value: contact.AssignedTo, key: `assigned-${contact.id}` },
              
              { label: "Request from line", value: requestFromLine, key: `reqfromline-${contact.id}` },
              { label: "", value: requestFromLine ? " " : undefined, key: `empty-${contact.id}` },
              { label: ( 
                <a href="#deny" key={`deny-${contact.id}`}
                  onClick={(e) => { e.preventDefault(); handleDeny(contact.id) }}
                  className="badge-danger">Deny</a> 
              ), value: " ", key: `deny-${contact.id}`},
              { label: ( 
                <a href="#approve"
                  onClick={(e) => { e.preventDefault(); handleApprove(contact.id) }}
                  className="badge-success">Approve</a>
              ), value: " ", key: `approve-${contact.id}`},
            ];
            return (
              <ContactCard
                key={contact.id}
                fname={contact.fname}
                lname={contact.lname}
                email={contact.email}
                meta={meta}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

export default Authorizations;
