import { useState, useEffect } from "react";
import {
  ChevronDownSolid,
  FilterOutline,
  SearchSolid,
} from "@graywolfai/react-heroicons";
import { Button, FormField, Loading } from "../../../Components";
import useSWR, { mutate } from "swr";
import apiHelper from "../../../Helpers/apiHelper";

import ContactCard from "../Shared/ContactCard";

import Filters from "../Shared/Filters";

function AllContacts({ normalize }) {
  const [isVisible, setVisible] = useState(false);
  const [salesPeople, setSalesPeople] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedArray, setSelected] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);
  const [cacheFilters, setCacheFilters] = useState({
    assigned: 'all',
    text: '',
    salesEmail: ''
  });

  const loadData = async() => {
    setIsLoading(true);
    const refreshedContactsData = await apiHelper(
      "/api/v1/admin/user/contact", "get", { view: "all" }
    );

    const settingsData = await apiHelper(
      "/api/v1/admin/company", "get", {}
    );
    setClientGroups(settingsData.data.data.company.ClientGroups);

    setContacts(normalize(refreshedContactsData.data.data.users));
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const { data: salesData } = useSWR("/api/v1/admin/user?role=sale", apiHelper);
  useEffect(() => {
    if (salesData) {
      setSalesPeople(salesData.data.data.users);
    }
  }, [salesData]);

  const handleCardSelector = (id) => {
    if (selectedArray.includes(id)) {
      setSelected(selectedArray.filter((item) => item !== id));
    } else {
      setSelected(selectedArray.concat(id));
    }
  };

  const onFilter = async (assigned, text, salesEmail, invite, clientGroupId) => {
    setIsLoading(true);
    
    const refreshedContactsData = await apiHelper(
      "/api/v1/admin/user/contact",
      "get",
      {
        search: text,
        view: "all",
        clientGroup: clientGroupId,
        assignmentStatus:
          assigned === "all" || assigned === "unassigned" ? 
            assigned :
            salesEmail,
      }
    );

    setContacts(normalize(refreshedContactsData.data.data.users));
    setIsLoading(false);
  };

  const handleDeny = async(id) => {
    await apiHelper(
      `/api/v1/admin/user/contact/${id}`,
      "post", {
        operation: "level",
        payload: "public"
      }
    );

    onFilter('');
  };

  const handleApprove = async(id) => {
    await apiHelper(
      `/api/v1/admin/user/contact/${id}`,
      "post", {
        operation: "level",
        payload: "private"
      }
    );

    onFilter('');
  };

  const handleAssignClientGroup = async(clientGroupId) => {
    setVisible(!isVisible);
    setIsLoading(true);

    for(let item of selectedArray) {
      await apiHelper(
        "/api/v1/admin/user/contact/" + item,
        "post", {
          operation: 'clientGroup',
          payload: clientGroupId === 0 ? null : clientGroupId
        }
      );
    };

    await loadData();

    setIsLoading(false);
  };

  return (
    <>
      {/* barra dei filtri da mobile */}
      <div className="contacts__filters-mobile">
        <Button type="default-button" text="Filter by">
          <FilterOutline className="light-icon ml-2" />
        </Button>
        <FormField
          className="flex-grow"
          withLabel="0"
          type="text"
          name="linesearch"
          placeholder="Search contacts...">
          <SearchSolid className="mid-icon"></SearchSolid>
        </FormField>
      </div>

      {/* barra dei filtri da desktop */}
      <Filters
        assignedFilterShown={false}
        salesPeople={salesPeople}
        clientGroupsFilterShown={true}
        clientGroups={clientGroups}
        onFilter={onFilter}
      />

      <div
        className={
          selectedArray.length !== 0
            ? "flex visible-true"
            : "flex visible-false"
        }>
        <div className="relative">
          <Button
            type="muted-button"
            onClick={() => setVisible(!isVisible)}
            text="Assign to client group">
            <ChevronDownSolid className="light-icon" />
          </Button>
          <ul className={`dropdown__container dropdown__container-white visible-${isVisible}`}>
            <li key={"cg0"}>
                  <a
                    href={"#person0"}
                    onClick={() => {
                      handleAssignClientGroup(0);
                    }}>
                    no group
                  </a>
                </li>
            {clientGroups.map((cg) => {
              return (
                <li key={cg.cg}>
                  <a
                    href={"#person" + cg.id}
                    onClick={() => {
                      handleAssignClientGroup(cg.id);
                    }}>
                    {cg.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/* elenco card di contatti */}
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <div className="contacts__cardgrid">
          {contacts.map((contact) => {
            console.log('contact', contact);
            let meta = [
              { label: "Company name", value: contact.company, key: `company-${contact.id}` },
              { label: "Country", value: contact.country, key: `country-${contact.id}` },
              { label: "Registration date", value: contact.registrationDate, key: `registration-${contact.id}` },
              { label: "Assigned to", value: contact.AssignedTo, key: `assigned-${contact.id}` },
              { 
                label: "Access level",
                value: contact.accessLevel === 'private' ? 
                  (
                    <a href="#private" key={`private-${contact.id}`}
                    onClick={(e) => { e.preventDefault(); handleDeny(contact.id) }}
                    className="badge-success">Private</a>
                  ) : (
                    <a href="#public" key={`public-${contact.id}`}
                    onClick={(e) => { e.preventDefault(); handleApprove(contact.id) }}
                    className="badge-info">Public</a>
                  ), // value: contact.accessLevel,
                key: `access-${contact.id}` 
              },
              { 
                label: "Revoke date", value: contact.revokedAt ? 
                <span className="badge-warning">{contact.revokedAt}</span> : 
                undefined, 
                key: `revoked-${contact.revoked}` 
              },
              { 
                label: "Client group",
                value: contact.ClientGroup ? (
                  <span className="badge-gray">{contact.ClientGroup}</span>
                ) : undefined,
                key: `client-group-${contact.id}` 
              },
            ];
            return (
              <ContactCard
                onClick={() => handleCardSelector(contact.id)}
                className={
                  selectedArray.includes(contact.id)
                    ? "contacts__card-selected"
                    : ""
                }
                key={contact.id}
                fname={contact.fname}
                lname={contact.lname}
                email={contact.email}
                meta={meta}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

export default AllContacts;
