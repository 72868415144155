import {
  CheckCircleSolid,
  ExclamationCircleSolid,
  ExclamationSolid,
  InformationCircleSolid,
  // XSolid,
} from "@graywolfai/react-heroicons";

function Alert(props) {
  var contents = {};
  switch (props.type) {
    case "warning":
      contents = {
        bgColor: "bg-yellow-50",
        borderColor: "border-yellow-100",
        mainColor: "text-yellow-500",
        icon: <ExclamationSolid className="w-4 h-4 text-yellow-500" />,
      };
      break;
    case "error":
      contents = {
        bgColor: "bg-red-50",
        borderColor: "border-red-100",
        mainColor: "text-red-500",
        icon: <ExclamationCircleSolid className="w-4 h-4 text-red-500" />,
      };
      break;
    case "success":
      contents = {
        bgColor: "bg-green-50",
        borderColor: "border-green-100",
        mainColor: "text-green-500",
        icon: <CheckCircleSolid className="w-4 h-4 text-green-500" />,
      };
      break;
    case "info":
      contents = {
        bgColor: "bg-blue-50",
        borderColor: "border-blue-100",
        mainColor: "text-blue-500",
        icon: <InformationCircleSolid className="w-4 h-4 text-blue-500" />,
      };
      break;
    default:
      contents = {
        bgColor: "bg-gray-100",
        borderColor: "border-gray-200",
        mainColor: "text-gray-600",
        icon: <InformationCircleSolid className="w-4 h-4 text-gray-500" />,
      };
  }

  return (
    <div
      className={`alert__container ${contents.bgColor} ${contents.borderColor}`}
    >
      <div className="alert__icon">{contents.icon}</div>
      <div className="alert__content">
        <div className={`alert__title ${contents.mainColor}`}>
          {props.title}
        </div>
        <div className={`alert__text ${contents.mainColor}`}>
          <span dangerouslySetInnerHTML={{__html: props.text}}></span>
        </div>
        <div className="alert__actions">{props.children}</div>
      </div>
      {/* <div className="alert__close">
        <XSolid className={`w-4 h-4 ${contents.mainColor}`} />
      </div> */}
    </div>
  );
}

export default Alert;
