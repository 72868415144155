import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from "../../Helpers/apiHelper";
import getLocalization from "../../Helpers/getLocalization";
import useSWR from "swr";
import { useHistory, useParams } from "react-router-dom";

import {
  ExternalLinkSolid,
  XSolid,
  FilterOutline
} from "@graywolfai/react-heroicons";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormField, Button, Select, Alert, Loading, FilterSidebar, MobileFilterSidebar } from "../../Components";
import { SelectedProducts } from "../../Components/Proposals";

const ProposalEdit = () => {
  const { proposalId } = useParams();
  const get_proposal_api_url = `/api/v1/admin/proposal/${proposalId}`;

  const new_proposal_api_url = `/api/v1/admin/proposal/${proposalId}`;
  let history = useHistory();
  const currentLang = "en";
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Edit proposal",
        subtitle: "",
        button: {
          visible: false,
        },
      },
    });
  }, [dispachPageConfig]);

  const { data: linesData } = useSWR("/api/v1/admin/line", apiHelper, {
    suspense: true,
    revalidateOnFocus: false,
  });
  const { data: contactsData } = useSWR(
    "/api/v1/admin/user/contact?accessLevel=private&view=all",
    apiHelper,
    { suspense: true, revalidateOnFocus: false }
  );

  // Getting proposal
  const { data: proposalData } = useSWR(
    get_proposal_api_url,
    apiHelper,
    { suspense: true, revalidateOnFocus: false }
  );

  const [validationError, setValidationError] = useState({
    success: true,
    type: "error",
    title: "Validation error",
    text: "Validation text",
  });
  const [title, setTitle] = useState({
    it: "",
    en: "",
  });

  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedLineId, setSelectedLineId] = useState();
  const [selectedContactId, setSelectedContactId] = useState();
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [lineRows, setLineRows] = useState([]);
  const [contactRows, setContactsRows] = useState([]);
  const [taxonomyFilters, setTaxonomyFilters] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [searchText, setSearchText] = useState();
  const [facets, setFacets] = useState({
    facet_counts: null,
    facet_translations: null,
  });
  const [submitNewProposalEnabled, setSubmitNewProposalEnabled] = useState(true);

  const normalizeProposalData = (products) => {

    let tmpSelectedProducts = []

    products.forEach((item) => {
      const image = item.Media.find(x => x.ProductMedia.relation === 'photo' && x.name.indexOf('_00.'));
      const row = {
        id: item.id.toString(),
        name: item.name,
        imageUrl: image ? image.url : null
      };
      tmpSelectedProducts.push(row)
      
    });
    setSelectedProducts(tmpSelectedProducts);
  };

  useEffect(() => {
    if (proposalData) {
      const data = proposalData.data.data.line;

      console.log('proposal', data);

      setTitle({ 
        ...title,
        en: data.Localizations.find(x => (x.type === 'title' && x.lang === 'en')) ? 
          data.Localizations.find(x => (x.type === 'title' && x.lang === 'en')).content : undefined,
        it: data.Localizations.find(x => (x.type === 'title' && x.lang === 'it')) ? 
          data.Localizations.find(x => (x.type === 'title' && x.lang === 'it')).content : undefined,
      });

      // TODO: Fix images
      //setSelectedProducts(data.Products);
      normalizeProposalData(data.Products);

      setSelectedContactId(data.LineUser.UserId);
    }
  }, [proposalData]);

  const normalizeLines = (lines) => {
    let rows = [];

    if (lines) {
      lines.data.data.line.forEach((item) => {
        let row = {
          id: item.id,
          name: getLocalization(item.Localizations, currentLang, "title"),
          value: item.id,
        };

        rows.push(row);
      });
    }

    rows.unshift({
      id: '',
      name: 'Select one...',
      value: '',
    });

    setLineRows(rows);
  };

  useEffect(() => {
    if (linesData) {
      normalizeLines(linesData);
    }
  }, [linesData]);

  const normalizeContacts = (contacts) => {
    let rows = [];

    if (contacts) {
      contacts.data.data.users.forEach((item) => {
        let row = {
          id: item.id,
          name: `${item.name} ${item.lastName} (${item.businessName})`,
          value: item.id,
        };

        rows.push(row);
      });
    }

    rows.unshift({
      id: '',
      name: 'Select one...',
      value: '',
    });

    setContactsRows(rows);
  };

  useEffect(() => {
    if (contactsData) {
      normalizeContacts(contactsData);
    }
  }, [contactsData]);

  const validateFieldFilled = (field, text) => {
    // Validate fields
    if (field === undefined || (Array.isArray(field) && field.length === 0) || field === "") {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitNewProposalEnabled(true);
      return false;
    }

    return true;
  };

  const validateFields = () => {

    if (
      !validateFieldFilled(title.en, "Please fill title field in english") ||
      !validateFieldFilled(title.it, "Please fill title field in italian") ||
      !validateFieldFilled(selectedContactId, "Please select a contact") ||
      !validateFieldFilled(selectedProducts, "Please select at least one product")
    ) {
      return false;
    }

    setValidationError({
      type: "success",
      title: "Data is correct!",
      text: "Please wait while uploading data",
      success: false,
    });

    return true;
  };

  const reloadProductData = async(lineId, filters) => {
    if (lineId) {
      setProductsLoading(true);
      const normalizedFilters = filters.map(x => { return({[x.taxonomy]: x.name}); });
      // console.log('FILTERS, normalizedFilters', normalizedFilters);

      const result = await apiHelper(`/api/v1/admin/line/${lineId}/product-filter`, 'post', {
        query: textSearch,
        filters: {
          fq: normalizedFilters
        }
      });
      console.log('result', result);

      setProducts(result.data.data.products.response.docs);
      setFacets({
        facet_counts: result.data.data.products.facet_counts,
        facet_translations: result.data.data.products.facet_translations,
      });

      setProductsLoading(false);
    }
  };

  useEffect(() => {
    console.log('facets', facets);
  }, [facets])

  useEffect(() => {
    console.log('taxonomyFilters', [taxonomyFilters, textSearch]);

    if (selectedLineId) {
      // console.log('FILTERS', [taxonomyFilters, textSearch, currentPage]);
      reloadProductData(selectedLineId, taxonomyFilters);
    }
    
  }, [taxonomyFilters, textSearch]);

  const handleTaxonomyChange = (event) => {
    setMobileFilterVisible(false);
    
    console.log('handleTaxonomyChange', event);

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const taxonomy = target.getAttribute('data-taxonomy');

    // console.log('FILTERS, handleTaxonomyClick', [taxonomy, name, value]);
    const key = taxonomy + '_' + name;
    // console.log('FILTERS, key', key);
    // console.log('FILTERS, taxonomyFilters', taxonomyFilters);
    
    if (value === false) {
      const taxonomyFiltersCopy = taxonomyFilters.filter(x => (x.taxonomy !== taxonomy || x.name !== name));
      // console.log('FILTERS, taxonomyFiltersCopy', taxonomyFiltersCopy);
      setTaxonomyFilters([...taxonomyFiltersCopy]);
    } else {
      setTaxonomyFilters([...taxonomyFilters, {
        taxonomy: taxonomy,
        name: name
      }]);
    }
  };

  const handleTextChange = (text) => {
    setMobileFilterVisible(false);
    setTextSearch(text);
  }

  const handleLineSelector = async(lineId) => {
    setTaxonomyFilters([]);
    reloadProductData(lineId, []);
  }

  const handleProductSelector = (id) => {
    console.log('handleProductSelector', selectedProducts);

    if (selectedProducts.find(x => x.id === id)) {
      setSelectedProducts(selectedProducts => selectedProducts.filter(x => x.id !== id ));
    } else {
      setSelectedProducts(selectedProducts => selectedProducts.concat(
        products.find(x => x.id === id)
      ));
    }
  }

  const handleSelectedRemove = (productId) => {
    setSelectedProducts(selectedProducts => selectedProducts.filter(x => x.id !== productId ));
  }

  const handleEditProposal = async () => {
    setSubmitNewProposalEnabled(false);

    const data = {
      title: title,
    };

    if (validateFields()) {
      // compose json to send
      let dataToSend = {
        type: "proposal",
        Localizations: [],
      };

      // title
      dataToSend.Localizations.push({
        lang: "en",
        content: title.en,
        type: "title",
      });
      dataToSend.Localizations.push({
        lang: "it",
        content: title.it,
        type: "title",
      });
      dataToSend.contactId = selectedContactId;
      dataToSend.status = 'active';
      dataToSend.productIds = selectedProducts.map(x => x.id);

      // console.log(new_proposal_api_url, dataToSend);

      const res = await apiHelper(new_proposal_api_url, "post", dataToSend);

      if (res.status === "success") {
        history.replace("/admin/proposals/list");
      } else {
        setValidationError({
          title: "Generic error",
          text: "There was an error calling server. Please contact IT support.",
          success: false,
          type: "error",
        });
        setSubmitNewProposalEnabled(true);
      }
    }
  };

  return (
    <div className="grid grid-cols-4 min-h-screen">
      {/* EDITOR */}
      <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">
        {!validationError.success && (
          <Alert
            type={validationError.type}
            title={validationError.title}
            text={validationError.text}
          />
        )}

        <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
          <TabPanel>
            <FormField
              onChange={(e) => {
                setTitle({ ...title, en: e.target.value });
              }}
              value={title.en}
              withLabel="1"
              label="Proposal title"
              type="text"
              name="title-en"
              placeholder="Proposal title in english" />
          </TabPanel>
          <TabPanel>
            <FormField
              onChange={(e) => {
                setTitle({ ...title, it: e.target.value });
              }}
              value={title.it}
              withLabel="1"
              label="Proposal title"
              type="text"
              name="title-it"
              placeholder="Proposal title in italian" />
          </TabPanel>

          <TabList className="tabs__container">
            <Tab className="tabs__tab">English</Tab>
            <Tab className="tabs__tab">Italian</Tab>
          </TabList>
        </Tabs>

        <div className="proposals__settings">
          {/* Cliente a cui assegnare la proposta */}
          <Select
            className="mb-2 w-full"
            withLabel
            label="Visible to this contact"
            options={contactRows}
            value={selectedContactId}
            onChange={(e) => {
              setSelectedContactId(e.target.value);
            }}>
            Select the client that can access this proposal
          </Select>
        </div>

        <div className="proposals__settings">
          {/* Selettore di linea per caricare poi prodotti e filtri relativi */}
          <Select
            className="mb-4 w-full"
            withLabel
            label="Choose a line to start select products from"
            options={lineRows}
            value={selectedLineId}
            onChange={(e) => {
              setSelectedLineId(e.target.value);
              handleLineSelector(e.target.value);
            }}>
            You can choose products from different lines by changing the
            selected line
          </Select>
        </div>

        {/* Griglia di prodotti della linea scelta in precedenza */}
        {productsLoading ? (
          <Loading />
        ) : (
          selectedLineId && (
            <div className="proposals__products">
              <div className="proposals__filters">
                <FilterSidebar
                  searchText={searchText}
                  setSearchText={setSearchText}
                  lineId={selectedLineId}
                  locale="en"
                  facets={facets}
                  loading={false}
                  handleTaxonomyChange={handleTaxonomyChange}
                  taxonomyFilters={taxonomyFilters}
                  onTextSearch={handleTextChange}
                />
              </div>
              <div className="proposals__filters-mobile-container md:hidden">
                <a href="#" className="default-button" onClick={(e) => { e.preventDefault(); setMobileFilterVisible(true); }}>
                  Filters{" "}
                  <FilterOutline className="light-icon inline ml-2 text-gray-300" />
                </a>

                <div id="mobilefilters" className={`proposals__filters-mobile visible-${isMobileFilterVisible}`}>
                  <XSolid
                    className="mobilefilters--close"
                    onClick={() => setMobileFilterVisible(false)} />
                  <MobileFilterSidebar
                    searchText={searchText}
                    setSearchText={setSearchText}
                    lineId={selectedLineId}
                    locale="en"
                    facets={facets}
                    loading={false}
                    handleTaxonomyChange={handleTaxonomyChange}
                    taxonomyFilters={taxonomyFilters}
                    onTextSearch={handleTextChange}
                  />
                </div>
              </div>
              
              <div className="proposals__productgrid">  
                {products.map((product) => {
                  return (
                    <div
                      key={`product-${product.id}`}
                      className={
                        selectedProducts.includes(product.id)
                          ? "proposals__product proposals__product-selected"
                          : "proposals__product"
                      }
                      onClick={() => handleProductSelector(product.id)}>

                      {product.imageUrl ? (
                        <img src={product.imageUrl} alt={product.name}></img>
                      ) : (
                        <img src='https://via.placeholder.com/200x/cccccc/5b21b6/?text=Walkr' alt={product.name}></img>
                      )}

                      <p>{product.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
        )
      )}

      </div>

      {/* SIDEBAR */}
      <div className="col-span-4 md:col-span-1 md:pl-8">
        {/* <label
          className="form__label form__label--small"
          htmlFor="line-permissions">
          Proposal link
        </label>
        <div className="form__optiongroup mb-8">
          <a
            className="text-tiny font-semibold text-gray-500"
            href="https://walkr.it/schmid/267823-4x823">
            https://walkr.it/schmid/267823-4x823
          </a>
          <DuplicateOutline className="text-purple-700 w-5 h-5 flex-shrink-0"></DuplicateOutline>
        </div> */}

        <div className="proposals__actions">
          <Button
            type="default-button w-full mt-10 disabled:opacity-30"
            text="Update proposal"
            onClick={handleEditProposal}
            disabled={!submitNewProposalEnabled}>
            <ExternalLinkSolid className="w-4 h-4 ml-4"></ExternalLinkSolid>
          </Button>
        </div>

        {/* Array dei prodotti selezionati */}
        <label className="form__label form__label--small">
          Included products
        </label>
        <div className="proposals__selectedproducts">
          <SelectedProducts
            onRemove={handleSelectedRemove}
            selected={selectedProducts} />
        </div>
      </div>
    </div>
  );
};

export default ProposalEdit;
