import { useState, useEffect } from "react";
import {
  FilterOutline,
  SearchSolid,
  LinkOutline
} from "@graywolfai/react-heroicons";
import { Button, FormField, Loading, Modal, LinkModal } from "../../../Components";
import useSWR from "swr";
import apiHelper from "../../../Helpers/apiHelper";
import moment from 'moment';

import ContactCard from "../Shared/ContactCard";

import Filters from "../Shared/Filters";

function Invites({ normalize }) {
  const fronted_base_url = process.env.REACT_APP_FRONTEND_BASE_URL;
  const get_company_settings_api_url = `/api/v1/admin/company`;

  const [salesPeople, setSalesPeople] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [linkModal, setLinkModal] = useState({
    hidden: true
  });

  const { data: settings } = useSWR(
    [get_company_settings_api_url, "get"],
    (url, method) => apiHelper(url, method),
    { revalidateOnFocus: false, suspense: true }
  );
  const companySlug = settings.data.data.company.slug;

  const loadData = async() => {
    setIsLoading(true);
    const refreshedContactsData = await apiHelper(
      "/api/v1/admin/user/contact", "get", { view: "invitations" }
    );

    setContacts(normalize(refreshedContactsData.data.data.users));
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const { data: salesData } = useSWR("/api/v1/admin/user?role=sale", apiHelper);
  useEffect(() => {
    if (salesData) {
      setSalesPeople(salesData.data.data.users);
    }
  }, [salesData]);

  const onFilter = async (assigned, text, salesEmail, filterInvites) => {
    setIsLoading(true);
    
    const refreshedContactsData = await apiHelper(
      "/api/v1/admin/user/contact",
      "get",
      {
        search: text,
        view: "invitations",
        invitationStatus: filterInvites === 'all' ? null : filterInvites
      }
    );

    setContacts(normalize(refreshedContactsData.data.data.users));
    setIsLoading(false);
  };

  const showUrl = (url) => {
    console.log("showurl", url)
    setLinkModal({
      url: url,
      hidden: false
    });
  };

  const onClosedLinkModal = () => {
    setLinkModal({
      hidden: true
    })
  }

  return (
    <>
      {/* barra dei filtri da mobile */}
      <div className="contacts__filters-mobile">
        <Button type="default-button" text="Filter by">
          <FilterOutline className="light-icon ml-2" />
        </Button>
        <FormField
          className="flex-grow"
          withLabel="0"
          type="text"
          name="linesearch"
          placeholder="Search contacts...">
          <SearchSolid className="mid-icon"></SearchSolid>
        </FormField>
      </div>

      {/* barra dei filtri da desktop */}
      <Filters
        assignedFilterShown={false}
        salesPeople={salesPeople}
        onFilter={onFilter}
        invitesFilterShown={true}
      />

      {/* elenco card di contatti */}
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <div className="contacts__cardgrid">
          {contacts.map((contact) => {
            const link = `${fronted_base_url}/${companySlug}/invitation/${contact.invitationsReceived[0].uuid}`;

            let meta = [
              { label: "Company name", value: contact.company, key: `company-${contact.id}` },
              { label: "Country", value: contact.country, key: `country-${contact.id}` },
              /* { label: "Registration date", value: contact.registrationDate, key: `registrationDate-${contact.id}` }, */
              { label: "Invitation date", value: moment(contact.invitationsReceived[0].createdAt).format('D/M/YYYY'), key: `registrationDate-${contact.id}` },
              { label: "Accept date", value: contact.invitationsReceived[0].status === 'accepted' ? moment(contact.invitationsReceived[0].acceptedAt).format('D/M/YYYY HH:mm:ss') : undefined, key: `registrationDate-${contact.id}` },
              { label: "Access level", value: contact.accessLevel, key: `accessLevel-${contact.id}` },
              { label: "Assigned to", value: contact.AssignedTo, key: `AssignedTo-${contact.id}` },
              { label: contact.invitationsReceived[0].status === 'pending' ? (
                <a href="#" onClick={(e) => { e.preventDefault(); showUrl(link)}} target="_blank" className="badge-link">Open Url</a>
              ) : null, value: contact.invitationsReceived[0].status === 'pending' ? " " : null, key: `url-${contact.id}`},
              { label: "Status", value: contact.invitationsReceived[0].status, key: `status-${contact.id}`},
            ];
            return (
              <ContactCard
                key={contact.id}
                fname={contact.fname}
                lname={contact.lname}
                email={contact.email}
                meta={meta}
              />
            );
          })}
        </div>
      )}
      <LinkModal url={linkModal.url} hidden={linkModal.hidden} onCloseHandler={onClosedLinkModal}/>
    </>
  );
}

export default Invites;
