import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import {
  GlobeOutline,
  ExternalLinkSolid,
  DocumentAddOutline,
  DocumentRemoveOutline,
  PencilAltOutline,
  UserRemoveOutline,
  UserAddOutline,
  PencilOutline
} from "@graywolfai/react-heroicons";
import apiHelper from "../../Helpers/apiHelper";
import uploadMedia from "../../Helpers/uploadMedia";

import { 
  Button,
  FormField,
  Textarea,
  Alert,
  InputFileButton,
  SizeSystemModal,
  ClientGroupModal,
  Loading
} from "../../Components";
import previewPlaceholder from "../../assets/images/preview-placeholder.jpg";

function Settings() {
  const get_company_settings_api_url = `/api/v1/admin/company`;
  const update_company_settings_api_url = `/api/v1/admin/company`;
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  const [showSizeSystemModal, setShowSizeSystemModal] = useState(false);
  const [showClientGroupModal, setShowClientGroupModal] = useState(false);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Settings",
        subtitle: "Configure the general settings for your company here",
        button: {
          visible: false,
        },
      },
    });
  }, []);

  const [validationError, setValidationError] = useState({
    success: true,
    type: "error",
    title: "Validation error",
    text: "Validation text",
  });
  const [submitSettingsEnabled, setSubmitSettingsEnabled] = useState(true);

  const [buttonBg, setButtonBg] = useState();
  const [buttonTxt, setButtonTxt] = useState();
  const [links, setLinks] = useState();
  const [logoImage, setLogoImage] = useState();
  const [previewLogoImage, setPreviewLogoImage] = useState();
  const [websiteUrl, setWebsiteUrl] = useState();
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState();
  const [footerDetails, setFooterDetails] = useState();

  const [sizeSystems, setSizeSystems] = useState([]);
  const [addSizeSystemModalError, setAddSizeSystemModalError] = useState();
  const [resetSizeSystemModal, setResetAddSizeSystemModal] = useState(false);
  const [updateSizeSystem, setUpdateSizeSystem] = useState({});

  const [clientGroups, setClientGroups] = useState([]);
  const [addClientGroupModalError, setAddClientGroupModalError] = useState();
  const [resetClientGroupModal, setResetAddClientGroupModal] = useState(false);
  const [updateClientGroup, setUpdateClientGroup] = useState({});
  const [settings, setSettings] = useState();

  // const { data: settings } = useSWR(
  //   [get_company_settings_api_url, "get"],
  //   (url, method) => apiHelper(url, method),
  //   { revalidateOnFocus: false, suspense: true }
  // );

  useEffect(async() => {
    // Loading settings (without swr)
    setSettings(await apiHelper(get_company_settings_api_url, 'get'));
  }, []);

  useEffect(() => {
    if (settings) {
      console.log("settings", settings);
      const data = settings.data.data.company;

      setButtonBg(data.bgColor);
      setButtonTxt(data.color);
      setLinks(data.linkColor);
      //setPreviewLogoImage(data.linkColor);
      setWebsiteUrl(data.url);
      setPrivacyPolicyUrl(data.privacy);
      setFooterDetails(data.footer);
      if (data.featuredMedia) {
        setPreviewLogoImage(data.featuredMedia.url)
      }
      setSizeSystems(data.SizeSystems);
      setClientGroups(data.ClientGroups);
    }
  }, [settings]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!logoImage) {
      //setPreviewLogoImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(logoImage);
    setPreviewLogoImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [logoImage]);

  const onSelectLogoImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setLogoImage(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setLogoImage(e.target.files[0]);
  };

  const handleUpdateSettings = async () => {
    setSubmitSettingsEnabled(false);

    const data = {
      bgColor: buttonBg,
      color: buttonTxt,
      linkColor: links,
      url: websiteUrl,
      privacy: privacyPolicyUrl,
      footer: footerDetails,
      SizeSystems: sizeSystems,
      ClientGroups: clientGroups
    };
    console.log("data", data);

    if (validateFields()) {
      console.log("validated");

      let mediaRes = {
        status: 'undefined'
      };

      if (logoImage) {
        mediaRes = await uploadMedia(logoImage);
        console.log('mediaRes', mediaRes);

        if (mediaRes.status === "success") {
          data.mediaId = mediaRes.data.data.media.id;
        }
      }

      const res = await apiHelper(update_company_settings_api_url, 'post', data);

      console.log('res', res);

      if (res.status === 'success') {
        //history.replace('/admin/lines/list');

        if (mediaRes.status === "fail") {
          setValidationError({
            title: 'Media upload error',
            text: 'Data was correctly uploaded but there was a problem uploading your media file. Please retry.',
            success: false,
            type: 'warning'
          });
        } else {
          setValidationError({
            type: "success",
            title: "Success!",
            text: "Settings was saved successfully",
            success: false,
          });

          setSettings(await apiHelper(get_company_settings_api_url, 'get'));

          // settings = useSWR(
          //   [get_company_settings_api_url, "get"],
          //   (url, method) => apiHelper(url, method),
          //   { revalidateOnFocus: false, suspense: true }
          // );
        }

        setSubmitSettingsEnabled(true);
      } else {
        console.log('res.data.message', res);

        if (res.data && res.data.data && res.data.data.message) {
          setValidationError({
            title: 'Error',
            text: res.data.data.message,
            success: false,
            type: 'error'
          });
        } else {
          setValidationError({
            title: 'Generic error',
            text: 'There was an error calling server. Please contact IT support.',
            success: false,
            type: 'error'
          });
        }
        
        setSubmitSettingsEnabled(true);
      }

    }
  };

  const validateFieldFilled = (field, text) => {
    // Validate fields
    if (field === undefined || field === "") {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitSettingsEnabled(true);
      return false;
    }

    return true;
  };

  const validateFieldUrl = (field, text) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    if (!pattern.test(field)) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitSettingsEnabled(true);
      return false;
    }

    return true;
  };

  const validateFields = () => {
    if (
      !validateFieldFilled(buttonBg, "Please fill button background color") ||
      !validateFieldFilled(buttonTxt, "Please fill button text color") ||
      !validateFieldFilled(links, "Please fill links text color") ||
      !validateFieldFilled(websiteUrl, "Please fill web site url") ||
      !validateFieldFilled(
        privacyPolicyUrl,
        "Please fill privacy policy url"
      ) ||
      !validateFieldFilled(footerDetails, "Please fill footer details") ||
      !validateFieldUrl(websiteUrl, "Web site url field is not valid") ||
      !validateFieldUrl(
        privacyPolicyUrl,
        "Privacy field url field is not valid"
      )

      /* || !( validateFieldFilled(featuredImage, "Please upload the featured image") || 
           validateFieldFilled(previewFeaturedImage, "Please upload the featured image")
        )*/
    ) {
      return false;
    }

    return true;
  };

  const showAddSizeSystemModal = () => {
    setUpdateSizeSystem(null);
    setResetAddSizeSystemModal(true);
    setShowSizeSystemModal(true);
  }

  const addSizeSystemModal = (name, sizes, index) => {
    setAddSizeSystemModalError(null);

    if (name === '' || sizes === '') {
      console.log(`Validation failed`);
      setAddSizeSystemModalError('All fields are mandatory');
      return;
    }

    console.log(`Adding or updating size system ${name} with sizes ${sizes}`, index);

    if (index === null) {
      setSizeSystems([
        ...sizeSystems, {
          name: name,
          sizes: sizes
        }
      ]);
    } else {
      let sizeSystemsTmp = [ ...sizeSystems ];
      sizeSystemsTmp[index] = {
        ...sizeSystemsTmp[index],
        name: name,
        sizes: sizes
      };
      setSizeSystems(sizeSystemsTmp);
    }

    setShowSizeSystemModal(false);
  }

  const removeSizeSystemModal = (index) => {
    console.log('removeSizeSystemModal', JSON.stringify(sizeSystems));
    console.log('removeSizeSystemModal', index);
    let sizeSystemsTmp = [ ...sizeSystems ];
    sizeSystemsTmp.splice(index, 1);
    setSizeSystems(sizeSystemsTmp);
  }

  const editSizeSystemModal = (sizeSystem, index) => {
    console.log('editSizeSystemModal', {...sizeSystem, index: index});

    setResetAddSizeSystemModal(false);
    setUpdateSizeSystem({...sizeSystem, index: index});
    setShowSizeSystemModal(true);
  }

  const showAddClientGroupModal = () => {
    setUpdateClientGroup(null);
    setResetAddClientGroupModal(true);
    setShowClientGroupModal(true);
  }

  const addClientGroupModal = (name, index) => {
    setAddClientGroupModalError(null);

    if (name === '') {
      console.log(`Validation failed`);
      setAddClientGroupModalError('Name field is mandatory');
      return;
    }

    console.log(`Adding or updating client group ${name}`, index);

    if (index === null) {
      setClientGroups([
        ...clientGroups,
        { name: name }
      ]);
    } else {
      let clientGroupsTmp = [ ...clientGroups ];
      
      clientGroupsTmp[index] = {
        ...clientGroupsTmp[index],
        name: name
      };
      console.log('*** clientGroupsTmp', clientGroupsTmp);

      setClientGroups(clientGroupsTmp);
    }

    setShowClientGroupModal(false);
  }

  const removeClientGroupModal = (index) => {
    console.log('removeClientGroupModal', JSON.stringify(clientGroups));
    console.log('removeClientGroupModal', index);
    let clientGroupsTmp = [ ...clientGroups ];
    clientGroupsTmp.splice(index, 1);
    setClientGroups(clientGroupsTmp);
  }

  const editClientGroupModal = (clientGroup, index) => {
    console.log('editClientGroupModal', {...clientGroup, index: index});

    setResetAddClientGroupModal(false);
    setUpdateClientGroup({...clientGroup, index: index});
    setShowClientGroupModal(true);
  }

  if (!settings) {
    return (
      <Loading></Loading>
    )
  }

  return (
    <div className="grid grid-cols-4 min-h-screen">
      {/* EDITOR */}
      <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">
        {!validationError.success && (
          <Alert
            type={validationError.type}
            title={validationError.title}
            text={validationError.text} />
        )}

        {showSizeSystemModal && (
          <SizeSystemModal
            title="Add size system"
            text="Please fill all data with to insert a new size system"
            reset={resetSizeSystemModal}
            dismiss={() => { setShowSizeSystemModal(false); }}
            error={addSizeSystemModalError}
            update={updateSizeSystem}
            add={addSizeSystemModal} />
        )}

        {showClientGroupModal && (
          <ClientGroupModal
            title="Add client group"
            text="Please fill the group name"
            reset={resetClientGroupModal}
            dismiss={() => { setShowClientGroupModal(false); }}
            error={addClientGroupModalError}
            update={updateClientGroup}
            add={addClientGroupModal} />
        )}
        

        {/* logo azienda */}
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Company Logo</h3>
            <p>
              200px x 30px maximum size, transparent PNG for the best results.
            </p>
          </div>

          <div className="settings__control">
            <div className="relative flex">
              <InputFileButton title="Upload" onUpload={onSelectLogoImage} />
            </div>

            <div className="bg-gray-100 h-30 w-60 mt-2">
              {logoImage || previewLogoImage ? (
                <img
                  src={previewLogoImage}
                  alt=""
                  className="object-scale-down" />
              ) : (
                <img
                  src={previewPlaceholder}
                  className="object-scale-down"
                  alt="" />
              )}
            </div>
          </div>
        </div>

        {/* colori del brand */}
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Brand Colors</h3>
            <p>
              Used on your Walk’r pages for buttons and links to create visual
              continuity with your website. HEX values accepted, e.g. #FF0000.
              Leave empty to use the default colors.
            </p>
            <div className="mt-4 py-8 px-4 relative bg-gray-100">
              <span className="absolute top-2 left-2 text-micro text-gray-400 uppercase font-semibold">
                Preview
              </span>
              <button
                className="default-button mb-2"
                style={{
                  backgroundColor: `${buttonBg}`,
                  color: `${buttonTxt}`,
                }}>
                This is a sample button
              </button>
              <a
                href="https://google.com"
                className="text-purple-700 underline text-xs font-semibold"
                style={{ color: `${links}` }}>
                This is a sample link
              </a>
            </div>
          </div>
          <div className="settings__control">
            <label htmlFor="buttonBgColor">Button background color</label>
            <FormField
              value={buttonBg}
              type="text"
              name="buttonBgColor"
              placeholder="#553C9A"
              onChange={(e) => {
                setButtonBg(e.target.value);
              }}
            />
            <label htmlFor="buttonTxtColor">Button text color</label>
            <FormField
              value={buttonTxt}
              type="text"
              name="buttonTxtColor"
              placeholder="#FFFFFF"
              onChange={(e) => {
                setButtonTxt(e.target.value);
              }}
            />
            <label htmlFor="linksColor">Links color</label>
            <FormField
              value={links}
              type="text"
              name="linkColor"
              placeholder="#553C9A"
              onChange={(e) => {
                setLinks(e.target.value);
              }}
            />
          </div>
        </div>

        {/* sito del brand */}
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Company website url</h3>
            <p>
              Link you want the visitors to go when clicking your logo in the
              Walk’r navigation
            </p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => {
                  setWebsiteUrl(e.target.value);
                }}
                value={websiteUrl}
                withLabel="0"
                withIcon="1"
                name="website-url"
                type="text"
                placeholder="https://yoursite.com">
                <GlobeOutline className="w-3 h-3 text-gray-300" />
              </FormField>
            </div>
          </div>
        </div>

        {/* link privacy policy */}
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Company privacy policy</h3>
            <p>Link to the Privacy Policy page on your website</p>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => {
                  setPrivacyPolicyUrl(e.target.value);
                }}
                value={privacyPolicyUrl}
                withLabel="0"
                withIcon="1"
                name="privay-policy-url"
                type="text"
                placeholder="https://yoursite.com/privacy-policy">
                <GlobeOutline className="w-3 h-3 text-gray-300" />
              </FormField>
            </div>
          </div>
        </div>

        {/* link privacy policy */}
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Company details in footer</h3>
            <p>
              Company information to be displayed in the footer of each Walk’r
              page. HTML tags accepted.
            </p>
          </div>
          <div className="settings__control">
            <Textarea
              onChange={(e) => {
                setFooterDetails(e.target.value);
              }}
              value={footerDetails}
              name="footer-details"
              size="6"
              placeholder="Your company details here" />
          </div>
        </div>

        {/* size systems */}
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Size system</h3>
            <Button
              type="muted-button w-full disabled:opacity-30"
              text="Add size system"
              onClick={showAddSizeSystemModal}>
              <DocumentAddOutline className="w-4 h-4 ml-4"></DocumentAddOutline>
            </Button>
          </div>
          <div className="settings__control">
            
            {sizeSystems.map((sizeSystem, index) => (
              <div className="mb-5 border-b-2 full-width pb-4" key={`size-system-${index}`}>
                <label htmlFor="sizeSystem.name">Name</label>
                <FormField
                  value={sizeSystem.name}
                  disabled={true}
                  type="text"
                  name="sizeSystem.name"
                  onChange={(e) => {
                    setLinks(e.target.value);
                  }} />
                <label htmlFor="sizeSystem.sizes">Sizes</label>
                <FormField
                  value={sizeSystem.sizes}
                  disabled={true}
                  type="text"
                  name="sizeSystem.sizes"
                  onChange={(e) => {
                    setLinks(e.target.value);
                  }} />
                <div className="flex mt-5">
                  <Button
                    type="muted-button w-full mr-2 disabled:opacity-30"
                    text="Delete"
                    onClick={() => { removeSizeSystemModal(index) }}>
                    <DocumentRemoveOutline className="w-4 h-4 ml-4"></DocumentRemoveOutline>
                  </Button>
                  <Button
                    type="muted-button w-full ml-2 disabled:opacity-30"
                    text="Update"
                    onClick={() => { editSizeSystemModal(sizeSystem, index) }}>
                    <PencilAltOutline className="w-4 h-4 ml-4"></PencilAltOutline>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      
        {/* clients groups */}
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Clients Groups</h3>
            <Button
              type="muted-button w-full disabled:opacity-30"
              text="Add group"
              onClick={showAddClientGroupModal}>
              <UserAddOutline className="w-4 h-4 ml-4"></UserAddOutline>
            </Button>
          </div>
          <div className="settings__control">
            
            {clientGroups.map((clientGroup, index) => (
              <div className="mb-5 border-b-2 full-width pb-4" key={`size-system-${index}`}>
                <label htmlFor="clientGroup.name">Name</label>
                <FormField
                  value={clientGroup.name}
                  disabled={true}
                  type="text"
                  name="clientGroup.name"
                  onChange={(e) => {
                    setLinks(e.target.value);
                  }} />
                <div className="flex mt-5">
                  <Button
                    type="muted-button w-full mr-2 disabled:opacity-30"
                    text="Delete"
                    onClick={() => { removeClientGroupModal(index) }}>
                    <UserRemoveOutline className="w-4 h-4 ml-4"></UserRemoveOutline>
                  </Button>
                  <Button
                    type="muted-button w-full ml-2 disabled:opacity-30"
                    text="Update"
                    onClick={() => { editClientGroupModal(clientGroup, index) }}>
                    <PencilOutline className="w-4 h-4 ml-4"></PencilOutline>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* SIDEBAR */}
      <div className="col-span-4 md:col-span-1 md:pl-8">
        <Button
          type="default-button w-full mt-10 disabled:opacity-30"
          text="Save settings"
          onClick={handleUpdateSettings}
          disabled={!submitSettingsEnabled}>
          <ExternalLinkSolid className="w-4 h-4 ml-4"></ExternalLinkSolid>
        </Button>
      </div>
    </div>
  );
}

export default Settings;
