import { Button, Modal } from "./index";
import {
  LinkOutline
} from "@graywolfai/react-heroicons";

function LinkModal(props) {

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  };

  let {url, title, onCloseHandler, icon, hidden} = props

  if (hidden) {
    return null;
  } else {

    if (!icon)
      icon = <LinkOutline className="mid-icon"></LinkOutline>

    if (!title)
      title = "URL"

    let dismissButton = (
      <Button type="muted-button" text="Close"
        onClick={() => {
          onCloseHandler()
        }}
    />)

    let confirmButton = (
      <Button 
        type="default-button" 
        text="Copy to clipboard"
        onClick={() => {
          copyToClipboard(url);
          onCloseHandler()
        }} />
    )

    return (
      <Modal
        title={title}
        text={(
        <a target="_blank" href={url}>{url}</a>)}
        icon={icon}
        dismissButton={dismissButton}
        confirmButton={confirmButton}
        hidden={hidden} />
    );
  }
}

export default LinkModal;
