import { ChevronRightSolid } from "@graywolfai/react-heroicons";

function interactionCategory(props){
    return(
        <div className="interactionItem__container" onClick={props.onClick}>
            <div className="interactionItem__data">
                {props.children}
                <h4>{props.category}</h4>
            </div>
            <div className="interactionItem__actions">
                {props.unread !== "0" ? (
                    <span className="interactionItem__count">{props.unread}</span>
                ) : (
                ""
                )}
                <ChevronRightSolid className="mid-icon" />
            </div>
        </div>
    );
}

export default interactionCategory;