import SelectedProduct from "./SelectedProduct";

function SelectedProducts(props) {
  const { selected, onRemove } = props;

  return selected.map((product) => {
    return <SelectedProduct product={product} onRemove={onRemove} />;
  });
}

export default SelectedProducts;
