import Button from "./FormElements/Button";

function FilterBar(props) {
  return (
    <div className="filterbar__container hidden md:flex flex-row items-center justify-between space-x-4 p-4 mb-4 bg-gray-50">
      {props.children}
      <Button type="muted-button" text="Apply filters" onClick={props.onClick}></Button>
    </div>
  );
}

export default FilterBar;
