function Button(props) {
  // placeholder per le variabile a livello di applicazione prese dai settings
  var buttonBgColor = "";
  var buttonTxtColor = "";

  return (
    <button
      className={props.type}
      style={{
        backgroundColor: `${buttonBgColor}`,
        color: `${buttonTxtColor}`,
      }}
      {...props}
    >
      {props.text}
      {props.children}
    </button>
  );
}

export default Button;
