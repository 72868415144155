import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from "../../Helpers/apiHelper";
import { useHistory, useParams } from "react-router-dom";
import useSWR from 'swr';

import {
  ExternalLinkSolid,
  BanOutline
} from "@graywolfai/react-heroicons";

import { FormField, Button, Alert, Radio } from "../../Components";

const UserEdit = () => {
  const { userId } = useParams();
  const get_user_api_url = `/api/v1/admin/user/${userId}`;
  const update_user_api_url = `/api/v1/admin/user/${userId}`;

  let history = useHistory();
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Update user",
        subtitle: "",
        button: {
          visible: false,
        },
      },
    });
  }, [dispachPageConfig]);

  const [validationError, setValidationError] = useState({
    success: true,
    type: "error",
    title: "Validation error",
    text: "Validation text",
  });

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState();

  // Loading user data
  const { data: user } = useSWR(
    [get_user_api_url, 'get'],
    (url, method) => apiHelper(url, method),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (user) {
      console.log('user', user);
      //setDataToSend(user.data.data.user);
      const data = user.data.data.user;

      if (data.name !== null) {
        setName(data.name);
      }
      if (data.lastName !== null) {
        setLastName(data.lastName);
      }
      if (data.businessName !== null) {
        setBusinessName(data.businessName);
      }
      if (data.username !== null) {
        setUsername(data.username);
      }

      data.Roles.forEach((item) => {
        if (item.name === "ROLE_ADMIN") {
          setRole('admin');
        }
        if (item.name === "ROLE_SALE") {
          setRole('sale');
        }
      });
    }
  }, [user]);

  const [submitUpdateUserEnabled, setSubmitUpdateUserEnabled] = useState(true);

  const handleUpdateUser = async () => {
    setSubmitUpdateUserEnabled(false);

    const data = {
      name: name,
      lastName: lastName,
      businessName: businessName,
      username: username,
      role: role,
    };

    if (password && password !== '') {
      data.password = password;
    }
    console.log("data", data);

    if (validateFields()) {
      console.log("validated");

      // compose json to send
      let dataToSend = {
        ...data,
      };

      console.log(dataToSend);

      const res = await apiHelper(update_user_api_url, "post", dataToSend);
      console.log("res", res);

      if (res.status === "success") {
        history.replace("/admin/users/list");
      } else {
        setValidationError({
          title: "Generic error",
          text: "There was an error calling server. Please contact IT support.",
          success: false,
          type: "error",
        });
        setSubmitUpdateUserEnabled(true);
      }
    }
  };

  const validateFieldFilled = (field, text) => {
    // Validate fields
    if (field === undefined || field === "") {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitUpdateUserEnabled(true);
      return false;
    }

    return true;
  };

  const validateFieldEmail = (field, text) => {
    var pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "i"
    );

    if (!pattern.test(field)) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitUpdateUserEnabled(true);
      return false;
    }

    return true;
  };

  const validateFields = () => {
    if (
      !validateFieldFilled(name, "Please fill name field") ||
      !validateFieldFilled(lastName, "Please fill last name field") ||
      !validateFieldFilled(businessName, "Please fill business name field") ||
      !validateFieldFilled(username, "Please fill username field") ||
      !validateFieldEmail(username, "Username field is not valid") ||
      // !validateFieldFilled(password, "Please fill password field") ||
      !validateFieldFilled(role, "Please select role") 
    ) {
      return false;
    }

    setValidationError({
      type: "success",
      title: "Data is correct!",
      text: "Please wait while uploading data",
      success: false,
    });

    return true;
  };

  return (
    <div className="grid grid-cols-4 min-h-screen">
      {/* EDITOR */}
      <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">
        {!validationError.success && (
          <Alert
            type={validationError.type}
            title={validationError.title}
            text={validationError.text}
          ></Alert>
        )}

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Name</h3>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                name="name"
                type="text"
                placeholder="First name"
              >
              </FormField>
            </div>
          </div>
        </div>
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Last name</h3>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastName}
                name="lastName"
                type="text"
                placeholder="Last name">
              </FormField>
            </div>
          </div>
        </div>
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Business name</h3>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => {
                  setBusinessName(e.target.value);
                }}
                value={businessName}
                name="businessName"
                type="text"
                placeholder="Business name">
              </FormField>
            </div>
          </div>
        </div>
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Username</h3>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                disabled={true}
                value={username}
                name="username"
                type="text"
                placeholder="Email address">
                  <BanOutline className="w-3 h-3 text-gray-300"></BanOutline>
              </FormField>
            </div>
          </div>
        </div>
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Password</h3>
          </div>
          <div className="settings__control">
            <div className="relative">
              <FormField
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                name="password"
                type="password"
                placeholder="Access password">
              </FormField>
            </div>
          </div>
        </div>
        <div className="settings__group">
          <div className="belabel__description">
            <h3>Roles</h3>
          </div>
          <div className="settings__control">
            <div className="relative">
              <div className="form__optiongroup">
                <Radio
                  group="roles"
                  value="admin"
                  label="Admin"
                  checked={role === "admin"}
                  onChange={(e) => setRole(e.target.value)} />
                <Radio
                  group="roles"
                  value="sale"
                  label="Sales"
                  checked={role === "sale"}
                  onChange={(e) => setRole(e.target.value)} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SIDEBAR */}
      <div className="col-span-4 md:col-span-1 md:pl-8">
        <Button
          type="default-button w-full mt-10 disabled:opacity-30"
          text="Update user"
          onClick={handleUpdateUser}
          disabled={!submitUpdateUserEnabled}>
          <ExternalLinkSolid className="w-4 h-4 ml-4"></ExternalLinkSolid>
        </Button>
      </div>
    </div>
  );
};

export default UserEdit;
