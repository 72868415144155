import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from "../../Helpers/apiHelper";
import uploadMedia from "../../Helpers/uploadMedia";
import { useHistory } from "react-router-dom";

import {
  ExternalLinkSolid,
  GlobeOutline,
} from "@graywolfai/react-heroicons";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormField, Textarea, Button, Radio, Alert, InputFileButton } from "../../Components";
import previewPlaceholder from "../../assets/images/preview-placeholder.jpg";

const LineNew = () => {
  const new_line_api_url = '/api/v1/admin/line';
  const load_additional_fields_url = '/api/v1/admin/line/additional-fields';
  const load_fields_default_values_url = '/api/v1/admin/line/fields-default-values';
  let history = useHistory();
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Create new line",
        subtitle: "",
        button: {
          visible: false,
        },
      },
    });
  }, [dispachPageConfig]);

  const [validationError, setValidationError] = useState({
    success: true,
    type: "error",
    title: "Validation error",
    text: "Validation text",
  });
  const [title, setTitle] = useState({
    it: "",
    en: "",
  });
  const [description, setDescription] = useState({
    it: "",
    en: "",
    ru: undefined,
    de: undefined,
    fr: undefined,
    es: undefined,
  });
  const [link, setLink] = useState({
    it: "",
    en: "",
  });
  const [taxonomy1, setTaxonomy1] = useState({
    it: "",
    en: "",
  });
  const [taxonomy2, setTaxonomy2] = useState({
    it: "",
    en: "",
  });
  const [taxonomy3, setTaxonomy3] = useState({
    it: "",
    en: "",
  });
  const [taxonomy4, setTaxonomy4] = useState({
    it: "",
    en: "",
  });
  const [taxonomy5, setTaxonomy5] = useState({
    it: "",
    en: "",
  });
  const [permissions, setPermissions] = useState("");
  const [promoteLine, setPromoteLine] = useState("true");
  const [displayPrices, setDisplayPrices] = useState("no");
  const [featuredImage, setFeaturedImage] = useState();
  const [previewFeaturedImage, setPreviewFeaturedImage] = useState();
  const [submitNewLineEnabled, setSubmitNewLineEnabled] = useState(true);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [metadata, setMetadata] = useState();

  useEffect(() => {
    const loadAdditionalFields = async function () {
      const res = await apiHelper(load_additional_fields_url, 'get', {});

      if (res.status === 'success') {
        setAdditionalFields(res.data.data.additionalFields)
      }
    }
    loadAdditionalFields();
  }, []);

  useEffect(() => {
    const loadFieldsDefaultValues = async function () {
      const res = await apiHelper(load_fields_default_values_url, 'get', {});

      if (res.status === 'success') {
        let defaults = res.data.data.fieldsDefaultValues
        console.log("defaults", defaults)
        if (defaults.taxonomy1) {
          setTaxonomy1(defaults.taxonomy1)
        }
        if (defaults.taxonomy2) {
          setTaxonomy2(defaults.taxonomy2)
        }
        if (defaults.taxonomy3) {
          setTaxonomy3(defaults.taxonomy3)
        }
        if (defaults.taxonomy4) {
          setTaxonomy4(defaults.taxonomy4)
        }
        if (defaults.taxonomy5) {
          setTaxonomy5(defaults.taxonomy5)
        }
      }
    }
    loadFieldsDefaultValues();
  }, []);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!featuredImage) {
      setPreviewFeaturedImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(featuredImage);
    setPreviewFeaturedImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [featuredImage]);

  const onSelectFeaturedImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFeaturedImage(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFeaturedImage(e.target.files[0]);
  };

  const handleNewLine = async() => {
    setSubmitNewLineEnabled(false);

    /*const data = {
      title: title,
      description: description,
      link: link,
      taxonomy1: taxonomy1,
      taxonomy2: taxonomy2,
      taxonomy3: taxonomy3,
      taxonomy4: taxonomy4,
      taxonomy5: taxonomy5,
      permissions: permissions,
      displayPrices: displayPrices,
      featuredImage: featuredImage,
    };*/

    if (validateFields()) {

       // compose json to send
      let dataToSend = {
        type: 'line',
        permission: permissions,
        promoteLine: promoteLine,
        price: displayPrices === 'yes',
        Localizations: []
      };

      // title
      dataToSend.Localizations.push({
        lang: 'en',
        content: title.en,
        type: 'title'
      });
      dataToSend.Localizations.push({
        lang: 'it',
        content: title.it,
        type: 'title'
      });

      // description
      dataToSend.Localizations.push({
        lang: 'en',
        content: description.en,
        type: 'description'
      });
      dataToSend.Localizations.push({
        lang: 'it',
        content: description.it,
        type: 'description'
      });
      if (description.ru !== undefined && description.ru !== '') {
        dataToSend.Localizations.push({
          lang: 'ru',
          content: description.ru,
          type: 'description'
        });
      }
      if (description.de !== undefined && description.de !== '') {
        dataToSend.Localizations.push({
          lang: 'de',
          content: description.de,
          type: 'description'
        });
      }
      if (description.fr !== undefined && description.fr !== '') {
        dataToSend.Localizations.push({
          lang: 'fr',
          content: description.fr,
          type: 'description'
        });
      }
      if (description.es !== undefined && description.es !== '') {
        dataToSend.Localizations.push({
          lang: 'es',
          content: description.es,
          type: 'description'
        });
      }

      // link homepage
      dataToSend.Localizations.push({
        lang: 'en',
        content: link.en,
        type: 'backlink'
      });
      dataToSend.Localizations.push({
        lang: 'it',
        content: link.it,
        type: 'backlink'
      });

      // taxonomies (will check just english because )
      if (taxonomy1.en !== undefined && taxonomy1.en !== '') {
        dataToSend.taxonomy1 = `${taxonomy1.en}|${taxonomy1.it}`;
      }
      if (taxonomy2.en !== undefined && taxonomy2.en !== '') {
        dataToSend.taxonomy2 = `${taxonomy2.en}|${taxonomy2.it}`;
      }
      if (taxonomy3.en !== undefined && taxonomy3.en !== '') {
        dataToSend.taxonomy3 = `${taxonomy3.en}|${taxonomy3.it}`;
      }
      if (taxonomy4.en !== undefined && taxonomy4.en !== '') {
        dataToSend.taxonomy4 = `${taxonomy4.en}|${taxonomy4.it}`;
      }
      if (taxonomy5.en !== undefined && taxonomy5.en !== '') {
        dataToSend.taxonomy5 = `${taxonomy5.en}|${taxonomy5.it}`;
      }

      //begin additional fields

      for (let additionalField of additionalFields) {
        dataToSend.Localizations.push({
          lang: 'en',
          content: additionalField.value.en,
          type: additionalField.name
        });
        dataToSend.Localizations.push({
          lang: 'it',
          content: additionalField.value.it,
          type: additionalField.name
        });
      }

      dataToSend.metadata = metadata

      //end

      const mediaRes = await uploadMedia(featuredImage);

      if (mediaRes.status === "success") {
        dataToSend.media_id = mediaRes.data.data.media.id;
      } else {
        setValidationError({
          title: 'Generic error',
          text: 'There was an error uploading your media file. Please retry.',
          success: false,
          type: 'error'
        });
        setSubmitNewLineEnabled(true);
        return;
      }

      const res = await apiHelper(new_line_api_url, 'post', dataToSend);

      if (res.status === 'success') {
        history.replace('/admin/lines/list');
      } else {
        if (res.data.data.data) {

          //console.log("res.data.data", res.data.data)
          //console.log("res.data.message", res.data.message)

          setValidationError({
            title: 'Error',
            text: res.data.data.message,
            success: false,
            type: 'error'
          });
        } else {
          setValidationError({
            title: 'Generic error',
            text: 'There was an error calling server. Please contact IT support.',
            success: false,
            type: 'error'
          });
        }

        setSubmitNewLineEnabled(true);
      }

    }
  };

  const validateFieldFilled = (field, text) => {
    // Validate fields
    if (field === undefined || field === "") {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: 'error'
      });

      setSubmitNewLineEnabled(true);
      return false;
    }

    return true;
  }

  const validateFieldBothFilled = (field1, field2, text) => {
    if ((field1 === undefined || field1 === "") && (field2 === undefined || field2 === "")) {
      return true;
    }

    if ((field2 === undefined || field2 === "") || 
        (field1 === undefined || field1 === "")) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: 'error'
      });

      setSubmitNewLineEnabled(true);
      return false;
    }

    return true;
  }

  const validateFieldUrl = (field, text) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    if (!pattern.test(field)) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: 'error'
      });

      setSubmitNewLineEnabled(true);
      return false;
    }

    return true;
  }

  const validateFields = () => {

    if (!validateFieldFilled(title.en, "Please fill title field in english") ||
        !validateFieldFilled(title.it, "Please fill title field in italian") ||
        !validateFieldFilled(description.en, "Please fill description field in english") ||
        !validateFieldFilled(description.it, "Please fill description field in italian") ||
        !validateFieldFilled(link.en, "Please fill line homepage field in english") ||
        !validateFieldFilled(link.it, "Please fill line homepage field in italian") ||
        !validateFieldUrl(link.en, "Line homepage field in english is not valid") ||
        !validateFieldUrl(link.it, "Line homepage field in italian is not valid") ||
        !validateFieldFilled(featuredImage, "Please upload the featured image") ||
        !validateFieldBothFilled(taxonomy1.en, taxonomy1.it, "Please fill both languages in taxonomy1") ||
        !validateFieldBothFilled(taxonomy2.en, taxonomy2.it, "Please fill both languages in taxonomy2") ||
        !validateFieldBothFilled(taxonomy3.en, taxonomy3.it, "Please fill both languages in taxonomy3") ||
        !validateFieldBothFilled(taxonomy4.en, taxonomy4.it, "Please fill both languages in taxonomy4") ||
        !validateFieldBothFilled(taxonomy5.en, taxonomy5.it, "Please fill both languages in taxonomy5") ||
        !validateFieldFilled(permissions, "Please choose line permission type") ||
        !validateFieldFilled(displayPrices, "Please choose if display prices on products")) {
      return false;
    }

    validateAdditionalFields()

    setValidationError({
      type: "success",
      title: "Data is correct!",
      text: "Please wait while uploading data",
      success: false,
    });

    return true;
  };

  const validateAdditionalFields = () => {
    for (let additionalField of additionalFields) {
      if (!validateFieldBothFilled(additionalField.value.en, additionalField.value.it, 'Please fill both languages in field ' + additionalField.label))
        return false
    }
  };

  const updateAdditionalField = (updatedFieldIndex, lang, value) => {
    let tmpAdditionalFields = []
    for (let [additionalFieldIndex, additionalField] of additionalFields.entries()) {
      let tmpAF = JSON.parse(JSON.stringify(additionalField))
      if (additionalFieldIndex === updatedFieldIndex) {
        tmpAF.value[lang] = value
      }
      tmpAdditionalFields.push(tmpAF)
    }
    setAdditionalFields(tmpAdditionalFields)
  }

  return (
    <div className="grid grid-cols-4 min-h-screen">
      {/* EDITOR */}
      <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">
        {!validationError.success && (
          <Alert
            type={validationError.type}
            title={validationError.title}
            text={validationError.text}
          ></Alert>
        )}

        <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
          <TabPanel>
            <FormField
              onChange={(e) => {
                setTitle({ ...title, en: e.target.value });
              }}
              value={title.en}
              withLabel="1"
              label="Line title"
              type="text"
              name="title-en"
              placeholder="Line title in english"
            ></FormField>
          </TabPanel>
          <TabPanel>
            <FormField
              onChange={(e) => {
                setTitle({ ...title, it: e.target.value });
              }}
              value={title.it}
              withLabel="1"
              label="Line title"
              type="text"
              name="title-it"
              placeholder="Line title in italian"
            ></FormField>
          </TabPanel>

          <TabList className="tabs__container">
            <Tab className="tabs__tab">English</Tab>
            <Tab className="tabs__tab">Italian</Tab>
          </TabList>
        </Tabs>

        <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
          <TabPanel>
            <Textarea
              onChange={(e) => {
                setDescription({ ...description, en: e.target.value });
              }}
              value={description.en}
              name="description-en"
              size="6"
              withLabel
              label="Line description"
              placeholder="Your line description in english here..."
            ></Textarea>
          </TabPanel>
          <TabPanel>
            <Textarea
              onChange={(e) => {
                setDescription({ ...description, it: e.target.value });
              }}
              value={description.it}
              name="description-it"
              size="6"
              withLabel
              label="Line description"
              placeholder="Your line description in italian here..."
            ></Textarea>
          </TabPanel>
          <TabPanel>
            <Textarea
              onChange={(e) => {
                setDescription({ ...description, ru: e.target.value });
              }}
              value={description.ru}
              name="description-ru"
              size="6"
              withLabel
              label="Line description"
              placeholder="Your line description in russian here..."
            ></Textarea>
          </TabPanel>
          <TabPanel>
            <Textarea
              onChange={(e) => {
                setDescription({ ...description, de: e.target.value });
              }}
              value={description.de}
              name="description-de"
              size="6"
              withLabel
              label="Line description"
              placeholder="Your line description in german here..."
            ></Textarea>
          </TabPanel>
          <TabPanel>
            <Textarea
              onChange={(e) => {
                setDescription({ ...description, fr: e.target.value });
              }}
              value={description.fr}
              name="description-fr"
              size="6"
              withLabel
              label="Line description"
              placeholder="Your line description in french here..."
            ></Textarea>
          </TabPanel>
          <TabPanel>
            <Textarea
              onChange={(e) => {
                setDescription({ ...description, es: e.target.value });
              }}
              value={description.es}
              name="description-es"
              size="6"
              withLabel
              label="Line description"
              placeholder="Your line description in spanish here..."
            ></Textarea>
          </TabPanel>

          <TabList className="tabs__container">
            <Tab className="tabs__tab">English</Tab>
            <Tab className="tabs__tab">Italian</Tab>
            <Tab className="tabs__tab">Russian</Tab>
            <Tab className="tabs__tab">German</Tab>
            <Tab className="tabs__tab">French</Tab>
            <Tab className="tabs__tab">Spanish</Tab>
          </TabList>
        </Tabs>

        {additionalFields.map((additionalField, additionalFieldIndex) => {
          return (
          <Tabs className="mb-6" selectedTabClassName="tabs__tab--active" key={'additional-'+additionalFieldIndex}>
            <TabPanel>
              <FormField
                  onChange={(e) => {
                    updateAdditionalField(additionalFieldIndex, 'en', e.target.value)
                  }}
                  value={additionalField.value.en}
                  withLabel="1"
                  label={additionalField.label.en}
                  type={additionalField.type}
                  name={additionalField.name + '-en'}
                  placeholder={additionalField.placeholder.en}
              ></FormField>
            </TabPanel>
            <TabPanel>
              <FormField
                  onChange={(e) => {
                    updateAdditionalField(additionalFieldIndex, 'it', e.target.value)
                  }}
                  value={additionalField.value.it}
                  withLabel="1"
                  label={additionalField.label.it}
                  type={additionalField.type}
                  name={additionalField.name + '-it'}
                  placeholder={additionalField.placeholder.it}
              ></FormField>
            </TabPanel>

            <TabList className="tabs__container">
              <Tab className="tabs__tab">English</Tab>
              <Tab className="tabs__tab">Italian</Tab>
            </TabList>
          </Tabs>
          )
        })}

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Line homepage</h3>
            <p>The page of your website where you will link this line.</p>
          </div>
          <div className="settings__control">
            <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setLink({ ...link, en: e.target.value });
                  }}
                  value={link.en}
                  withLabel="0"
                  withIcon="1"
                  name="link-en"
                  type="text"
                  placeholder="https://yoursite.com/en/line-landing-page"
                >
                  <GlobeOutline className="w-3 h-3 text-gray-300" />
                </FormField>
              </TabPanel>
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setLink({ ...link, it: e.target.value });
                  }}
                  value={link.it}
                  withLabel="0"
                  withIcon="1"
                  name="link-it"
                  type="text"
                  placeholder="https://yoursite.com/it/line-landing-page"
                >
                  <GlobeOutline className="w-3 h-3 text-gray-300" />
                </FormField>
              </TabPanel>

              <TabList className="tabs__container">
                <Tab className="tabs__tab">English</Tab>
                <Tab className="tabs__tab">Italian</Tab>
              </TabList>
            </Tabs>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Featured image</h3>
            <p>Raccomended size 1200 x 450px, .jpg format</p>
          </div>
          <div className="settings__control">
            <div className="relative flex">
              <InputFileButton title="Upload image" onUpload={onSelectFeaturedImage} />
            </div>

            <div className="bg-gray-100 h-30 w-60 mt-2">
              {featuredImage ? (
                <img
                  src={previewFeaturedImage}
                  alt=""
                  className="object-scale-down"
                />
              ) : (
                <img
                  src={previewPlaceholder}
                  className="object-scale-down"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Taxonomies</h3>
            {/* <p>Reccomended size 1200 x 450px, .jpg format</p> */}
          </div>
          <div className="settings__control">
            <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy1({ ...taxonomy1, en: e.target.value });
                  }}
                  value={taxonomy1.en}
                  name="taxonomy1-en"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 1 in english"
                ></FormField>
              </TabPanel>
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy1({ ...taxonomy1, it: e.target.value });
                  }}
                  value={taxonomy1.it}
                  name="taxonomy1-it"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 1 in italian"
                ></FormField>
              </TabPanel>

              <TabList className="tabs__container">
                <Tab className="tabs__tab">English</Tab>
                <Tab className="tabs__tab">Italian</Tab>
              </TabList>
            </Tabs>

            <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy2({ ...taxonomy2, en: e.target.value });
                  }}
                  value={taxonomy2.en}
                  name="taxonomy2-en"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 2 in english"
                ></FormField>
              </TabPanel>
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy2({ ...taxonomy2, it: e.target.value });
                  }}
                  value={taxonomy2.it}
                  name="taxonomy2-it"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 2 in italian"
                ></FormField>
              </TabPanel>

              <TabList className="tabs__container">
                <Tab className="tabs__tab">English</Tab>
                <Tab className="tabs__tab">Italian</Tab>
              </TabList>
            </Tabs>

            <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy3({ ...taxonomy3, en: e.target.value });
                  }}
                  value={taxonomy3.en}
                  name="taxonomy3-en"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 3 in english"
                ></FormField>
              </TabPanel>
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy3({ ...taxonomy3, it: e.target.value });
                  }}
                  value={taxonomy3.it}
                  name="taxonomy3-it"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 3 in italian"
                ></FormField>
              </TabPanel>

              <TabList className="tabs__container">
                <Tab className="tabs__tab">English</Tab>
                <Tab className="tabs__tab">Italian</Tab>
              </TabList>
            </Tabs>

            <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy4({ ...taxonomy4, en: e.target.value });
                  }}
                  value={taxonomy4.en}
                  name="taxonomy4-en"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 4 in english"
                ></FormField>
              </TabPanel>
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy4({ ...taxonomy4, it: e.target.value });
                  }}
                  value={taxonomy4.it}
                  name="taxonomy4-it"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 4 in italian"
                ></FormField>
              </TabPanel>

              <TabList className="tabs__container">
                <Tab className="tabs__tab">English</Tab>
                <Tab className="tabs__tab">Italian</Tab>
              </TabList>
            </Tabs>

            <Tabs className="mb-6" selectedTabClassName="tabs__tab--active">
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy5({ ...taxonomy5, en: e.target.value });
                  }}
                  value={taxonomy5.en}
                  name="taxonomy5-en"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 5 in english"
                ></FormField>
              </TabPanel>
              <TabPanel>
                <FormField
                  onChange={(e) => {
                    setTaxonomy5({ ...taxonomy5, it: e.target.value });
                  }}
                  value={taxonomy5.it}
                  name="taxonomy5-it"
                  withLabel="0"
                  type="text"
                  placeholder="Taxonomy 5 in italian"
                ></FormField>
              </TabPanel>

              <TabList className="tabs__container">
                <Tab className="tabs__tab">English</Tab>
                <Tab className="tabs__tab">Italian</Tab>
              </TabList>
            </Tabs>
          </div>
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Metadata</h3>
            <p>JSON field for metadata storage</p>
          </div>
          <div className="settings__control">
            <div className="relative flex">
              <FormField
                  onChange={(e) => {
                    setMetadata(e.target.value);
                  }}
                  value={metadata}
                  withLabel="0"
                  withIcon="1"
                  name="metadata"
                  type="text"
              ></FormField>
            </div>
          </div>
        </div>

      </div>

      {/* SIDEBAR */}
      <div className="col-span-4 md:col-span-1 md:pl-8">
        <label
          className="form__label form__label--small"
          htmlFor="line-permissions"
        >
          Line permissions
        </label>
        <div className="form__optiongroup">
          <Radio
            group="line-permissions"
            value="public"
            label="Public"
            checked={permissions === "public"}
            onChange={(e) => setPermissions(e.target.value)}
          ></Radio>
          <Radio
            group="line-permissions"
            value="private"
            label="Private"
            checked={permissions === "private"}
            onChange={(e) => setPermissions(e.target.value)}
          ></Radio>
        </div>

        <label
            className="form__label form__label--small"
            htmlFor="promote-line"
        >
          Promote line on ArsutoriaStudio
        </label>
        <div className="form__optiongroup">
          <Radio
              group="promote-line"
              value="true"
              label="Yes"
              checked={promoteLine === "true"}
              onChange={(e) => setPromoteLine(e.target.value)} />
          <Radio
              group="promote-line"
              value="false"
              label="No"
              checked={promoteLine !== "true"}
              onChange={(e) => setPromoteLine(e.target.value)} />
        </div>

        {/* <label className="form__label form__label--small" htmlFor="line-prices">
          Display prices on products
        </label>
        <div className="form__optiongroup">
          <Radio
            group="line-prices"
            value="yes"
            label="Yes"
            checked={displayPrices === "yes"}
            onChange={(e) => setDisplayPrices(e.target.value)}
          ></Radio>
          <Radio
            group="line-prices"
            value="no"
            label="No"
            checked={displayPrices === "no"}
            onChange={(e) => setDisplayPrices(e.target.value)}
          ></Radio>
        </div> */}

        <Button
          type="default-button w-full mt-10 disabled:opacity-30"
          text="Publish line"
          onClick={handleNewLine}
          disabled={!submitNewLineEnabled}
        >
          <ExternalLinkSolid className="w-4 h-4 ml-4"></ExternalLinkSolid>
        </Button>
      </div>
    </div>
  );
};

export default LineNew;
