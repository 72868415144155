import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from '../../Helpers/apiHelper';
import moment from 'moment';
import cookieCutter from 'cookie-cutter';
import { Alert } from '../../Components';
import { useHistory } from "react-router-dom";


import { 
  Table, 
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FilterBar,
  FormField,
  Loading,
  Radio
 } from "../../Components";
import { LinkSolid, PencilSolid, SearchSolid, UsersOutline } from "@graywolfai/react-heroicons";


function SAUsersList() {
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  let history = useHistory();

  const impersonificate_api_url = '/api/v1/saadmin/user/impersonificate';

  const [userRows, setUserRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState('all');
  const [ alertShown, setAlertShown ] = useState(false);
  const [ alertData, setAlertData ] = useState({ type: 'info', title: '', text: '' });

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Users",
        subtitle: "Manage backend accounts here",
        button: {
          visible: false,
          name: 'Add user',
          link: '/saadmin/users/new'
        }
      },
    });
  }, [dispachPageConfig]);

  const normalizeUsers = (users) => {
    let rows = [];

    if (users) {
      users.data.data.users.forEach((item) => {
        const createdAt = moment(item.createdAt);
    
        let row = {
          id: item.id,
          email: item.username,
          creationDate: createdAt.format("D/M/YYYY HH:mm"),
          name: item.name,
          lastName: item.lastName,
          businessName: item.businessName,
          roles: item.Roles,
          company: item.Companies[0].name
        };
    
        rows.push(row);
      });
    }

    setUserRows(rows);
  }

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async() => {
    const newData = await apiHelper('/api/v1/saadmin/user', 'get', {
      search: searchText,
      role: searchType !== 'all' ? searchType : null
    });

    normalizeUsers(newData);
  }

  const impersonificate = async(userId) => {
    const res = await apiHelper(impersonificate_api_url, 'post', {
      userId: userId,
    }, true);

    if (res.status === 'success') {

      const JWT_COOKIE_TOKEN = 'jwt_admin_cookie_token';
      const COOKIE_VALIDITY_DATE = new Date(3000,0,1);

      const jwt = res.data.data.accessToken;
      cookieCutter.set(JWT_COOKIE_TOKEN, jwt, { expires: COOKIE_VALIDITY_DATE, path: '/', httpOnly: false });

      let homeUrl = '/admin'
      console.log("redirecting to " + homeUrl)
      showAlert('success', 'Login successful', 'Login completed successfully. Please wait...');
      setTimeout(() => {
        history.replace(homeUrl);
      }, 1000);
      
    } else {
      showAlert('error', 'Login error', 'Email or password are not valid');
      return;
    }
  }

  const showAlert = (type, title, text) => {
    setAlertData({
      type: type,
      title: title,
      text: text
    });
    setAlertShown(true);
  }

  const rowData = (row) => {
    //console.log('current row', row); 

    return (
      <TableRow key={row.id}>
        <TableCell>
          {row.email}
        </TableCell>
        <TableCell>
          {row.name} {row.lastName}
        </TableCell>
        <TableCell>
          {row.company}
        </TableCell>
        <TableCell className="hidden md:table-cell">
          { row.roles.find(x => x.name === 'ROLE_ADMIN') ? 'Admin' : 'Sale' }
        </TableCell>
        <TableCell className="hidden md:table-cell">
          {row.creationDate}
        </TableCell>
        <TableCell>
          <a href="#impersonificate" onClick={(e) => { e.preventDefault(); impersonificate(row.id)}}>
            <UsersOutline className="dark-icon w-3 h-3 ml-4 cursor-pointer"></UsersOutline>
          </a>
          {/* <Link to={'/admin/user/edit/' + row.id}>
            <PencilSolid className="dark-icon w-3 h-3 ml-4 cursor-pointer"></PencilSolid>
          </Link> */}
        </TableCell>
      </TableRow>
    );
  }

  if (userRows) {
    return (
      <div className="backend__page">
        {alertShown ? (
        <Alert
          type={alertData.type}
          title={alertData.title}
          text={alertData.text} />
      ) : null}
        <FilterBar onClick={refreshData}>
          <div className="filterbar__inlinefilters flex-grow">
            <span>Filter by:</span>
            <Radio
              className="flex flex-row items-center"
              group="searchType"
              name="all"
              label="All"
              value="all"
              checked={searchType === "all"}
              onChange={(e) => setSearchType(e.target.value)} />
            <Radio
              className="flex flex-row items-center"
              group="searchType"
              name="admin"
              label="Admin"
              value="admin"
              checked={searchType === "admin"}
              onChange={(e) => setSearchType(e.target.value)} />
            <Radio
              className="flex flex-row items-center"
              group="searchType"
              name="sale"
              label="Sales"
              value="sale"
              checked={searchType === "sale"}
              onChange={(e) => setSearchType(e.target.value)} />
          </div>
          <FormField
            className="flex-grow"
            withLabel="0"
            type="text"
            value={searchText}
            onChange={(e) => {setSearchText(e.target.value)}}
            name="linesearch"
            placeholder="Search by name, description or contents...">
            <SearchSolid className="mid-icon"></SearchSolid>
          </FormField>
        </FilterBar>
  
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell className="hidden md:table-cell">Name</TableCell>
              <TableCell className="hidden md:table-cell">Company</TableCell>
              <TableCell className="hidden md:table-cell">Role</TableCell>
              <TableCell className="hidden md:table-cell">Registered on</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { userRows.map((item, i) => { return rowData(item); }) }
          </TableBody>
        </Table>

      </div>
    );
  } else {
    return <Loading />;
  }
}

export default SAUsersList;
