import React from "react";
import { PageTitle } from '../../Components';
import { PageConfigStore } from '../../Store';

const AuthLayout = (props) => {

  return (
    <PageConfigStore>
      <div className="container mx-auto sm:w-2/4">

        <div className="backend__content">
          <div className="backend__header">
            <PageTitle />
          </div>

          <div className="backend__page">
            {props.children}
          </div>

        </div>
      </div>
    </PageConfigStore>
  );
};

export default AuthLayout;
