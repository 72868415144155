import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import { SaveAsSolid } from "@graywolfai/react-heroicons";
import useSWR from "swr";
import apiHelper from "../../Helpers/apiHelper";
import { Link } from "react-router-dom";
import moment from 'moment';
import { Button, Alert } from "../../Components";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FilterBar,
  FormField,
  Loading,
  DeleteModal,
  LinkModal,
  Radio
} from "../../Components";
import { LinkSolid, PencilSolid, SearchSolid, TrashOutline } from "@graywolfai/react-heroicons";

function SACompanyList() {

  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  const [companyList, setCompanyList] = useState([])

  const reloadCompanyList = async () => {

    const tmpCompanyListData = await apiHelper(
        "/api/v1/company", "get", {
        }
    );
    setCompanyList(tmpCompanyListData.data.data.companies);
    console.log('tmpCompanyListData', tmpCompanyListData);
  }


  useEffect(async() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Company List",
        subtitle: "List of all Walkr's companies",
        button: {
          visible: true,
          name: 'New Company',
          icon: '',
          link: '/saadmin/companies/new'
        }
      },
    });

    await reloadCompanyList();
  }, []);

  const rowData = (row) => {
    //console.log('current row', row);

    return (
        <TableRow key={"tr-" + row.id}>
          <TableCell><Link to={`/saadmin/companies/edit/${row.id}`}>
            <PencilSolid className="dark-icon w-3 h-3 ml-4 cursor-pointer"></PencilSolid>
          </Link></TableCell>
          <TableCell>{row.id}</TableCell>
          <TableCell>{row.featuredMedia && (<img src={`${row.featuredMedia?.url}`} className={"w-32 max-h-10"} />)}</TableCell>
          <TableCell>{`${row.name}`}</TableCell>
          <TableCell>{row.slug}</TableCell>
          <TableCell>{row.Lines.length}</TableCell>
          <TableCell className="md:table-cell">{moment(row.createdAt).format("YYYY-MM-DD")}</TableCell>
          <TableCell className="md:table-cell">{moment(row.updatedAt).format("YYYY-MM-DD")}</TableCell>
        </TableRow>
    );
  }

  if (companyList) {
    return (
        <div className="backend__page">

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Edit</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Slug</TableCell>
                <TableCell>Num. of Lines</TableCell>
                <TableCell className="md:table-cell">Creation date</TableCell>
                <TableCell className="md:table-cell">Update date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { companyList.map((item, i) => { return rowData(item); }) }
            </TableBody>
          </Table>


        </div>
    );
  } else {
    return <Loading />;
  }
}

export default SACompanyList;
