const PageConfigReducer = (state, action) => {
  switch (action.type) {
    case "HEADERS":
      return {
        ...state,
        header: {
          title: action.payload.title ? action.payload.title : '',
          subtitle: action.payload.subtitle ? action.payload.subtitle : '',
          button: {
            visible: action.payload.button.visible ? action.payload.button.visible : '',
            name: action.payload.button.name ? action.payload.button.name : '',
            icon: action.payload.button.icon ? action.payload.button.icon : '',
            link: action.payload.button.link ? action.payload.button.link : ''
          }
        },
      };
    default:
      return state;
  }
};

export default PageConfigReducer;


