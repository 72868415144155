import { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import { Button, FormField } from "../../Components";
import { ExternalLinkSolid } from "@graywolfai/react-heroicons";
import apiHelper from '../../Helpers/apiHelper';
import cookieCutter from 'cookie-cutter';
import { Alert } from '../../Components';
import { useHistory } from "react-router-dom";

const Login = (props) => {
  const login_api_url = '/api/v1/admin/user/login';

  let history = useHistory();

  const [ pageConfig, dispachPageConfig ] = useContext(PageConfigContext);
  const [ email, setEmail ] = useState(); // 'john.smith5@shoesinc.com'
  const [ password, setPassword ] = useState(); // 'password'
  const [ submitEnabled, setSubmitEnabled ] = useState(true);
  const [ alertShown, setAlertShown ] = useState(false);
  const [ alertData, setAlertData ] = useState({ type: 'info', title: '', text: '' });

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Walkr",
        subtitle: "Please fill the fields with your username and password to access backend",
        button: {
          visible: false
        }
      },
    });
  }, []);

  const validateFields = () => {
    setAlertShown(false);
    if (!email) {
      showAlert('error', 'Missing field', 'Email is required');
      return false;
    }
    if (!password) {
      showAlert('error', 'Missing field', 'Password is required');
      return;
    }
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      showAlert('error', 'Invalid field', 'Email field is not valid');
      return;
    }

    return true;
  }

  const showAlert = (type, title, text) => {
    setAlertData({
      type: type,
      title: title,
      text: text
    });
    setAlertShown(true);
  }

  const handleLogin = async(e) => {
    e.preventDefault();

    setSubmitEnabled(false);
    if (!validateFields()) {
      setSubmitEnabled(true);
      return;
    }

    const res = await apiHelper(login_api_url, 'post', {
      username: email,
      password: password
    }, false);

    if (res.status === 'success') {

      const JWT_COOKIE_TOKEN = 'jwt_admin_cookie_token';
      const COOKIE_VALIDITY_DATE = new Date(3000,0,1);

      const jwt = res.data.data.accessToken;
      cookieCutter.set(JWT_COOKIE_TOKEN, jwt, { expires: COOKIE_VALIDITY_DATE, path: '/', httpOnly: false });

      let homeUrl = res.data.data.isSuperAdmin ? '/saadmin/' : '/admin'
      console.log("redirecting to " + homeUrl)
      showAlert('success', 'Login successful', 'Login completed successfully. Please wait...');
      setTimeout(() => {
        history.replace(homeUrl);
      }, 1000);
      
    } else {
      showAlert('error', 'Login error', 'Email or password are not valid');
      setSubmitEnabled(true);
      return;
    }
  };

  return (
    <div className="container mx-auto">
      {alertShown ? (
        <Alert
          type={alertData.type}
          title={alertData.title}
          text={alertData.text} />
      ) : null}
      
      <FormField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        withLabel="1"
        label="Username"
        type="text"
        name="username"
        placeholder="Email address"></FormField>

      <FormField
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        withLabel="1"
        label="Password"
        type="password"
        name="password"
        placeholder="Password"></FormField>
        
      <Button type="default-button w-full mt-4" text="Login" onClick={handleLogin} disabled={!submitEnabled}>
        <ExternalLinkSolid className="w-4 h-4 ml-4"></ExternalLinkSolid>
      </Button>
    </div>
  );
};

export default Login;
