function SalesPeopleSelect(props) {
  if (props.options) {
    return (
      <div className={props.className}>
        {props.withLabel ? (
          <label
            className="form__label text-white text-tiny"
            htmlFor="actionselector">
            Assign to
          </label>
        ) : null}
        <select name="actionselector" className="form__dropdown" value={props.selected} onChange={props.onChange}>
          <option key="0" value="">
            Choose a sales agent...
          </option>
          {props.options.map((person) => {
            return <option key={person.id} value={person.username}>{person.name} {person.lastName}</option>;
          })}
        </select>
      </div>
    );
  }

  return null;
}

export default SalesPeopleSelect;
