import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiHelper from '../../Helpers/apiHelper';
import useSWR from 'swr';

import {
  HomeOutline,
  UsersOutline,
  FolderOutline,
  LightningBoltOutline,
  AdjustmentsOutline,
  DocumentOutline,
  PhotographOutline,
  BriefcaseOutline,
  ChatOutline,
  ArchiveOutline,
} from "@graywolfai/react-heroicons";
import { Loading } from "../";

function SidebarItems(props) {
  const { user, hideMenu } = props;
  const [ totalUnread, setTotalUnread ] = useState(0);

  const { data: unread } = useSWR(
    ['/api/v1/admin/interaction/count-all-unread', "get"],
    (url, method) => apiHelper(url, method),
    { revalidateOnFocus: false, suspense: true, refreshInterval: 60*1000 }
  );

  useEffect(() =>  {
    if (unread && unread.data.data.count !== undefined) {
      setTotalUnread(unread.data.data.count);
    }
  }, [unread]);

  const isAdminRole = () => {
    return props.user.Roles.find(x => x.name === 'ROLE_ADMIN') !== undefined;
  }

  if (user) {
    return (
      <>
        <li>
          <Link to="/admin" onClick={e => { hideMenu && hideMenu() }}>
            <HomeOutline /> Home
          </Link>
        </li>
        {isAdminRole() && (
          <>
            <li>
              <Link to="/admin/settings" onClick={e => { hideMenu && hideMenu() }}>
                <AdjustmentsOutline /> Settings
              </Link>
            </li>
            <li>
              <Link to="/admin/users/list" onClick={e => { hideMenu && hideMenu() }}>
                <UsersOutline /> Users
              </Link>
            </li>
            <li>
              <Link to="/admin/lines/list" onClick={e => { hideMenu && hideMenu() }}>
                <FolderOutline /> Lines
              </Link>
            </li>
            <li>
              <Link to="/admin/media/list" onClick={e => { hideMenu && hideMenu() }}>
                <PhotographOutline /> Media Gallery
              </Link>
            </li>
            <li>
              <Link to="/admin/products" onClick={e => { hideMenu && hideMenu() }}><ArchiveOutline /> All Products</Link>
            </li>
            
          </>
        )}

        {!isAdminRole() && (
          <>
          <li>
            <Link to="/admin/quickproducts/list" onClick={e => { hideMenu && hideMenu() }}><LightningBoltOutline /> Quick Products</Link>
          </li>

          <li>
            <Link to="/admin/proposals/list" onClick={e => { hideMenu && hideMenu() }}><DocumentOutline /> Proposals</Link>
          </li>
          </>
        )}

        <li>
          <Link to="/admin/contacts/list" onClick={e => { hideMenu && hideMenu() }}>
            <BriefcaseOutline /> Contacts
          </Link>
        </li>
        
        {!isAdminRole() && (
        <li>
          <Link to="/admin/interactions" onClick={e => { hideMenu && hideMenu() }}>
            <ChatOutline /> Interactions 
            
          </Link>
          { totalUnread > 0 && (
            <span className="ml-2 interactionItem__count absolute right-0">{totalUnread}</span>
          )}
        </li>
        )}

      </>
    );
  } else {
    return <Loading></Loading>;
  }
}

export default SidebarItems;
