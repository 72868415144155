import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from "../../Helpers/apiHelper";
import {useHistory, useParams} from "react-router-dom";
import useSWR from 'swr';

import {
  ExternalLinkSolid,
} from "@graywolfai/react-heroicons";

import { FormField, Button, Alert, Radio } from "../../Components";

const SACompanyEdit = () => {
  const { companyId } = useParams();
  const get_company_api_url = `/api/v1/saadmin/company/${companyId}`;
  const update_company_api_url = `/api/v1/saadmin/company/${companyId}`;

  let history = useHistory();
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(async () => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Edit company",
        subtitle: "",
        button: {
          visible: false,
        },
      },
    });
    await reloadCompany();
  }, [dispachPageConfig]);

  const [validationError, setValidationError] = useState({
    success: true,
    type: "error",
    title: "Validation error",
    text: "Validation text",
  });

  const MAX_LINES_DEFAULT = '2';
  const MAX_PRODUCTS_PER_LINE_DEFAULT = '50';

  const [company, setCompany] = useState(undefined);

  const [name, setName]                     = useState("");
  const [slug, setSlug]                     = useState("");
  const [url, setUrl]                       = useState("");
  const [privacy, setPrivacy]               = useState("");
  const [footerBusiness, setFooterBusiness] = useState("");
  const [footerAddress, setFooterAddress]   = useState("");
  const [footerCity, setFooterCity]         = useState("");
  const [promoLines, setPromoLines]         = useState(false);
  const [maxLines, setMaxLines]             = useState(undefined);
  const [maxProductsPerLine, setMaxProductsPerLine] = useState(undefined);
  const [linesLimitations, setLinesLimitations] = useState(false);

  /*
  const [name, setName]                     = useState("Company Test 1");
  const [slug, setSlug]                     = useState("company-test-1");
  const [url, setUrl]                       = useState("https://news.ycombinator.com");
  const [privacy, setPrivacy]               = useState("https://news.ycombinator.com/privacy");
  const [footerBusiness, setFooterBusiness] = useState("Footer business");
  const [footerAddress, setFooterAddress]   = useState("Footer address");
  const [footerCity, setFooterCity]         = useState("Footer city");
*/



  const reloadCompany = async () => {

    const tmpCompanyData = await apiHelper(
        get_company_api_url, "get", {
        }
    );
    setCompany(tmpCompanyData.data.data.company);
    console.log('tmpCompanyData', tmpCompanyData);
  }

  useEffect(() => {
    if (company) {
      console.log('company', company);
      //setDataToSend(user.data.data.user);
      //const data = company.data.data.company;
      const data = company;

      if (data.name !== null) {
        setName(data.name);
      }
      if (data.slug !== null) {
        setSlug(data.slug);
      }
      if (data.url !== null) {
        setUrl(data.url);
      }
      if (data.privacy !== null) {
        setPrivacy(data.privacy);
      }
      if (data.footer !== null) {
        let FOOTER_TPL = '<p><b>[BUSINESS]</b></p><p>[ADDRESS]</p><p>[CITY]</p>'
        let allFooter = data.footer
        allFooter = allFooter.replace('<p><b>', '')
        allFooter = allFooter.replace('</b></p><p>', '§')
        allFooter = allFooter.replace('</p><p>', '§')
        allFooter = allFooter.replace('</p>', '')
        let tokens = allFooter.split('§')
        if (tokens.length === 3) {
          setFooterBusiness(tokens[0])
          setFooterAddress(tokens[1])
          setFooterCity(tokens[2])
        }
      }
      if (data.promoLines !== null) {
        setPromoLines(data.promoLines);
      }
      console.log("data.maxLines", data.maxLines)
      if (data.maxLines !== null) {
        setMaxLines(data.maxLines);
      }
      if (data.maxProductsPerLine !== null) {
        setMaxProductsPerLine(data.maxProductsPerLine);
      }

    }
  }, [company]);


  const [submitUpdatedCompanyEnabled, setSubmitUpdatedCompanyEnabled] = useState(true);

  const handleNewCompany = async () => {
    setSubmitUpdatedCompanyEnabled(false);

    const data = {
      name,
      slug,
      url,
      privacy,
      footerBusiness,
      footerAddress,
      footerCity,
      promoLines,
      maxLines: !linesLimitations ? undefined : (maxLines ? '' + maxLines : undefined),
      maxProductsPerLine: !linesLimitations ? undefined : (maxProductsPerLine ? '' + maxProductsPerLine : undefined),
    };
    console.log("data", data);

    if (validateFields()) {
      console.log("validated");

      // compose json to send
      let dataToSend = {
        ...data,
      };

      console.log(dataToSend);

      const res = await apiHelper(update_company_api_url, "post", dataToSend);
      console.log("res", res);

      if (res.status === "success") {
        history.replace("/saadmin/companies/list");
      } else {
        setValidationError({
          title: "Generic error",
          text: res.data.data.message,
          success: false,
          type: "error",
        });
        setSubmitUpdatedCompanyEnabled(true);
      }
    }
  };

  const validateFieldFilled = (field, text) => {
    // Validate fields
    if (field === undefined || field === "") {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitUpdatedCompanyEnabled(true);
      return false;
    }

    return true;
  };

  const validateFieldEmail = (field, text) => {
    var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "i"
    );

    if (!pattern.test(field)) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitUpdatedCompanyEnabled(true);
      return false;
    }

    return true;
  };

  const validateFieldUrl = (field, text) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    if (!pattern.test(field)) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: 'error'
      });

      setSubmitUpdatedCompanyEnabled(true);
      return false;
    }

    return true;
  }

  const validateFieldAlphanumericAndDash = (field, text) => {
    var pattern = new RegExp(
        /^[a-z\d\-]+$/,
        "g"
    );

    if (!pattern.test(field)) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitUpdatedCompanyEnabled(true);
      return false;
    }

    return true;
  };

  const validateNumeric = (field, text) => {
    var pattern = new RegExp(
        /^\d*$/,
        "gm"
    );

    if (!pattern.test(field)) {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: "error",
      });

      setSubmitUpdatedCompanyEnabled(true);
      return false;
    }

    return true;
  };

  const validateFields = () => {
    if (
        !validateFieldFilled(name, "Please fill name field") ||
        !validateFieldAlphanumericAndDash(slug, "Please fill slug field (only alphanumeric and dash)") ||
        !validateFieldUrl(url, "Please fill valid url field") ||
        !validateFieldUrl(privacy, "Please fill valid privacy url field") ||
        !validateFieldFilled(footerBusiness, "Please fill footer business field") ||
        !validateFieldFilled(footerAddress, "Please fill footer address field") ||
        !validateFieldFilled(footerCity, "Please fill footer city field") ||
        (linesLimitations && !validateNumeric(maxLines, "Max lines must be a number")) ||
        (linesLimitations && !validateNumeric(maxProductsPerLine, "Max products per line must be a number"))
    ) {
      return false;
    }

    setValidationError({
      type: "success",
      title: "Data is correct!",
      text: "Please wait while creating company",
      success: false,
    });

    return true;
  };

  const buildSlug = (name) => {
    let slug = name.toLowerCase()
    slug = slug.trim()
    slug = slug.replaceAll(/ /ig, '-')
    slug = slug.replace(/[^0-9a-z\-]/gi, '')
    slug = slug.replace(/\-+spa$/gm, '')
    slug = slug.replace(/\-+srl$/gm, '')
    slug = slug.replace(/\-{2,}/gm, '-')
    setSlug(slug)
  }

  useEffect(() => {
    if (maxLines && maxLines !== undefined) {
      setLinesLimitations(true)
    } else {
      setLinesLimitations(false)
    }
  }, [maxLines]);

  return (
      <div className="grid grid-cols-4 min-h-screen">
        {/* EDITOR */}
        <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">
          {!validationError.success && (
              <Alert
                  type={validationError.type}
                  title={validationError.title}
                  text={validationError.text}
              ></Alert>
          )}

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Name</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setName(e.target.value);
                      buildSlug(e.target.value)
                    }}
                    value={name}
                    name="name"
                    type="text"
                    placeholder="Company name"
                >
                </FormField>
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Slug</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setSlug(e.target.value);
                    }}
                    value={slug}
                    name="slug"
                    type="text"
                    placeholder=""
                >
                </FormField>
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Url</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                    value={url}
                    name="url"
                    type="text"
                    placeholder="Company url"
                >
                </FormField>
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Privacy url</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setPrivacy(e.target.value);
                    }}
                    value={privacy}
                    name="privacy"
                    type="text"
                    placeholder="Privacy url"
                >
                </FormField>
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Footer - business</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setFooterBusiness(e.target.value);
                    }}
                    value={footerBusiness}
                    name="footerBusiness"
                    type="text"
                    placeholder="Company S.p.a."
                >
                </FormField>
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Footer - address</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setFooterAddress(e.target.value);
                    }}
                    value={footerAddress}
                    name="footerAddress"
                    type="text"
                    placeholder="Via di Esempio, 24"
                >
                </FormField>
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Footer - city</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setFooterCity(e.target.value);
                    }}
                    value={footerCity}
                    name="footerCity"
                    type="text"
                    placeholder="20121 Milano (MI) - Italy"
                >
                </FormField>
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Promo lines</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <Radio
                    className="flex flex-row items-center"
                    group="promoLines"
                    name="promoLines"
                    label="Yes"
                    value="true"
                    checked={promoLines}
                    onChange={(e) => setPromoLines(true)} />
                <Radio
                    className="flex flex-row items-center"
                    group="promoLines"
                    name="promoLines"
                    label="No"
                    value="false"
                    checked={!promoLines}
                    onChange={(e) => setPromoLines(false)} />
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Lines limitations</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <Radio
                    className="flex flex-row items-center"
                    group="linesLimitations"
                    name="linesLimitations"
                    label="Yes"
                    value="true"
                    checked={linesLimitations}
                    onChange={(e) => setLinesLimitations(true)} />
                <Radio
                    className="flex flex-row items-center"
                    group="linesLimitations"
                    name="linesLimitations"
                    label="No"
                    value="false"
                    checked={!linesLimitations}
                    onChange={(e) => setLinesLimitations(false)} />
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Max number of lines</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setMaxLines(e.target.value);
                    }}
                    value={maxLines}
                    name="maxLines"
                    type="number"
                    disabled={linesLimitations ? false : true}
                >
                </FormField>
              </div>
            </div>
          </div>

          <div className="settings__group">
            <div className="belabel__description">
              <h3>Max products per lines</h3>
            </div>
            <div className="settings__control">
              <div className="relative">
                <FormField
                    onChange={(e) => {
                      setMaxProductsPerLine(e.target.value);
                    }}
                    value={maxProductsPerLine}
                    name="maxProductsPerLine"
                    type="number"
                    disabled={linesLimitations ? false : true}
                >
                </FormField>
              </div>
            </div>
          </div>

        </div>

        {/* SIDEBAR */}
        <div className="col-span-4 md:col-span-1 md:pl-8">
          <Button
              type="default-button w-full mt-10 disabled:opacity-30"
              text="Edit company"
              onClick={handleNewCompany}
              disabled={!submitUpdatedCompanyEnabled}
          >
            <ExternalLinkSolid className="w-4 h-4 ml-4"></ExternalLinkSolid>
          </Button>
        </div>
      </div>
  );
};

export default SACompanyEdit;
