import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from "../../Helpers/apiHelper";
import uploadMedia from "../../Helpers/uploadMedia";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import {
  ExternalLinkSolid,
} from "@graywolfai/react-heroicons";

import { Textarea, Button, Alert, Select, FormField, InputFileButton, MediaPreview } from "../../Components";
import previewPlaceholder from "../../assets/images/preview-placeholder.jpg";

const QuickProductEdit = () => {

  const new_quickproduct_api_url = '/api/v1/admin/quickproduct';
  let history = useHistory();
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "New quick product",
        subtitle: "",
        button: {
          visible: false,
        },
      },
    });
  }, [dispachPageConfig]);

  const [validationError, setValidationError] = useState({
    success: true,
    type: "error",
    title: "Validation error",
    text: "Validation text",
  });
  const [notes, setNotes] = useState();
  const [name, setName] = useState();
  const [contactRows, setContactsRows] = useState([]);
  const [contactId, setContactId] = useState();
  const [featuredImage, setFeaturedImage] = useState();
  const [previewFeaturedImage, setPreviewFeaturedImage] = useState();
  const [submitNewQuickProductEnabled, setSubmitNewQuickProductEnabled] = useState(true);

  const { data: contactsData } = useSWR(
    "/api/v1/admin/user/contact?accessLevel=private&view=all",
    apiHelper,
    { suspense: true, revalidateOnFocus: false }
  );

  const normalizeContacts = (contacts) => {
    let rows = [];

    if (contacts) {
      contacts.data.data.users.forEach((item) => {
        let row = {
          id: item.id,
          name: `${item.name} ${item.lastName} (${item.businessName})`,
          value: item.id,
        };

        rows.push(row);
      });
    }

    rows.unshift({
      id: '',
      name: 'Select one...',
      value: '',
    });

    setContactsRows(rows);
  };

  useEffect(() => {
    if (contactsData) {
      normalizeContacts(contactsData);
    }
  }, [contactsData]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!featuredImage) {
      setPreviewFeaturedImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(featuredImage);
    setPreviewFeaturedImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [featuredImage]);

  const onSelectFeaturedImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFeaturedImage(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFeaturedImage(e.target.files[0]);
  };

  const handleNewQuickProduct = async() => {
    setSubmitNewQuickProductEnabled(false);

    const data = {
      notes: notes,
      name: name,
      contactId: contactId,
      featuredImage: featuredImage,
    };

    if (validateFields()) {

       // compose json to send
      let dataToSend = {
        notes: notes,
        name: name,
        contactId: contactId
      };
      const mediaRes = await uploadMedia(featuredImage);

      if (mediaRes.status === "success") {
        dataToSend.mediaId = mediaRes.data.data.media.id;
      } else {
        setValidationError({
          title: 'Generic error',
          text: 'There was an error uploading your media file. Please retry.',
          success: false,
          type: 'error'
        });
        setSubmitNewQuickProductEnabled(true);
        return;
      }

      const res = await apiHelper(new_quickproduct_api_url, 'post', dataToSend);

      if (res.status === 'success') {
        history.replace('/admin/quickproducts/list');
      } else {
        setValidationError({
          title: 'Generic error',
          text: 'There was an error calling server. Please contact IT support.',
          success: false,
          type: 'error'
        });
        setSubmitNewQuickProductEnabled(true);
      }

    }
  };

  const validateFieldFilled = (field, text) => {
    // Validate fields
    if (field === undefined || field === "") {
      setValidationError({
        ...validationError,
        text: text,
        success: false,
        type: 'error'
      });

      setSubmitNewQuickProductEnabled(true);
      return false;
    }

    return true;
  }

  const validateFields = () => {

    if (!validateFieldFilled(name, "Please fill quick product name field") ||
        !validateFieldFilled(notes, "Please fill notes field") ||
        !validateFieldFilled(featuredImage, "Please upload the product image") ||
        !validateFieldFilled(contactId, "Please select a contact")) {
      return false;
    }
     
    setValidationError({
      type: "success",
      title: "Data is correct!",
      text: "Please wait while uploading data",
      success: false,
    });

    return true;
  };

  return (
    <div className="grid grid-cols-4 min-h-screen">
      {/* EDITOR */}
      <div className="col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200">
        {!validationError.success && (
          <Alert
            type={validationError.type}
            title={validationError.title}
            text={validationError.text}
          ></Alert>
        )}

        <div className="mt-4">
          <FormField
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            withLabel
            label="Name"
            name="name"
            size={10}
            placeholder="Quick product name" />
        </div>

        <div className="settings__group">
          <div className="belabel__description">
            <h3>Product image</h3>
            <p>Raccomended 1:1 aspect ratio, .jpg format</p>
          </div>
          <div className="settings__control">
            <div className="relative flex">
              <InputFileButton title="Upload image" onUpload={onSelectFeaturedImage} />
            </div>

            <div className="bg-gray-100 h-30 w-60 mt-2">
              <MediaPreview
                controls={true}
                previewFeaturedImage={previewFeaturedImage}
                featuredImage={featuredImage} />
            </div>
          </div>
        </div>

        <div className="mt-4">
          <Textarea
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            value={notes}
            withLabel
            label="Notes"
            name="notes"
            size={10}
            placeholder="Notes" />
        </div>

      </div>

      {/* SIDEBAR */}
      <div className="col-span-4 md:col-span-1 md:pl-8">
        <label
          className="form__label form__label--small"
          htmlFor="line-permissions">
          Visible to contact
        </label>
        <div className="form__optiongroup">
          <Select
            className="mb-2 w-full"
            withLabel="0"
            label=""
            options={contactRows}
            value={contactId}
            onChange={(e) => {
              setContactId(e.target.value);
            }}>
          </Select>
        </div>

        <Button
          type="default-button w-full mt-10 disabled:opacity-30"
          text="Publish"
          onClick={handleNewQuickProduct}
          disabled={!submitNewQuickProductEnabled}>
          <ExternalLinkSolid className="w-4 h-4 ml-4"></ExternalLinkSolid>
        </Button>
      </div>
    </div>
  );
};

export default QuickProductEdit;
