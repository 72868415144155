import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import moment from 'moment';

import Allcontacts from "./Panels/Allcontacts";
import Assign from "./Panels/Assign";
import Authorizations from "./Panels/Authorizations";
import Invites from "./Panels/Invites";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { UserAddOutline } from '@graywolfai/react-heroicons';
import { AdminTab } from '../../Components';

function ContactList(props) {

  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);
  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Contacts",
        subtitle: "Manage permissions, invites, sales force assignment",
        button: {
          visible: true,
          name: 'Invite a client',
          icon: ( <UserAddOutline className="light-icon ml-2" /> ),
          link: '/admin/contacts/invite'
        }
      },
    });
  }, [dispachPageConfig]);

  const [selectedOption, setOption] = useState(0);

  const normalizeData = (contacts) => {
    if (contacts) {
      return contacts.map(item => {
        const createdAt = moment(item.createdAt);
        const revokedAt = item.Companies ? (item.Companies[0].UserCompany.revokedAt ? moment(item.Companies[0].UserCompany.revokedAt).format("D/M/YYYY HH:mm:ss") : undefined) : undefined
        let assignedTo = null;
        if (item.assignedSales && item.assignedSales.length > 0) {
          assignedTo = item.assignedSales[0].name + ' ' + item.assignedSales[0].lastName;
        }
        let clientGroup = null;
        if (item.ClientGroups && item.ClientGroups.length > 0) {
          clientGroup = item.ClientGroups[0].name;
        }
        return {
          id: item.id,
          fname: item.name,
          lname: item.lastName,
          email: item.username,
          registrationDate: createdAt.format("D/M/YYYY"),
          company: item.businessName,
          AssignedTo: assignedTo,
          ClientGroup: clientGroup,
          country: item.countryCode,
          accessLevel: item.Companies ? item.Companies[0].UserCompany.accessLevel : undefined,
          revokedAt: revokedAt,
          invitationDate: 'TODO',
          invitationAcceptedDate: 'TODO',
          invitationStatus: 'TODO',
          requestStatus: 'TODO',
          invitationsReceived: item.invitationsReceived,
          invitationsSent: item.invitationsSent,
        }
      });
    }
    
    return [];
  }

  const isAdminRole = () => {
    console.log('isAdminRole', props.user);
    console.log('isAdminRole', props.user.Roles.find(x => x.name === 'ROLE_ADMIN') !== undefined);
    return props.user.Roles.find(x => x.name === 'ROLE_ADMIN') !== undefined;
  }

  const handleSelectChange = (event) => {
    setOption(event.target.value);
  }

  return (
    <div className="backend__page">
      {/* Select pox per cambiare pannello visibile solo da mobile*/}
      <div className="contacts__mobileswitcher field__container">
        <label className="form__label" htmlFor="actionselector">
          What you want to do?
        </label>
        <select
          name="actionselector"
          className="form__dropdown"
          value={selectedOption}
          onChange={handleSelectChange}>
          <option value="0" disabled>Choose an option...</option>
          <option value="1">Assign contacts to sales agents</option>
          <option value="2">Authorization requests</option>
          <option value="3">Invites</option>
          <option value="4">All contacts</option>
        </select>
      </div>

      {/* contenuto della pagina/tab attualmente selezionata */}
      <div className="contacts__panel">
        <div className="contacts__panel-mobile">
          {selectedOption === "1" && isAdminRole() ? <Assign normalize={normalizeData} /> : (
            <div className="text-gray-500 text-xl mt-20 text-center">This tab is available for Admin users only</div>
          )}
          {selectedOption === "2" && <Authorizations normalize={normalizeData} />}
          {selectedOption === "3" && <Invites normalize={normalizeData} />}
          {selectedOption === "4" && <Allcontacts normalize={normalizeData} />}
        </div>
        <div className="contacts__panel-desktop">
          <Tabs selectedTabClassName="contacts__tab-selected"
            onSelect={(index, lastIndex, event) => {
              console.log('onSelect', [index, lastIndex, event]);
            }}>
            <TabList className="contacts__tabs">
              <Tab className="contacts__tab">Assign to salesforce</Tab>
              <Tab className="contacts__tab">Authorization requests</Tab>
              <Tab className="contacts__tab">Invites</Tab>
              <Tab className="contacts__tab">All Contacts</Tab>
            </TabList>

            {isAdminRole() ? (
              <>
                <TabPanel>
                  <Assign normalize={normalizeData} />
                </TabPanel>
                <TabPanel>
                  <Authorizations normalize={normalizeData} />
                </TabPanel>
              </>
            ) : (
              <>
              <TabPanel>
                <div className="text-gray-500 text-xl mt-20 text-center">This tab is available for Admin users only</div>
              </TabPanel>
              <TabPanel>
                <div className="text-gray-500 text-xl mt-20 text-center">This tab is available for Admin users only</div>
              </TabPanel>
              </>
            )}
            
            <TabPanel>
              <Invites normalize={normalizeData} />
            </TabPanel>
            <TabPanel>
              <Allcontacts normalize={normalizeData} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default ContactList;
