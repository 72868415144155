import React, { useContext, useEffect } from "react";
import { PageConfigContext } from "../../Store";
import { 
  Alert
 } from "../../Components";


function SADashboard() {
  const [pageConfig, dispachPageConfig] = useContext(PageConfigContext);

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "SA Homepage",
        subtitle: "SA homepage dashboard",
        button: {
          visible: false
        }
      },
    });
  }, []);

  return (
    <div className="backend__page">
      <Alert
          type='info'
          title='This is SA homepage'
          text='To be filled with a lot of SA data' />
    </div>
  );
}

export default SADashboard;
