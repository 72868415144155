const Textarea = (props) => {
  
  const getLabel = () => {
    if (props.withLabel) {
      return (
        <label className="form__label" htmlFor={`${props.name}`}>
          {props.label}
        </label>
      )
    } 
    
    return null;
  }

  return (
    <>
      {getLabel()}
      <textarea
        value={props.value}
        onChange={props.onChange}
        className="form-field"
        rows={props.size}
        placeholder={props.placeholder}></textarea>
    </>
  );
}

export default Textarea;
