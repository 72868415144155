import { ChevronRightSolid, CheckSolid } from "@graywolfai/react-heroicons";

function ContactItem(props) {
  console.log(props)
  return (
    <div className="interactionItem__container" onClick={props.onClick}>
      <div className="interactionItem__data">
        <div className={`visible-${!props.compact}`}>
          <h3>{props.name}</h3>
          <p>{props.email}</p>
          <span>Assigned to: {props.assigned}</span>
        </div>
      </div>
      <div className="interactionItem__actions">
        {props.unread !== "0" ? (
          <span className="interactionItem__count">{props.unread}</span>
        ) : (
          ""
        )}
        <ChevronRightSolid className="mid-icon"></ChevronRightSolid>
      </div>
    </div>
  );
}

export default ContactItem;
