import previewPlaceholder from "../assets/images/preview-placeholder.jpg";

const MediaPreview = (props) => {
  function isURL(str) {
    //console.log('+++ isURL', str);
    if (str && str.startsWith('blob:')) {
      return false;
    }

    try {
      new URL(str);
      return true;
    } catch (err) {
      return false;  
    }
  }

  // console.log('*** MediaPreview props', props);

  if (!props.previewFeaturedImage && !props.featuredImage) {
    return (
      <img
        src={previewPlaceholder}
        className={props.className}
        alt="" />
    );
  }

  let extension = null;
  if (props.previewFeaturedImage && isURL(props.previewFeaturedImage)) {
    const imageFilename = props.previewFeaturedImage;
    extension = imageFilename.split('.').pop();
  } else {
    if (props.featuredImage && props.featuredImage.name) {
      const imageFilename = props.featuredImage.name;
      extension = imageFilename.split('.').pop();
    }
  }

  if (extension === "jpg" || extension === "png") {
    return (
      <img
        src={props.previewFeaturedImage}
        alt=""
        className={props.className} />
    );
  }

  if (extension === "mov" || extension === "mp4" || extension === "webm") {
    return (
      <video  
        muted
        controlsList="nodownload"
        controls={props.controls}
        src={props.previewFeaturedImage}
        alt=""
        className={props.className} />
    );
  }

  return (
    <img
      src={previewPlaceholder}
      className={props.className}
      alt="" />
  );
}

export default MediaPreview;
