function Select(props) {
  return (
    <div className={props.className}>
      {props.withLabel && (
        <label className="form__label" htmlFor="actionselector">
          {props.label}
        </label>
      )}
      <span className=" block text-tiny text-gray-400 mb-2">
        {props.children}
      </span>
      <select
        name="contactselect"
        className="form__dropdown"
        value={props.value}
        onChange={props.onChange}>
        <option value="0" disabled selected hidden>
          Select one...
        </option>
        {props.options.map((data) => {
          return <option value={data.value}>{data.name}</option>;
        })}
      </select>
    </div>
  );
}

export default Select;
