import React from "react";
import { SASidebarItems, PageTitle, SANavbar } from "../../Components";
import { PageConfigStore } from "../../Store";

const SALoggedLayout = (props) => {
  return (
    <PageConfigStore>
      <SANavbar user={props.user} />
      <div id="backend__container">
        <div className="backend__sidebar">
          <SASidebarItems user={props.user} />
        </div>

        <div className="backend__content">
          <div className="backend__header">
            <PageTitle />
          </div>
          <div className="backend__page">{props.children}</div>
        </div>
      </div>
    </PageConfigStore>
  );
};

export default SALoggedLayout;
