import { useEffect, useState } from "react";
import {
  CheckCircleOutline,
  XCircleOutline,
  ChevronDownSolid,
  DocumentOutline,
  TrashOutline,
  XOutline,
  
} from "@graywolfai/react-heroicons";
import {
  Button,
  PageTitle,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "../../../Components";
import placeholderImage from '../../../assets/images/placeholder-product.jpg';

function OrderDetail(props) {
  // const [isVisible, setVisible] = useState(false);
  const { order } = props;
  const isSizeSystem = order.Product.SizeSystemId !== null;

  //console.log('!!! order', order);

  const getMedia = (sku) => {
    const media = order.Product.Media.find(x => x.ProductMedia.relation === 'photo' && x.name.indexOf(`_${sku}_`) > 0);
    return (media ? media.url : placeholderImage);
  }

  const getDescription = (sku) => {
    const variations = order.Product.variations.split(';');
    const variation = variations.find(x => x.startsWith(`${sku}|`));
    const elements = variation.split('|');

    let description = '';
    for (let i = 1; i < elements.length; i+=2) {
      if (elements[i] === 'en') {
        description = elements[i+1];
        break;
      }
    }

    return description;
  }

  return (
    <>
      {/* <div className="backend__header relative">
        <Button type="default-button" text="Order actions">
          <ChevronDownSolid
            className="light-icon ml-2"
            onClick={() => setVisible(!isVisible)}
          />
        </Button>
        <ul
          className={`dropdown__container dropdown__container-white visible-${isVisible}`}>
          <li>
            <DocumentOutline className="mid-icon w-4 h-4 mr-2 inline" />
            Download order PDF
          </li>
          <li>
            <CheckCircleOutline className="mid-icon w-4 h-4 mr-2 inline" />
            Close order
          </li>
          <li className="text-red-900">
            <TrashOutline className="w-4 h-4 mr-2 inline" />
            Delete order
          </li>
        </ul>
      </div> */}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product name</TableCell>
            <TableCell>SKU/Variation</TableCell>
            {isSizeSystem && (
              <TableCell>Size</TableCell>
            )}
            <TableCell>Quantity</TableCell>
            {isSizeSystem && (
              <TableCell>Sample</TableCell>
            )}
            {/* <TableCell>Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {order && order.OrderDescriptions.map((item) => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  <img
                    src={getMedia(item.stockCode)}
                    className="w-8 h-auto" />
                  <p>{getDescription(item.stockCode)}</p>
                </TableCell>
                <TableCell>{item.stockCode}</TableCell>

                {isSizeSystem ? (
                  <>
                    <TableCell>{item.size.replace('S_', '')} ({item.sizeSystemName})</TableCell>
                    <TableCell>{item.sizeQuantity} {order.Product.measurementUnit}</TableCell>
                    <TableCell>{item.sample ? (
                      <XCircleOutline className="red-icon w-4 h-4 cursor-pointer" />
                    ) : (
                      <CheckCircleOutline className="green-icon w-4 h-4 cursor-pointer" />
                    )}</TableCell>
                  </>
                ) : (
                  <TableCell>{item.quantity} {order.Product.measurementUnit}</TableCell>
                )}
                
                {/* <TableCell>
                  <XOutline className="mid-icon w-4 h-4"></XOutline>
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default OrderDetail;
