import React, { useContext, useEffect, useState } from "react";
import { PageConfigContext } from "../../Store";
import apiHelper from '../../Helpers/apiHelper';
import { 
  Loading,
  Alert
 } from "../../Components";
import loadingAnimation from "../../assets/images/preload.gif";

function MediaImport() {
  const [ pageConfig, dispachPageConfig ] = useContext(PageConfigContext);
  const [ isImporting, setIsImporting ] = useState(true);
  const [ importingSuccess, setImportingSuccess ] = useState(true);

  const [ importingSuccessText, setImportingSuccessText ] = useState('The media import is completed successfully.<br><br>');

  useEffect(() => {
    dispachPageConfig({
      type: "HEADERS",
      payload: {
        title: "Media import",
        subtitle: "",
        button: {
          visible: false
        }
      },
    });

    startImport();
  }, []);

  const getFileList = (report) => {
    return report.mediasData.map(image => {
      if (image.error) {
        return (`<li><span class="text-red-900">Error - ${image.name} - ${image.error}</span></li>`);
      } else {
        return (`<li>OK - <a href="${image.url}" target="_blank"><span class="text-blue-900">${image.name}</span></a></li>`);
      }
    }).join(' ');
  }

  const startImport = async() => {
    const importResult = await apiHelper('/api/v1/admin/media/import', 'post', {});

    setImportingSuccess(importResult.success);

    console.log('debug', importResult);

    if (importResult.success) {
      setImportingSuccessText(
        importingSuccessText +
        `<b>Files imported: ${importResult.data.data.report.filesImported}</b><br><br>` +
        `<b>Files with error: ${importResult.data.data.report.filesWithError}</b><br><br>` +
        `<b>360 photo sets imported: ${importResult.data.data.report.photo360Imported}</b><br><br>` +
        `<b>360 photo sets with error: ${importResult.data.data.report.photo360WithError}</b><br><br>` +
        `<ul>${getFileList(importResult.data.data.report)}</ul>`
      );
    }
    
    setIsImporting(false);
  }

  if (isImporting) {
    return (
      <div className="backend__page">
        <div className="flex justify-center">
          <p className="text-3xl font-semibold">Please wait while Walkr imports media files</p>
        </div>
        <div className="flex justify-center">
          <img className="object-center" src={loadingAnimation} alt="Loading" />
        </div>
      </div>
    );
  } else {
    if (importingSuccess) {
      return (
        <div className="backend__page">
          <Alert text={importingSuccessText} title="Import completed" type="success"></Alert>
        </div>
      )
    } else {
      return (
        <div className="backend__page">
          <Alert text="There was an error importing media files. Please contact administrator." title="Import error" type="error"></Alert>
        </div>
      )
    }
    
  }
}
export default MediaImport;