import {
  ChevronRightOutline,
  ChevronRightSolid,
} from "@graywolfai/react-heroicons";

function ProductItem(props) {
  console.log(props)
  return (
    <div className="interactionItem__container" onClick={props.onClick}>
      <div className="interactionItem__data">
        {/* <img src={props.image} alt="" className="interactionItem__image" /> */}
        <div className={`visible-${!props.compact}`}>
          <h3>
            {props.line}{" "}
            {/* <ChevronRightOutline className="mid-icon inline"></ChevronRightOutline>{" "} */}
            {props.name}
          </h3>
          <span>{props.productID}</span>
        </div>
      </div>
      <div className="interactionItem__actions">
        {props.unread !== "0" ? (
          <span className="interactionItem__count">{props.unread}</span>
        ) : (
          ""
        )}
        <ChevronRightSolid className="mid-icon"></ChevronRightSolid>
      </div>
    </div>
  );
}

export default ProductItem;
