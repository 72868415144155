import React from "react";
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";

import {
  Login,
  Dashboard,
  SADashboard,
  LineList,
  LineNew,
  LineEdit,
  ProductList,
  ProposalList,
  ProposalNew,
  ProposalEdit,
  MediaList,
  MediaImport,
  Settings,
  UserNew,
  UserList,
  UserEdit,
  ContactList,
  ContactInvite,
  Interactions,
  QuickProductList,
  QuickProductNew,
  QuickProductEdit,
  SAUserList,
  SACompanyList,
  SACompanyNew,
  SACompanyEdit,
  SAArsutoriaImportList,
  SAArsutoriaImportNew,
  SAArsutoriaImportView,
} from "./Views";

import { AuthRoutes, LoggedRoutes, SALoggedRoutes } from "./Layout";

const Routing = (props, { children }) => {
  return (
    <Router>
      <Switch>
        <AuthRoutes exact path="/login" component={Login} />

        <LoggedRoutes exact path="/admin/" component={Dashboard} />
        <LoggedRoutes exact path="/admin/lines/list" component={LineList} />
        <LoggedRoutes exact path="/admin/lines/new" component={LineNew} />
        <LoggedRoutes path="/admin/lines/edit/:lineId" component={LineEdit} />
        <LoggedRoutes exact path="/admin/media/list" component={MediaList} />
        <LoggedRoutes exact path="/admin/media/import" component={MediaImport} />
        <LoggedRoutes exact path="/admin/proposals/list" component={ProposalList} />
        <LoggedRoutes exact path="/admin/proposals/new" component={ProposalNew} />
        <LoggedRoutes exact path="/admin/proposals/edit/:proposalId" component={ProposalEdit} />
        <LoggedRoutes exact path="/admin/quickproducts/list" component={QuickProductList} />
        <LoggedRoutes exact path="/admin/quickproducts/new" component={QuickProductNew} />
        <LoggedRoutes exact path="/admin/quickproducts/edit/:quickproductId" component={QuickProductEdit} />
        <LoggedRoutes exact path="/admin/settings" component={Settings} />
        <LoggedRoutes exact path="/admin/users/list" component={UserList} />
        <LoggedRoutes exact path="/admin/users/new" component={UserNew} />
        <LoggedRoutes path="/admin/user/edit/:userId" component={UserEdit} />
        <LoggedRoutes exact path="/admin/contacts/list" component={ContactList} />
        <LoggedRoutes exact path="/admin/contacts/invite" component={ContactInvite} />
        <LoggedRoutes exact path="/admin/interactions" component={Interactions} />
        <LoggedRoutes exact path="/admin/products" component={ProductList} />
        <SALoggedRoutes exact path="/saadmin/" component={SADashboard} />
        <SALoggedRoutes exact path="/saadmin/users/list" component={SAUserList} />
        <SALoggedRoutes exact path="/saadmin/companies/list" component={SACompanyList} />
        <SALoggedRoutes exact path="/saadmin/companies/new" component={SACompanyNew} />
        <SALoggedRoutes exact path="/saadmin/companies/edit/:companyId" component={SACompanyEdit} />
        <SALoggedRoutes exact path="/saadmin/arsutoria/import/list" component={SAArsutoriaImportList} />
        <SALoggedRoutes exact path="/saadmin/arsutoria/import/new" component={SAArsutoriaImportNew} />
        <SALoggedRoutes exact path="/saadmin/arsutoria/import/view/:reportId" component={SAArsutoriaImportView} />

        <Route path="*">
            <Redirect to="/login" />
          </Route>
      </Switch>
    </Router>
  );
};

export default Routing;
