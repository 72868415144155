function ChatMessage(props) {
  var bubble =
    props.sender === "user"
      ? { style: "bg-gray-100 text-gray-600 rounded-bl-none", align: "" }
      : {
          style: "bg-purple-700 text-white rounded-br-none",
          align: "justify-end",
        };

  return (
    <div className={`chat-message flex items-end ${bubble.align}`}>
      <div className="flex flex-col space-y-1 text-xs max-w-xs mx-2 order-2">
        <span className={`${bubble.style} px-4 py-2 rounded-lg inline-block`}>
          {props.children}
        </span>
      </div>
    </div>
  );
}

export default ChatMessage;
