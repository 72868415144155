import { TabPanel } from "react-tabs";

function AdminTab(props) {
  console.log('AdminTab');

  return (
    <TabPanel>
      <div className="text-gray-500 text-xl mt-20 text-center">This tab is available for Admin users only</div>
    </TabPanel>
  );
}
 export default AdminTab;