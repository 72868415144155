import React from "react";
import { Link } from "react-router-dom";

import {
  HomeOutline,
  UsersOutline,
  FolderOutline,
  LightningBoltOutline,
  AdjustmentsOutline,
  DocumentOutline,
  PhotographOutline,
  BriefcaseOutline,
  ChatOutline,
  ArchiveOutline,
    OfficeBuildingOutline,
  SaveAsOutline,
} from "@graywolfai/react-heroicons";
import { Loading } from "../";

function SASidebarItems(props) {
  const { user } = props;
  console.log("SidebarItems", user);

  const isAdminRole = () => {
    return props.user.Roles.find(x => x.name === 'ROLE_ADMIN') !== undefined;
  }

  if (user) {
    return (
      <>
        <li>
          <Link to="/saadmin">
            <HomeOutline /> Home
          </Link>
        </li>
        <li>
          <Link to="/saadmin/companies/list">
            <OfficeBuildingOutline /> Companies
          </Link>
        </li>
        <li>
          <Link to="/saadmin/users/list">
            <UsersOutline /> Users
          </Link>
        </li>
        <li>
          <Link to="/saadmin/arsutoria/import/list">
            <SaveAsOutline /> Arsutoria
          </Link>
        </li>
      </>
    );
  } else {
    return <Loading></Loading>;
  }
}

export default SASidebarItems;
