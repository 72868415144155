import React, { useContext } from 'react';
import { PageConfigContext } from '../Store';
import { Button } from "../Components";
import { PlusCircleSolid } from "@graywolfai/react-heroicons";
import { Link } from "react-router-dom";

const PageTitle = () => {
  const [state, dispatch] = useContext(PageConfigContext);

  const getButton = () => {
    if (state.header.button.visible) {
      return (
        <Link to={state.header.button.link}>
          <Button type="default-button" text={state.header.button.name}>
            { state.header.button.icon ?  state.header.button.icon : ( <PlusCircleSolid className="light-icon ml-2" /> ) }
          </Button>
        </Link>
      );
    } else {
      return null;
    }
    
  }

  return (
    <>
      <div>
        <h1 className="text-gray-900 font-bold text-xl md:text-2xl lg:text-3xl leading-8">
          {state.header.title}
        </h1>
        <p className="text-gray-500 font-normal text-xs md:text-sm lg:text-base">
          {state.header.subtitle}
        </p>
      </div>
      { getButton() }
    </>
  );
}

export default PageTitle;
