import { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../../Components";
import useSWR from "swr";
import apiHelper from "../../../Helpers/apiHelper";
import { DownloadSolid, CubeSolid } from "@graywolfai/react-heroicons";
import moment from 'moment';

function EventList(props) {
  const { contactId, productId } = props;
  const [eventList, setEventList] = useState([]);

  const {
    data: events,
  } = useSWR(
    `/api/v1/admin/interaction/interactions-by-type?contactId=${contactId}&productId=${productId}&type=events`,
    apiHelper,
    { refreshInterval: 60000 }
  );

  useEffect(() => {
    if (events) {
      setEventList(events.data.data.result);
    }
  }, [events]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Event</TableCell>
          <TableCell>Filename</TableCell>
          {/* <TableCell>Count</TableCell> */}
          <TableCell>Data</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {eventList.map((event) => {
          return (
            <TableRow key={event.id}>
              <TableCell>
                {event.type === 'download' ? (
                  <>
                    <DownloadSolid className="mr-2 w-4 h-4 mid-icon" />File download
                  </>
                ) : (
                  <>
                    <CubeSolid className="mr-2 w-4 h-4 mid-icon" />Other
                  </>
                )}
              </TableCell>
              
              <TableCell>
                {event.type === 'download' ? (
                  <a href={event.Media.url} target="_blank">
                    {event.Media.name}
                  </a>
                ) : null}
              </TableCell>
              <TableCell>
                {moment(event.createdAt).format("D/M/YYYY")}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
export default EventList;
