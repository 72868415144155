import Metadata from "../Shared/Metadata";

function ContactCard(props) {
  return (
    <div
      className={`contacts__card ${props.className}`}
      onClick={props.onClick}>
      <h1>
        {props.fname} {props.lname}
      </h1>
      <h3>{props.email}</h3>
      <Metadata meta={props.meta}/>
    </div>
  );
}

export default ContactCard;
